<template>
  <v-container>
  </v-container>
</template>

<script>

  export default {
    name: 'Fares',

    created: function () {
      document.title = "Flights365 | Fares";
    },

    components: {
    },
  }
</script>
