<template>
  <v-container>
    <div class='text-h1 text-center mt-16 primary--text'>404 Not Found</div>
  </v-container>
</template>

<script>

  export default {
    name: 'NotFound',

    created: function () {
      document.title = "Flights365 | 404 Not Found";
    },

    components: {
    },
  }
</script>
