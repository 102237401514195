import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import Axios from 'axios'
import html2canvas from 'html2canvas';
import VueCarousel from 'vue-carousel';
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.prototype.$html2canvas = html2canvas;
Vue.prototype.$http = Axios;
Vue.prototype.backend_address = 'https://flights365.com:5002'

Vue.config.productionTip = false

Vue.use(VueCarousel)
Vue.use(wysiwyg, {}); // config is optional. more below

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
