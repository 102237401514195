<template>
  <v-container v-if='password != ""'>
    <v-row v-if='maximized == null'>
      <v-col cols='4'>
        <v-btn @click='showArticleCreator()' x-large>
          <v-icon>mdi-plus</v-icon>
          New Article
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify='center'>
      <v-col :cols='12' md='4' :key='index' v-for='(article, index) in articles' >
        <v-card @click='showArticle(article)' v-if='maximized == null'>
          <v-img :src='"https://flights365.com/blogbanners/"+article.id+".jpg?"+Date.now()'></v-img>
          <v-card-title>
            {{article.title}}
          </v-card-title>
          <v-card-text v-html="formatContent(article.content).slice(0, 200)+'...'">
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if='maximized != null' cols='6'>
        <v-card>
          <v-img :src='"https://flights365.com/blogbanners/"+maximized.id+".jpg?"+Date.now()'></v-img>
          <v-file-input
            placeholder='Upload banner'
            truncate-length="15"
            v-model='bannerUpload'
          ></v-file-input>
          <v-card-title>
            <v-text-field v-model='maximized.title'>
            </v-text-field>
          </v-card-title>
          <v-card-text>
            <wysiwyg v-model='maximized.content'>
            </wysiwyg>
          </v-card-text>
          <v-card-actions>
            <v-switch label='Do not show in the blog' v-model='maximized.hidden' color='primary'>
            </v-switch>
          </v-card-actions>
          <v-card-actions>
            <v-btn @click='maximized = null' text>
              Go back to all articles
            </v-btn>

            <v-btn v-if='maximized.id' @click='deleteArticle(maximized)' text color='red' :loading='deleting'>
              Delete
            </v-btn>

            <v-btn v-if='maximized.id' @click='editArticle(maximized)' text color='orange' :loading='editing'>
              Save
            </v-btn>

            <v-btn v-if='maximized.id' :href="'https://flights365.com/blog/'+`${maximized.title.split(' ').map(w => {return w.toLowerCase()}).join('-')}-${maximized.id}`" text color='primary' :loading='adding' target='_blank'>
              Preview
            </v-btn>

            <v-btn v-if='!maximized.id' @click='addArticle(maximized)' text color='green' :loading='adding'>
              Save
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Blog',

    created: function () {
      document.title = "Flights365 | Blog";
      this.fetchArticles()

      this.authorize()
    },

    data: () => ({
      password: '',
      articles: null,
      maximized: null,
      bannerUpload: null,
      editing: false,
      adding: false,
      deleting: false
    }),

    components: {
    },

    methods: {
      authorize() {
        let password = prompt('Please provide password')
        if (password) {
          this.$http.post('https://www.flights365.com/blogposts/authorize', {'password': password}).then(response => {
            if (parseInt(response.data)) {
              this.password = password
            } else {
              this.authorize()
            }
          })
        }
      },

      uploadBanner(article, callback = (/* isChanged */) => {}) {
        if (this.bannerUpload) {
          let uploadData = new FormData()
          uploadData.append('id', article.id)
          uploadData.append('image', this.bannerUpload)
          uploadData.append('password', this.password)
          this.$http.post('https://www.flights365.com/blogposts/banner', uploadData, {headers: {'Content-Type': 'multipart/form-data'}}).then(() => {
            this.maximized = null
            this.fetchArticles()
            callback(true)
          })
        } else {
          callback(false)
        }
      },

      fetchArticles () {
        this.$http.get('https://www.flights365.com/blogposts/').then(response => {
          this.articles = response.data
        })
      },

      formatContent (content) {
        return content.replace(/\n/g, '<br/>')
      },

      showArticle (article) {
        this.bannerUpload = null
        this.maximized = article
      },

      showArticleCreator () {
        this.maximized = {'id': null, 'title': '', 'content': ''}
      },

      deleteArticle (article) {
        this.deleting = true
        this.$http.post('https://www.flights365.com/blogposts/remove', {'id': article.id, 'password': this.password}).then(() => {
          this.deleting = false
          this.maximized = null
          this.fetchArticles()
        })
      },

      editArticle (article) {
        this.editing = true
        this.$http.post('https://www.flights365.com/blogposts/edit', {'id': article.id, 'title': article.title, 'content': article.content, 'hidden': article.hidden, 'password': this.password}).then(() => {
          this.uploadBanner(article, () => {
            this.editing = false
            this.maximized = null
            this.fetchArticles()
          })
        })
      },

      addArticle (article) {
        this.adding = true
        this.$http.post('https://www.flights365.com/blogposts/add', {'title': article.title, 'content': article.content, 'hidden': article.hidden, 'password': this.password}).then(response => {
          this.uploadBanner({'id': parseInt(response.data)}, () => {
            this.adding = false
            this.maximized = null
            this.fetchArticles()
          })
        })
      },
    }
  }
</script>
