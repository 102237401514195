import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/Home.vue'
import Flights from '../views/Flights.vue'
import Cruises from '../views/Cruises.vue'
import Fares from '../views/Fares.vue'
import About from '../views/About.vue'
import Contacts from '../views/Contacts.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import AirlinesContacts from '../views/AirlinesContacts.vue'
import Faq from '../views/Faq.vue'
import Landing from '../views/Landing.vue'
import Blog from '../views/Blog.vue'
import NotFound from '../views/NotFound.vue'
import Admin from '../views/Admin.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/cruises',
    name: 'Cruises',
    component: Cruises
  },
  {
    path: '/flightsadmin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/flights*',
    name: 'Flights',
    component: Flights
  },
  {
    path: '/fares',
    name: 'Fares',
    component: Fares
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/airlines-contacts',
    name: 'AirlinesContacts',
    component: AirlinesContacts
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq
  },
  {
    path: '/landing/:destination',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/blog/:link?',
    name: 'Blog',
    component: Blog
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
