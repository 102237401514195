<template>
  <v-container class='text-subtitle-1'>
    <div class='primary--text text-h6 my-6'>About us</div>
    <div class='mb-6'><span class='primary--text'>Flights365</span> is a OTA(online travel agency) that specializes in providing customers with the best fares available for domestic and international .We encourage travellers to contact our call canter as our experienced agents are able to assist you in choosing better deals according to your needs. We have access to unpublished rates which are lower than these found on the Internet. </div>
    <div class='mb-6'><span class='primary--text'>Flights365</span> has been serving its consumers since 2005 and we offers an exclusive variety of travel content by providing large selection of airfares. Flights365 generates its vast selection of cheap fares from multiple GDS systems – Sabre, Amadeus and Galileo. This unique mix unique pricing and destination combinations that result in more travel options.</div>
    <div class='mb-6'><span class='primary--text'>Customer satisfaction</span> is our top most priority. We provide our customers with 24/7 emergency line and our agents are able to help you in case of airline cancellations, changes, strikes and other unpredicted events.</div>
    <div><span class='primary--text'>Award-winning agency</span>, Flights365 is multiple winner of Best Travel Agency and Best Travel Management Company from World Travel Awards.</div>
  </v-container>
</template>

<script>

  export default {
    name: 'About',

    components: {
    },

    created: function () {
      document.title = "Flights365 | About";
    },
  }
</script>
