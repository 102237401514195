<template>
  <v-container>
      <iframe src='http://www3.latesttraveloffers.com/Search/SearchResults.asp?v1=1&SupplierFilter=&DateRangeType=4&selLength=&searchboxcaption=CruisePro&SearchBoxStyle=default&SearchBoxDate=range&txtSupplier=&targetframe=&txtDestination=&UseSearchAPI=true&SearchButton=&srchTravType=CRUZ&TravelStart=&selTextDestination=Bahamas*Bahamas*Bahamas&txtDepartureLocation=&TravelEnd=&txtShipName=&selBudget=any**Any%20Price'></iframe>
  </v-container>
</template>

<script>

  export default {
    name: 'Cruises',

    created: function () {
      document.title = "Flights365 | Cruises";
    },

    components: {
    },
  }
</script>
