<template>
  <v-container class='text-h5'>
    <v-row>
      <v-col cols='12'>
        <v-card flat outlined>
          <v-card-title class='primary--text text-h6 my-6'>
            Customer support
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols='6'>
                <v-text-field label='First Name'></v-text-field>
              </v-col>
              <v-col cols='6'>
                <v-text-field label='Last Name'></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols='6'>
                <v-text-field label='Email Address:'></v-text-field>
              </v-col>
              <v-col cols='6'>
                <v-select :items='subjects' label='Subject'></v-select>
              </v-col>
            </v-row>
            <v-btn outlined text>Send</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='6'>
        <v-card flat outlined>
          <v-card-title class="primary--text text-h6">
            Hours of operation
          </v-card-title>
          <v-card-text class='text--primary text-subtitle-1'>
            9am - 8pm EST.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols='6'>
        <v-card flat outlined>
          <v-card-title class="primary--text text-h6">
            Call center
          </v-card-title>
          <v-card-text class='text--primary text-subtitle-1'>
          1-833-833-3088
          Call Now for Unpublished Lower Fares
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Contacts',

    components: {
    },

    created: function () {
      document.title = "Flights365 | Contacts";
    },

    data: () => ({
      subjects: [
        "New Booking",
        "Wrong Date",
        "Time or Airport",
        "Wrong or Missing Email",
        "Wrong Name(s)",
        "Add Additional Passenger(s)",
        "Unconfirmed Reservation",
        "No Confirmation Email Received",
        "Other Question"
      ]
    })
  }
</script>
