<template>
  <v-container>
        <div class='hidden-md-and-up justify-space-between d-md-none d-flex mb-2' v-if='!this.$store.state.searching && this.$store.state.flights.length != 0'>
            <div>
                <v-btn tile small color='primary' outlined text @click='expandFilters = !expandFilters'>{{expandFilters ? 'Hide' : 'Show'}} filters <v-icon small>mdi-filter</v-icon></v-btn>
            </div>
            <div>
                <v-btn-toggle tile value="sort" @change='emitSort' mandatory dense>
                    <v-btn small value="cheapest" text active-class='primary white--text'>Cheapest</v-btn>
                    <v-btn small value="shortest" text active-class='primary white--text'>Shortest</v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <div v-if='expandFilters'>
            <v-skeleton-loader
            v-if="this.$store.state.searching"
            type="article, list-item-three-line"
            ></v-skeleton-loader>
            <div v-if='!this.$store.state.searching && this.$store.state.flights.length != 0'>
                <v-card class='mb-2'>
                    <v-card-title>
                        <span>Filter your results</span>
                        <v-spacer></v-spacer>
                        <v-btn text @click='emitClearFilters'>Clear All</v-btn>
                    </v-card-title>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Number of stops
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols='12'>
                                <v-select @change='emitFilterByStopCount' :value='stops' :items="[{'text': 'Show all', 'value': ''}, {'text': 'Non Stop', 'value': 'n'}, {'text': 'Single Stop', 'value': 's'}, {'text': 'Multiple Stops', 'value': 'm'}]" hide-details>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Airline
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-select @change='emitFilterByCarrier' :value='carrier' :items='getCarriers()'></v-select>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Baggage
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-checkbox @change='emitFilterByBaggage' :value='baggageAllowance' label='Show only fares that allow baggage'></v-checkbox>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Flight Time
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                Going to {{firstTo[1]}} ({{firstTo[0]}})
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols='12'>
                                Departure Time: <span class='primary--text'>{{formatMinutesToHours(departureTime[0])}} - {{formatMinutesToHours(departureTime[1])}}</span>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols='12'>
                                <v-range-slider @change='emitFilterByDepartTime' :value='departureTime' :thumb-label='true' :thumb-size='48' step='10' min='0' max='1439'>
                                    <template v-slot:thumb-label="props">
                                        <div class='text-center font-weight-bold'>{{ formatMinutesToHours(props.value) }}</div>
                                    </template>
                                </v-range-slider>
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                Returning to {{firstFrom[1]}} ({{firstFrom[0]}})
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                Return Time: <span class='primary--text'>{{formatMinutesToHours(returnTime[0])}} - {{formatMinutesToHours(returnTime[1])}}</span>
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                <v-range-slider @change='emitFilterByReturnTime' :value='returnTime' :thumb-label='true' :thumb-size='48' step='10' min='0' max='1439'>
                                    <template v-slot:thumb-label="props">
                                        <div class='text-center font-weight-bold'>{{ formatMinutesToHours(props.value) }}</div>
                                    </template>
                                </v-range-slider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Flight Duration
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols='12'>
                                Going to {{firstTo[1]}} ({{firstTo[0]}})
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols='12'>
                                Departure Duration: <span class='primary--text'>{{formatMinutesToDuration(departureDuration[0])}} - {{formatMinutesToDuration(departureDuration[1])}}</span>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols='12'>
                                <v-range-slider @change='emitFilterByDepartDuration' :value='departureDuration' :thumb-label='true' :thumb-size='48' step='10' :min='minFlightDepartureDuration' :max='maxFlightDepartureDuration'>
                                    <template v-slot:thumb-label="props">
                                        <div class='text-center font-weight-bold'>{{ formatMinutesToDuration(props.value) }}</div>
                                    </template>
                                </v-range-slider>
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                Returning to {{firstFrom[1]}} ({{firstFrom[0]}})
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                Return Duration: <span class='primary--text'>{{formatMinutesToDuration(returnDuration[0])}} - {{formatMinutesToDuration(returnDuration[1])}}</span>
                            </v-col>
                        </v-row>
                        <v-row dense v-if='this.oldFlightType == "round"'>
                            <v-col cols='12'>
                                <v-range-slider @change='emitFilterByReturnDuration' :value='returnDuration' :thumb-label='true' :thumb-size='48' step='10' :min='minFlightReturnDuration' :max='maxFlightReturnDuration'>
                                    <template v-slot:thumb-label="props">
                                        <div class='text-center font-weight-bold'>{{ formatMinutesToDuration(props.value) }}</div>
                                    </template>
                                </v-range-slider>
                            </v-col>
                        </v-row>

                        <v-divider class='mb-4'></v-divider>

                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Layovers
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols='12'>
                                <v-select
                                :items="this.genLayOverOptions()"
                                label="Up to"
                                :value="layOver"
                                @change='emitFilterLayOver'
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class='mb-2'>
                    <v-card-text>
                        <v-row class='primary--text'>
                            <v-col cols='12'>
                                Connection Points
                            </v-col>
                        </v-row>

                        <!-- <v-row dense>
                            <v-col cols='12'>
                                <v-select
                                    :items="allConnectionPoints"
                                    :value='connectionPoints'
                                    attach
                                    chips
                                    deletable-chips
                                    multiple
                                    @change='emitFilterByConnectionPoints'
                                ></v-select>
                            </v-col>
                        </v-row> -->

                        <v-row dense>
                            <v-col cols='12'>
                                <v-list flat>
                                    <v-list-item>
                                        <v-btn outlined text block @click='selectDeselectAllConPoints'> {{allConnectionPoints.length == connectionPoints.length ? 'Deselect All' : 'Select All'}} </v-btn>
                                    </v-list-item>
                                    <v-list-item-group active-class='con-point-active' @change='emitFilterByConnectionPoints' multiple :value='connectionPoints'>
                                        <v-list-item class='px-0' :value='point.value' :key='id' v-for='(point, id) in allConnectionPoints'>
                                            <template v-slot:default="{ active }">
                                                <v-list-item-action class='mr-2'>
                                                    <v-checkbox
                                                    :input-value="active"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title class='text-body-2'>({{point.value}}) - {{point.text}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class='ml-0'>
                                                    <v-btn x-small text color='primary' @click.stop='emitFilterByConnectionPoints([point.value])'>Only</v-btn>
                                                </v-list-item-action>
                                            </template>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
        </div>
  </v-container>
</template>

<script>
  export default {
    name: 'FilterWidget',

    data: () => ({
        expandFilters: false,
        oldFlightType: 'round',
        layOverOptions: [2, 3, 4, 5, 10, 15, 20, 25]
    }),

    mounted: function () {
        this.oldFlightType = this.$store.state.flightType
        this.expandFilters = !this.isMobile()
    },

    watch: {
        '$store.state.flights': function () {
            this.oldFlightType = this.$store.state.flightType
        }
    },

    props: ['flights', 'sort', 'carrier', 'departureTime', 'returnTime', 'departureDuration', 'returnDuration', 'allConnectionPoints', 'layOver', 'connectionPoints', 'baggageAllowance', 'stops'],
    
    methods: {
        getCarriers() {
            let flightCarriers = this.$store.state.flights.map((flight) => { return {'text': flight.ValidatingCarrierName, 'value': flight.ValidatingCarrierCode} })
            let carriers = flightCarriers.filter(function(item, pos, self) {
                return self.indexOf(item) == pos;
            })
            carriers.unshift({'text': 'All airlines', 'value': ''})
            return carriers
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        },
        formatMinutesToHours (minutes) {
            let h = parseInt(minutes/60)
            let m = minutes-(h*60)
            let date = new Date(2000, 0, 1, h, m, 0)
            let fDate = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
            return fDate;
        },
        formatMinutesToDuration (minutes) {
            let d = parseInt(minutes/(24*60))
            let h = parseInt((minutes-d*(24*60))/60)
            let m = minutes - (d*24*60 + h*60)
            return d+'D '+h+'h '+m+'M';
        },
        timeStringToMinutes (tstring) { // There is a more complex version of timeStringToMinutes in Flights.vue, maybe update to that
            if (tstring == null)
              return 0

            let time = tstring.split(' ')
            let d = parseInt(time[0].slice(0, -1))
            let h = parseInt(time[1].slice(0, -1))
            let m = parseInt(time[2].slice(0, -1))
            return (d*60*24) + (h*60) + m
        },
        emitSort (sort) {
            this.$emit('sort', sort)
        },
        emitFilterByCarrier (carrier) {
            this.$emit('filter-by-carrier', carrier)
        },
        emitFilterByDepartTime (departTime) {
            this.$emit('filter-by-depart-time', [departTime[0], departTime[1]])
        },
        emitFilterByReturnTime (returnTime) {
            this.$emit('filter-by-return-time', [returnTime[0], returnTime[1]])
        },
        emitFilterByDepartDuration (departDuration) {
            this.$emit('filter-by-depart-duration', [departDuration[0], departDuration[1]])
        },
        emitFilterByReturnDuration (returnDuration) {
            this.$emit('filter-by-return-duration', [returnDuration[0], returnDuration[1]])
        },
        emitFilterLayOver (minutes) {
            this.$emit('filter-by-layover', minutes)
        },
        emitFilterByConnectionPoints(points) {
            this.$emit('filter-by-connection-points', points)
        },
        emitFilterByBaggage(allow) {
            this.$emit('filter-by-baggage', allow ? 1 : 0)
        },
        emitFilterByStopCount(stops) {
            this.$emit('filter-by-stop-count', stops)
        },
        emitClearFilters () {
            this.$emit('clear-filters')
        },
        genLayOverOptions () {
            let options = this.layOverOptions.map(option => {return {text: option+' hours', value: option*60}})
            options.unshift({text: 'Show all', value: 0})
            return options
        },
        selectDeselectAllConPoints () {
            if (this.allConnectionPoints.length == this.connectionPoints.length)
                this.emitFilterByConnectionPoints([])
            else
                this.emitFilterByConnectionPoints(this.allConnectionPoints.map(point => {return point.value}))
        },
    },

    computed: {
        maxFlightDepartureDuration: function () {
            let durations = this.$store.state.flights.map(flight => {
            return this.timeStringToMinutes(flight.Citypairs[0].Duration)
            })
            return Math.max.apply(Math, durations) === -Infinity ? 0 : Math.max.apply(Math, durations)
        },

        minFlightDepartureDuration: function () {
            let durations = this.$store.state.flights.map(flight => {
            return this.timeStringToMinutes(flight.Citypairs[0].Duration)
            })
            return Math.min.apply(Math, durations) === Infinity ? 0 : Math.min.apply(Math, durations)
        },

        maxFlightReturnDuration: function () {
            let durations = this.$store.state.flights.map(flight => {
                return this.timeStringToMinutes(flight.Citypairs[flight.Citypairs.length-1].Duration)
            })
            return Math.max.apply(Math, durations) === -Infinity ? 0 : Math.max.apply(Math, durations)
        },

        minFlightReturnDuration: function () {
            let durations = this.$store.state.flights.map(flight => {
                return this.timeStringToMinutes(flight.Citypairs[flight.Citypairs.length-1].Duration)
            })
            return Math.min.apply(Math, durations) === Infinity ? 0 : Math.min.apply(Math, durations)
        },

        firstFrom: function () {
            if (this.$store.state.flights.length > 0)
                return [this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureDisplayName]
            else
                return ['', '']
        },
        firstTo: function () {
            if (this.$store.state.flights.length > 0)
                return [this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].ArrivalLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].DestinationAirportName]
            else
                return ['', '']
        }

    }
  }
</script>

<style>
    .con-point-active {
        /* border: 1px solid black; */
    }

    .border-grey {
        border: thin solid #0000001f;
    }
</style>
