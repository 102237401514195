<template>
  <v-container fluid>
    <div class='primary--text font-weight-bold text-center text-h6' style='border: 1px solid #0000001f' v-if='!$store.state.expandSearch' @click='$store.state.expandSearch = true'>{{firstFrom[0]}} <v-icon small class='mx-1' color='primary'>mdi-airplane-takeoff</v-icon> {{firstTo[0]}} {{firstDepartureDate}} - {{firstReturnDate}}<v-icon small color='primary' class='ml-2'>mdi-pencil</v-icon></div>
    <v-expand-transition>
      <div v-if='$store.state.expandSearch'>
        <div>
          <v-row class="my-3" justify="start" no-gutters>
            <v-col cols='auto'>
              <!-- Round or single -->
              <v-btn-toggle
              v-model="$store.state.flightType"
              mandatory
              class='elevation-2'
              >
                <v-btn large text value="single" active-class='primary white--text'>
                  <span>One Way</span>
                </v-btn>

                <v-btn large text value="round" active-class='primary white--text'>
                  <span>Round Trip</span>
                </v-btn>

                <v-btn large text value="multi" active-class='primary white--text'>
                  <span>Multi City</span>
                </v-btn>

              </v-btn-toggle>
            </v-col>

            <v-btn v-if='!this.$store.state.searching && this.$store.state.flights.length != 0 && this.$route.name == "Flights"' @click='$store.state.expandSearch=false' large icon class='hidden-md-and-up ml-2'><v-icon>mdi-close</v-icon></v-btn>
          </v-row>
          <v-row class="" dense>
          
            <!-- From location -->
            <v-col cols="12" lg='2'>
              <v-autocomplete append-icon='' class='mb-2 mb-lg-0' id='locationInputFrom' :no-filter="true" solo filled ref='locationInputFrom' @input='onAirportSelect(0)' :search-input.sync="$store.state.fromSearch" @click='clearSearchField("from")' hide-no-data dense hide-details outlined placeholder='From' prepend-inner-icon="mdi-map-marker" :items="$store.state.fromLocations" v-model='$store.state.from'></v-autocomplete>
            </v-col>

            <!-- To location -->
            <v-col cols="12" lg='2'>
              <v-autocomplete append-icon='' class='mb-2 mb-lg-0' id='locationInputTo' :no-filter="true" solo filled ref='locationInputTo' @input='onAirportSelect(1)' :search-input.sync="$store.state.toSearch" @click='clearSearchField("to")' hide-no-data dense hide-details outlined placeholder='To' prepend-inner-icon="mdi-map-marker" :items="$store.state.toLocations" v-model='$store.state.to'></v-autocomplete>
            </v-col>

            <!-- Departure date -->
            <v-col cols="12" lg='2'>
              <v-menu
                v-model="showDatePickerDeparture"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class='mb-2 mb-lg-0'
                    solo
                    filled
                    dense
                    hide-details
                    v-model="$store.state.departureDate"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="$store.state.departureDate"
                  :min="new Date().toISOString()"
                  @input="onDepartureDateSelected"
                ></v-date-picker>
              </v-menu>
            </v-col>
            
            <!-- Return date -->
            <v-col v-show='$store.state.flightType != "multi"' cols="12" lg='2'>
              <v-menu
                v-model="showDatePickerReturn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class='mb-2 mb-lg-0'
                    solo
                    filled
                    :disabled='$store.state.flightType == "single"'
                    dense
                    hide-details
                    v-model="$store.state.returnDate"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="$store.state.returnDate"
                  :min="$store.state.departureDate"
                  @input="() => {this.showDatePickerReturn = false; this.showFlightClassMenu = true}"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <!-- Class and passangers select -->
            <v-col cols="12" lg='3'>
              <v-menu
                v-model="showFlightClassMenu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :disabled='$store.state.searching' class='mb-2' color='white' v-bind="attrs" v-on="on" block height='40'>{{getPassangerCount()}} Traveler(s) | {{getFlightClassName()}} Class </v-btn>
                </template>

                <v-card>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Adults (12+ years)</v-list-item-title>
                      <v-btn icon color='secondary' @click="removePassanger(0)"><v-icon>mdi-minus</v-icon></v-btn>{{$store.state.adults}}<v-btn icon color='secondary' @click="addPassanger(0)"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title>Children (2-11 years)</v-list-item-title>
                      <v-btn icon color='secondary' @click="removePassanger(1)"><v-icon>mdi-minus</v-icon></v-btn>{{$store.state.children}}<v-btn icon color='secondary' @click="addPassanger(1)"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title>Infant (Under 2 years)</v-list-item-title>
                        <v-btn icon color='secondary' @click="removePassanger(2)"><v-icon>mdi-minus</v-icon></v-btn>{{$store.state.infants}}<v-btn icon color='secondary' @click="addPassanger(2)"><v-icon>mdi-plus</v-icon></v-btn>
                    </v-list-item>
                  </v-list>

                  <v-card-text>
                    <v-select
                      :items="flightClasses"
                      label="Class"
                      dense
                      outlined
                      v-model='$store.state.flightClass'
                    ></v-select>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"  @click="showFlightClassMenu = false; searchForFlights();">
                      Confirm
                    </v-btn>
                  </v-card-actions>

                </v-card>
              </v-menu>
            </v-col>

            <!-- Search button -->
            <v-col cols="12" lg='1' v-if='$store.state.flightType != "multi"'>
              <v-btn block color='primary' height='40' @click='searchForFlights' :loading='$store.state.searching'>Search</v-btn>
            </v-col>

          </v-row>
        </div>
        <div v-if='$store.state.flightType == "multi"'>
          <div :key='id' v-for='(destination, id) in $store.state.additionalDestinations'>
            <v-row class="" dense>
            
              <!-- From location -->
              <v-col cols="12" lg='2'>
                <v-autocomplete append-icon='' class='mb-2 mb-lg-0' id='locationInputFrom' :no-filter="true" solo filled ref='locationInputFrom' @update:search-input='onLocationInput(0, id)' :search-input.sync="destination.searchInputFrom" @click='clearSearchField("from", id)' hide-no-data dense hide-details outlined placeholder='From' prepend-inner-icon="mdi-map-marker" :items="destination.fromLocations" v-model='destination.DepartureLocationCode'></v-autocomplete>
              </v-col>

              <!-- To location -->
              <v-col cols="12" lg='2'>
                <v-autocomplete append-icon='' class='mb-2 mb-lg-0' id='locationInputTo' :no-filter="true" solo filled ref='locationInputTo' @update:search-input='onLocationInput(1, id)' :search-input.sync="destination.searchInputTo" @click='clearSearchField("to", id)' hide-no-data dense hide-details outlined placeholder='To' prepend-inner-icon="mdi-map-marker" :items="destination.toLocations" v-model='destination.ArrivalLocationCode'></v-autocomplete>
              </v-col>

              <!-- Departure date -->
              <v-col cols="12" lg='2'>
                <v-menu
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class='mb-2 mb-lg-0'
                      solo
                      filled
                      dense
                      hide-details
                      v-model="$store.state.additionalDestinations[id].DepartureTime"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="$store.state.additionalDestinations[id].DepartureTime"
                    :min="new Date().toISOString()"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg='1'>
                <v-btn v-if='$store.state.additionalDestinations.length > 1' icon @click='removeMultiCityDestination(id)' :disabled='$store.state.searching' height='40'><v-icon>mdi-close</v-icon></v-btn>
              </v-col>

              <v-col cols="12" lg='2'>
                <v-btn v-if='$store.state.additionalDestinations.length-1 == id' @click='addMultiCityDestination' block :disabled='$store.state.searching' color='primary' height='40'><v-icon>mdi-plus</v-icon> Add flight</v-btn>
              </v-col>

              <!-- Search button -->
              <v-col cols="12" lg='1'>
                <v-btn v-if='$store.state.additionalDestinations.length-1 == id' block color='primary' height='40' @click='searchForFlights' :loading='$store.state.searching'>Search</v-btn>
              </v-col>

            </v-row>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script>
  export default {
    name: 'SearchWidget',

    data: () => ({
      flightClasses: [
        {value: 'e', text: 'Economy'},
        {value: 'p', text: 'Premium'},
        {value: 'b', text: 'Business'},
        {value: 'f', text: 'First'}
      ],
      showFlightClassMenu: false,
      showDatePickerDeparture: false,
      showDatePickerReturn: false,
      showDatePickerAdditional: null,
    }),

    mounted: function () {
      this.$refs.locationInputFrom.focus()
      if (this.$route.name == 'Flights')
        this.$root.$on('search', this.searchForFlights)
    },

    computed: {
      firstFrom: function () {
        if (this.$store.state.flights.length > 0)
            return [this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureDisplayName]
        else
            return ['', '']
      },
      firstTo: function () {
        if (this.$store.state.flights.length > 0)
            return [this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].ArrivalLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].DestinationAirportName]
        else
            return ['', '']
      },
      firstDepartureDate: function () {
        if (this.$store.state.flights.length > 0)
            return new Date(this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureDateTime).toLocaleString('en-US', {day: '2-digit', month: 'short'})
        else
            return ''
      },
      firstReturnDate: function () {
        if (this.$store.state.flights.length > 0) {
          if (this.$store.state.flights[0].Citypairs.length > 1)
            return new Date(this.$store.state.flights[0].Citypairs[1].FlightSegment[0].DepartureDateTime).toLocaleString('en-US', {day: '2-digit', month: 'short'})
          else
            return ''
        }
        else
            return ''
      }
    },
    
    methods: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile
      },
      getPassangerCount () {
        return this.$store.state.adults+this.$store.state.children+this.$store.state.infants;
      },

      getFlightClassName() {
        return this.flightClasses.filter((flightClass) => {
          return flightClass.value == this.$store.state.flightClass;
        })[0].text;
      },
      
      removePassanger(passangerType) {
        if (passangerType == 0) {
          if (this.$store.state.adults > 1)
            this.$store.state.adults--;
        }
        else if (passangerType == 1)
        {
          if (this.$store.state.children > 0)
            this.$store.state.children--;
        }
        else if (passangerType == 2)
        {
          if (this.$store.state.infants > 0)
            this.$store.state.infants--;
        }
      },
      
      addPassanger(passangerType) {
        if (passangerType == 0)
          this.$store.state.adults++;
        else if (passangerType == 1)
          this.$store.state.children++;
        else if (passangerType == 2)
          this.$store.state.infants++;
      },

      getAirportCodes(airports) {
        return airports.map((airport) => {
          return {'value': airport.Code, 'text': airport.City};
        })
      },

      onAirportSelect(fromTo) {
        if (this.$store.state.flightType != 'multi') {
          if (fromTo == 0) // From
          {
            this.$refs.locationInputTo.focus()
          }
          else if (fromTo == 1) // To
          {
            this.showDatePickerDeparture = true
          }
        }
      },

      onLocationInput(fromTo, id = null) {
        if (id == null) {
          if (fromTo == 0) // From
          {
            if (this.$store.state.fromSearch != '' && this.$store.state.fromSearch != null && this.$store.state.fromSearch.length >= 3)
            {
              this.$http.get('https://www.flights365.com/airports/'+this.$store.state.fromSearch).then((response) => {
                if (!('message' in response.data))
                  this.$store.state.fromLocations = this.getAirportCodes(response.data)
              })
            }
            else {
              this.$store.state.fromLocations = []
              this.$store.state.from = ''
            }
          }
          else if (fromTo == 1) // To
          {
            if (this.$store.state.toSearch != '' && this.$store.state.toSearch != null && this.$store.state.toSearch.length >= 3)
            {
              this.$http.get('https://www.flights365.com/airports/'+this.$store.state.toSearch).then((response) => {
                if (!('message' in response.data))
                  this.$store.state.toLocations = this.getAirportCodes(response.data)
              })
            }
            else {
              this.$store.state.toLocations = []
              this.$store.state.to = ''
            }
          }
        }

        else { // Multi city
          setTimeout (() => { // Hack
            if (fromTo == 0) // From
            {
              if (this.$store.state.additionalDestinations[id].searchInputFrom != '' && this.$store.state.additionalDestinations[id].searchInputFrom != null && this.$store.state.additionalDestinations[id].searchInputFrom.length >= 3)
              {
                this.$http.get('/airports/'+this.$store.state.additionalDestinations[id].searchInputFrom).then((response) => {
                  this.$store.state.additionalDestinations[id].fromLocations = this.getAirportCodes(response.data)
                })
              }
              else {
                this.$store.state.additionalDestinations[id].fromLocations = []
                this.$store.state.additionalDestinations[id].DepartureLocationCode = ''
              }
            }
            else if (fromTo == 1) // To
            {
              if (this.$store.state.additionalDestinations[id].searchInputTo != '' && this.$store.state.additionalDestinations[id].searchInputTo != null && this.$store.state.additionalDestinations[id].searchInputTo.length >= 3)
              {
                this.$http.get('/airports/'+this.$store.state.additionalDestinations[id].searchInputTo).then((response) => {
                  this.$store.state.additionalDestinations[id].toLocations = this.getAirportCodes(response.data)
                })
              }
              else {
                this.$store.state.additionalDestinations[id].toLocations = []
                this.$store.state.additionalDestinations[id].ArrivalLocationCode = ''
              }
            }
          }, 50) // Hack
        }
      },

      onDepartureDateSelected () {
        if (this.$store.state.departureDate > this.$store.state.returnDate)
          this.$store.state.returnDate = this.$store.state.departureDate
        this.showDatePickerDeparture = false;
        if (this.$store.state.flightType != 'multi') {
          this.showFlightClassMenu = (this.$store.state.flightType == "single" ? true : false);
          this.showDatePickerReturn = (this.$store.state.flightType == "single" ? false : true);
        }
      },

      pad(n, width = 2, z = 0) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      },

      formatDateToSlashes(date) {
        return this.pad(new Date(date).getUTCDate())+'/'+this.pad(new Date(date).getUTCMonth()+1)+'/'+new Date(date).getUTCFullYear()
      },

      hideJCB (flights) {
        let flight_with_hidden = flights.map(flight => {
          let flight_modified = flight

          if (flight.FareType == 'JCB') {
            if (['LH', 'SN', 'LX', 'OS', 'BA', 'IB', 'KL', 'AF'].includes(flight.ValidatingCarrierCode)) {
              flight_modified.ValidatingCarrierCode = 'MAJOR_EU'
              flight_modified.ValidatingCarrierName = 'Major EU airline'
            }
            else if (['AC', 'UA', 'DL', 'AA'].includes(flight.ValidatingCarrierCode)) {
              flight_modified.ValidatingCarrierCode = 'MAJOR_US'
              flight_modified.ValidatingCarrierName = 'Major US airline'
            }
          }

          return flight_modified
        })

        return flight_with_hidden
      },

      removeMultiCityDestination (id) {
        this.$store.state.additionalDestinations.splice(id, 1)
      },

      addMultiCityDestination () {
        this.$store.state.additionalDestinations.push({
          "DepartureTime": (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          "DepartureLocationCode": "",
          "ArrivalLocationCode": "",
          "CabinClass": "E",
          "searchInputFrom": "",
          "searchInputTo": "",
          "fromLocations": [],
          "toLocations": []
        })
      },

      clearSearchField(fromTo, id = null) {
        if (id == null) {
          if (fromTo == 'from' && this.$store.state.from != '')
            this.$store.state.fromSearch = ''
          else if (fromTo == 'to' && this.$store.state.to != '')
            this.$store.state.toSearch = ''
        }

        // Clear by id for multicity
        else {
          if (fromTo == 'from' && this.$store.state.from != '')
            this.$store.state.additionalDestinations[id].DepartureLocationCode = ''
          else if (fromTo == 'to' && this.$store.state.to != '')
            this.$store.state.additionalDestinations[id].ArrivalLocationCode = ''
        }
      },

      getAvgFlightCost (flight) { // Per person
        let adultPrice = 0
        let childrenPrice = 0
        flight.Fares.forEach(fare => {
            if (fare.PaxType == 'ADT')
                adultPrice = fare.Taxes+fare.BaseFare
            else if (fare.PaxType == 'CHD')
                childrenPrice = fare.Taxes+fare.BaseFare
        });
        let avgPrice = (adultPrice+childrenPrice)/(childrenPrice == 0 ? 1 : 2)
        return avgPrice.toFixed(2);
      },

      filterAlternatives (alternatives, exact) {
        let sorted_exact = exact.sort((a, b) => {
          return this.getAvgFlightCost(a)-this.getAvgFlightCost(b)
        })

        let lowest_exact_price = this.getAvgFlightCost(sorted_exact[0])
        let filtered_alternatives = alternatives.filter((alternative) => {
          return this.getAvgFlightCost(alternative) < lowest_exact_price
        })
        return filtered_alternatives 
      },

      multiCityValid() {
        if (this.$store.state.flightType != 'multi') // Multi City not enabled
          return true
        else {
          return !this.$store.state.additionalDestinations.map((destination) => {
            return [destination.DepartureLocationCode, destination.ArrivalLocationCode]
          }).flat().includes('')
        }
      },

      searchForFlights() {
        window.fbq('track', 'Search')
        // Check if locations supplied
        if (this.$store.state.from != '' && this.$store.state.to != '') { 
          // Check if locations match
          if (this.$store.state.from != this.$store.state.to && this.multiCityValid()) {
            let departureIsoDate = new Date(Date.parse(this.$store.state.departureDate) - (new Date().getTimezoneOffset() * 60000)).toISOString()
            let returnIsoDate = new Date(Date.parse(this.$store.state.returnDate) - (new Date().getTimezoneOffset() * 60000)).toISOString()
            let searchData = {
                "from": this.$store.state.from,
                "to": this.$store.state.to,
                "status": this.$store.state.flightType == "round" ? 1 : 2,
                "class": this.$store.state.flightClass.toUpperCase(),
                "adult": this.$store.state.adults,
                "infant": this.$store.state.infants,
                "fromDate": departureIsoDate,
                "toDate": returnIsoDate,
                "child": this.$store.state.children,
                "date": this.formatDateToSlashes(this.$store.state.departureDate),
                "date2": this.formatDateToSlashes(this.$store.state.returnDate),
                "search": true
            }
            if (this.$store.state.flightType == "single") {
              delete searchData.date2;
              delete searchData.toDate;
            }

            if (this.$store.state.flightType == "multi") {
              searchData.destinations = this.$store.state.additionalDestinations.map(destination => {
                return {
                  "DepartureTime": this.formatDateToSlashes(destination.DepartureTime),
                  "DepartureLocationCode": destination.DepartureLocationCode,
                  "ArrivalLocationCode": destination.ArrivalLocationCode,
                  "CabinClass": this.$store.state.flightClass.toUpperCase()
                }
              })
              searchData.destinations.unshift({
                "DepartureTime": this.formatDateToSlashes(this.$store.state.departureDate),
                "DepartureLocationCode": this.$store.state.from,
                "ArrivalLocationCode": this.$store.state.to,
                "CabinClass": this.$store.state.flightClass.toUpperCase()
              })
            }

            this.$store.state.flights = [];
            this.$store.state.searching = true;
            if (this.$route.name != 'Flights')
              this.$router.push('/flights');
            this.$http.post(this.backend_address+"/api/v1/search"+(this.$store.state.flightType == "round" ? '/round' : (this.$store.state.flightType == "multi" ? '/multi' : '')), searchData).then(response => {
              this.$store.state.searching = false;
              this.$store.state.currentPage = 1;

              let flexiItinerary = []
              if ('FlexiItinerary' in response.data.response)
                flexiItinerary = response.data.response.FlexiItinerary

              let nearLocationItinerary = []
              if ('NearLocationItinerary' in response.data.response)
                nearLocationItinerary = response.data.response.NearLocationItinerary

              // Hide JCB Names
              let flights_with_hidden = this.hideJCB(response.data.response.FlightItinerary)
              let flights_alt_with_hidden = this.hideJCB(response.data.response.FlightItinerary.concat(this.filterAlternatives(flexiItinerary.concat(nearLocationItinerary), response.data.response.FlightItinerary)))

              this.$store.state.flights = flights_with_hidden;
              this.$store.state.altFlights = flights_alt_with_hidden;
              this.$store.state.hasSearched = true;
              if (this.$route.name == 'Flights')
                this.$root.$emit('search-end')
              if (this.isMobile() && this.$store.state.flights.length > 0) {
                this.$store.state.expandSearch = false
              }
            })
          }
        }
      }
    },

    watch: {
      '$store.state.fromSearch' : function () {
        this.onLocationInput(0);
      },

      '$store.state.toSearch' : function () {
        // this.$refs.locationInputTo.focus()
        this.onLocationInput(1);
      }
    }
  }
</script>
<style>
.theme--light.v-text-field--filled>.v-input__control>.v-input__slot {
  background: white !important;
  max-width:350px;
}
</style>
