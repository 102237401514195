<template>
  <v-container class='text-subtitle-1'>
    <div class='my-8'><span class='primary--text'>www.flights365.com</span> website is an internet-based travel portal owned and operated by Flights365 a company incorporated under the laws of the United States of America</div>
    <div class='mb-8'>Before using our services you are required to read the user agreement and accept all the terms and conditions listed below.</div>
    <div class='primary--text text-h6 my-6'>Terms And Conditions</div>
    <div class='mb-8'>By making use of this site, you shall be presumed to have agreed to the terms and conditions outlined below. If you do not accept these terms and conditions, do not continue to use or access this site. Use Of Site</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> grants you a limited, non-transferable license to use this site in accordance with the terms and conditions of this User Agreement.</div>
    <div class='mb-8 ml-6'>&#8226; You may only use this site to make legitimate reservations or purchases and shall not use this site for any other purposes, including without limitation, to make any speculative, false or fraudulent reservation or any reservation in anticipation of demand.</div>
    <div class='mb-8 ml-6'>&#8226; You shall not make a reservation in the name of a fictitious person.</div>
    <div class='mb-8 ml-6'>&#8226; You agree that you will not use any device, software, or routine that would interfere or be reasonably expected to interfere with the normal working of this site.</div>
    <div class='mb-8 ml-6'>&#8226; You agree that you shall not take any action that imposes a burden or load on our infrastructure that <span class='primary--text'>www.flights365.com</span> deems in its sole discretion to be unreasonable or disproportionate to the benefits <span class='primary--text'>www.flights365.com</span> obtains from your use of the site.</div>
    <div class='mb-8 ml-6'>&#8226; You shall be solely liable for any damages resulting from any infringement of copyright, trademark, or other proprietary rights, or any other harm resulting from your use of this site.</div>
    <div class='primary--text text-h6 my-6'>Age & Responsibility</div>
    <div class='mb-8'>By using this site, you accept that you alone are responsible for maintaining the confidentiality of your account information and your password/s. You agree to accept responsibility for all activities that occur under your account or password. You represent that you are of age prescribed by law to be eligible to enter into a contract and use this to create binding reciprocal legal obligations, and are capable, ready, and willing to discharge any liability you may incur as a result of the use of this site. You understand that you are financially responsible for all uses of this site by you and those using your login information.</div>
    <div class='primary--text text-h6 my-6'>Accuracy Of Information</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> does not warrant or guarantee the accuracy or completeness of the information provided on this Website. Under no circumstances <span class='primary--text'>www.flights365.com</span> will be liable for any loss or direct, indirect, incidental, special, or consequential damages caused by reliance on this information. The information on this Website may be changed or updated without notice.<span class='primary--text'>www.flights365.com</span> may also make improvements and/or changes in the products, services, and/or programs described on this site at any time without notice.</div>
    <div class='primary--text text-h6 my-6'>Confidentiality</div>
    <div class='mb-8'>Information concerning <span class='primary--text'>www.flights365.com</span> and its travel subsidiaries, their employees, customers, agents, suppliers, franchisees, affiliates, or others on whom data is collected, stored, or processed is the property of <span class='primary--text'>www.flights365.com</span> and is confidential except for the necessary disclosures required by law.</div>
    <div class='primary--text text-h6 my-6'>Use Of Communication Service</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> may contain services such as email, chat, bulletin board services, blogs, reviews, surveys, information related to various tourist spots, news groups, forums, communities, personal web pages, calendars, and/or other messages (collectively, “Communication Services”), you agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using a Communication Service, you will not:</div>
    <div class='mb-8 ml-6'>&#8226; Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</div>
    <div class='mb-8 ml-6'>&#8226; Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material, or information.</div>
    <div class='mb-8 ml-6'>&#8226; Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents.</div>
    <div class='mb-8 ml-6'>&#8226; Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer.</div>
    <div class='mb-8 ml-6'>&#8226; Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages.</div>
    <div class='mb-8 ml-6'>&#8226; Conduct or forward surveys, contests, pyramid schemes, or chain letters.</div>
    <div class='mb-8 ml-6'>&#8226; Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner.</div>
    <div class='mb-8 ml-6'>&#8226; Falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.</div>
    <div class='mb-8 ml-6'>&#8226; Violate any code of conduct or other guidelines, which may be applicable for or to any particular Communication Service.</div>
    <div class='mb-8 ml-6'>&#8226; The user should take particular care while disclosing any identifying information in any Communication Service, which may be identifiable.</div>
    <div class='mb-8 ml-6'>&#8226; Violate any applicable laws or regulations for the time being in force in or outside the United States of America.</div>
    <div class='mb-8 ml-6'>&#8226; Violate any of the terms and conditions of the user agreement or any other terms and conditions for the use of <span class='primary--text'>www.flights365.com</span> contained elsewhere herein.</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> has no obligation to monitor the Communication Services. However, <span class='primary--text'>www.flights365.com</span> reserves the right to review materials posted to a Communication Service and to remove any materials at its sole discretion.  <span class='primary--text'>www.flights365.com</span> reserves the right to terminate the user access to any or all of the Communication Services at any time without notice for any reason whatsoever.</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in <span class='primary--text'>www.flights365.com</span> sole discretion.</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> does not control or endorse the content, messages or information found in any Communication Service and, therefore, <span class='primary--text'>www.flights365.com</span> specifically disclaims any liability or responsibility whatsoever with regard to the Communication Services and any actions resulting from the users participation in any Communication Service.</div>
    <div class='mb-8'>Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination; User is responsible for keeping himself/herself updated of and adhering to such limitations if they download the materials.</div>
    <div class='primary--text text-h6 my-6'>Proprietary Rights</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> may provide the user with content such as sound, photographs, graphics, video or other material contained in sponsor advertisements or information. This material may be protected by copyrights, trademarks, or other intellectual property rights and laws. The user may use this material only as expressly authorized by Flights365 and shall not copy, transmit or create derivative works of such material without express authorization.</div>
    <div class='mb-8'>The user acknowledges and agrees that he/she shall not upload, post, reproduce, or distribute any content on or through the Services that is protected by copyright or other proprietary right of a third party, without obtaining the permission of the owner of such right. Any copyrighted or other proprietary content distributed with the consent of the owner must contain the appropriate copyright or other proprietary rights notice. The unauthorized submission or distribution of copyrighted or other proprietary content is illegal and could subject the user to personal liability or criminal prosecution.</div>
    <div class='primary--text text-h6 my-6'>Enforceability Of These Provisions</div>
    <div class='mb-8'>Should any of these terms and conditions be held invalid, that invalid provision shall be construed to be consistent with the applicable law, and in manner so as to remain consistent with the original intent of <span class='primary--text'>www.flights365.com</span> . Provisions not otherwise held invalid shall remain in force.</div>
    <div class='primary--text text-h6 my-6'>Security Warnings</div>
    <div class='mb-8'>It is up to you to take precautions to ensure that whatever you select for your use is free of such items as viruses, worms, Trojan horses, malicious codes and other items of a destructive nature. In no event will <span class='primary--text'>www.flights365.com</span> be liable to any party for any loss or direct, indirect, incidental, special or consequential damages caused by use of this website, or any other hyperlinked or interfaced website or due to accessing data residing on third party servers not belonging to <span class='primary--text'>www.flights365.com</span> .</div>
    <div class='primary--text text-h6 my-6'>Products & Services</div>
    <div class='mb-8'>Please Note that none of the information contained in this Website should be viewed as an offer to sell or as a solicitation to purchase any of our products or services. Rather, the information on our products and services is provided to you so that you can learn what products <span class='primary--text'>www.flights365.com</span> generally offers. The products and service statements on this Website are for general description purposes only. Furthermore, not all the products or services are available in every state or country. Send us a message via our Contact us, we will supply you with details regarding terms and conditions, exclusions, products, and services applicable to you.</div>
    <div class='mb-8 ml-6'>a. Hotels: While booking a hotel on our site please carefully go through the fact sheet in detail to ensure that the amenities present are as per your requirement. <span class='primary--text'>www.flights365.com</span> generally updates all these fact sheets however it is dependent on the hotel to provide updated information. Renovation at hotels is normally undertaken at the sole discretion of hotels and not necessarily is informed to us. In case of any dispute <span class='primary--text'>www.flights365.com</span> shall not be liable for disruption in information.</div>
    <div class='mb-8 ml-6'>b. Sightseeing: You have a choice of seat in coach sightseeing and private sightseeing tours. Please choose the same carefully. Panoramic tours do not allow you to stop at the site of the attractions. You will have to follow a schedule. Entrances to attractions are not included in city tours unless specified.</div>
    <div class='mb-8 ml-6'>c. Airlines: You have a choice of full service and low cost airline options. Low cost airlines do not have all services available on board hence please choose the airlines carefully before booking. Kindly go through the fare rules and also select your class of travel. There are paper tickets and/or e-tickets that will be provided to you. Both are valid to travel by the airlines. However certain airlines do not entertain e-tickets. In such cases you will be issued paper tickets. We are only an intermediary party hence cannot be held responsible for any delay or cancellation of flight or change of route - a decision taken by the airline. You will be subject to all terms and conditions as per the airlines.</div>
    <div class='mb-8 ml-6'>d. Holidays: Please refer to inclusions and exclusions carefully before booking. You can customize your holiday completely on our site if our packages do not meet your requirements.</div>
    <div class='mb-8 ml-6'>e. Rail and Cruises: Please refer to individual rules applicable against both rail and cruise providers. These rules will be applicable for each transaction made through us.</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> is a travel and holiday organizer. We do not control or operate any airline, neither do we control or own any shipping company hotel or transport or any other facility or service mentioned on the site. We take care in selecting all the principles however but because we select them- we have no control on running them. We cannot be held responsible for any lack of service by any independent agency airline transport hotel or any other service provider.</div>
    <div class='primary--text text-h6 my-6'>Baggage</div>
    <div class='mb-8'>You will have to limit yourself as per normal baggage policy of the airlines in the respective class that you have chosen to fly. Any excess baggage that you carry shall remain your responsibility. Any loss of baggage by the airlines or the passenger will be the sole responsibility of the airline and the passenger. Normally economy class 20kgs is permitted however if you are traveling to US/ Canada you are permitted to carry 2 pieces as check in and 1 piece as cabin baggage. You are requested to kindly refer to the baggage rules of the individual airlines as the same gets updated from time to time.</div>
    <div class='primary--text text-h6 my-6'>Health And Insurance</div>
    <div class='mb-8'>It is advisable that the passenger acquires adequate insurance cover to secure their life health and property. You can purchase the same from our site. Your health and good life is of utmost importance to us.</div>
    <div class='primary--text text-h6 my-6'>Professional Advice</div>
    <div class='mb-8'>The information provided on this site is distributed with the understanding that Flights365 is not providing professional advice of any type. If you have a question requiring professional advice, such as question relating to law, tax or financial planning, please seek the advice of a qualified professional in the relevant field.</div>
    <div class='primary--text text-h6 my-6'>Warranties</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> specifically disclaims all warranties with respect to this Web site or your use thereof, express, implied, or otherwise, including without limitation, all warranties of merchantability and fitness for a particular purpose. Flights365 shall not be liable for any damages resulting from the use or misuse of this site or the information on this site.</div>
    <div class='primary--text text-h6 my-6'>Payments</div>
    <div class='mb-8'>All online payments that happen for any of the products booked via <span class='primary--text'>www.flights365.com</span> site are secure. <span class='primary--text'>www.flights365.com</span> does not store the Credit card details of any customer in their database or in any data backup system or retrieval systems. Once you are ready to transact you are transferred to the bank site. The card details are captured on the credit card site and not ours hence we cannot be held responsible for any misuse of credit card what so ever.</div>
    <div class='primary--text text-h6 my-6'>Refund</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> reserves the right to determine the amount of refund value incase of cancellation and amendments. The decision on the quantum of refund will be final as that made by <span class='primary--text'>www.flights365.com</span> . The refund will be made in the name of the client. Incase you have booked through the Travel Agent or a Franchisee still we will make the refund in the passengers name. It will take at least 45 days to process refunds for all services. There will be no refund for unutilised services unless specified. All cancellation and amendment charges will be applicable as per principles policies on which <span class='primary--text'>www.flights365.com</span> has no control and you will be subjected to it.</div>
    <div class='primary--text text-h6 my-6'>Governing Law</div>
    <div class='mb-8'>All legal disputes will be settled under the laws of US courts subject to laws of Republic of United States of America. In the event of any dispute of whatever nature, arising under this Agreement, such dispute shall be settled through good faith negotiation among the parties. In case such dispute can not be resolved by negotiation within 30 days such dispute shall be referred to binding arbitration. The decision of the arbitral tribunal shall be final and binding upon the parties.</div>
    <div class='primary--text text-h6 my-6'>Injunctive Relief</div>
    <div class='mb-8'>You acknowledge that a violation or attempted violation of any of this User Agreement and these terms and conditions will cause such damage to <span class='primary--text'>www.flights365.com</span> as will be irreparable, the exact amount of which would be difficult to ascertain and for which there will be no adequate remedy at law. Accordingly, you agree that <span class='primary--text'>www.flights365.com</span> shall be entitled as a matter of right to an injunction issued by any court of competent jurisdiction, restraining such violation or attempted violation of these terms and conditions by You, or Your affiliates, partners, or agents, as well as recover from You any and all costs and expenses sustained or incurred by <span class='primary--text'>www.flights365.com</span> in obtaining such an injunction, including, without limitation, reasonable attorney’s fees. You agree that no bond or other security shall be required in connection with such injunction.</div>
    <div class='primary--text text-h6 my-6'>Privacy Policy</div>
    <div class='mb-8'>You have read the <span class='primary--text'>www.flights365.com</span> Privacy Policy, the terms of which are incorporated herein, and agree that the terms of such policy are reasonable. You consent to the use of your personal information by <span class='primary--text'>www.flights365.com</span> and/or its third party providers and distributors in accordance with the terms of and for the purposes set forth in the <span class='primary--text'>www.flights365.com</span> Privacy Policy. <span class='primary--text'>www.flights365.com</span> prompts all its users to register at one time. The details captured during registration are purely to provide better service in future. <span class='primary--text'>www.flights365.com</span> will not be sharing these details with any other third party.</div>
    <div class='primary--text text-h6 my-6'>Termination</div>
    <div class='mb-8'><span class='primary--text'>www.flights365.com</span> may terminate this User Agreement and these terms and conditions and/or the provision of any of the services at any time for any reason, including any improper use of this site or your failure to comply with these terms and conditions. Such termination shall not affect any right to relief to which <span class='primary--text'>www.flights365.com</span> and its third party providers and distributors may be entitled, at law or in equity. Upon termination of this User Agreement and these terms and conditions, all rights granted to you will terminate and revert to <span class='primary--text'>www.flights365.com</span> and its third party providers or distributors, as applicable.</div>
    <div class='primary--text text-h6 my-6'>Taxes and Fees</div>
    <div class='mb-8'>In addition to airlines cost and fees, Flights365 would charge a service fee for any service rendered over the Phone. All fees charged by Flights365 are charged ,basis per passenger and are non - refundable.</div>
    <div class='primary--text text-h6 my-6'>Post-Ticketing Fees (flights365.com)</div>
    <div style='overflow-x: auto;'>
      <table class='infotable mb-8' style="table-layout: fixed; width: 755px">
        <colgroup>
        <col style="width: 400px">
        <col style="width: 170px">
        <col style="width: 170px">
        <col style="width: 170px">
        <col style="width: 170px">
        </colgroup>
        <thead>
          <tr>
            <th rowspan="2">Changes, Cancellation and Refunds to Existing Tickets</th>
            <th colspan="2">Air - Economy (Amount Per Ticket)</th>
            <th colspan="2">Air - Business/First (Amount Per Ticket)</th>
          </tr>
          <tr>
            <td>Domestic<br></td>
            <td>International</td>
            <td>Domestic</td>
            <td>International</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Void/Cancel (Within 24 Hours) - US Origin Bookings</td>
            <td>$0</td>
            <td>$0</td>
            <td>$0</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Void/Cancel (Same day before Midnight) - NON US ORIGIN</td>
            <td>$0</td>
            <td>$0</td>
            <td>$0</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Cancellation with Future Credit (Beyond 24 hrs for US Origin Bookings/ Beyond Midnight for Non US Origin Bookings)</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
          </tr>
          <tr>
            <td>Cancellation with Refund (Beyond 24 hrs for US Origin Bookings/ Beyond Midnight for Non US Origin Bookings)</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
          </tr>
          <tr>
            <td>Voluntary Changes- (Changes to Itinerary, Dates , Route etc)</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
            <td>$25</td>
          </tr>
          <tr>
            <td>Seats</td>
            <td>$10</td>
            <td>$10</td>
            <td>$10</td>
            <td>$10</td>
          </tr>
          <tr>
            <td>All other services</td>
            <td colspan="4">$25 Per Ticket/Per Passanger</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class='mb-8'>Airlines Fees and Service fee may reflect with two different Charges on your account.</div>
    <div class='mb-8'>Any Passenger occupying a seat - Adult,Child,Infant with Seat,Senior Citizen, would be liable to Pay Fees.</div>
    <div class='mb-8'>Service fees on all changes, refunds, cancellations and future credits will be charged on a per passenger, per ticket basis.</div>
    <div class='mb-8'>Like our transaction service fees (booking fees), all post-ticketing service fees are non-refundable and are subject to change without notice. Our fees are in addition to any airline and/or other supplier imposed fees and charges.</div>
    <div class='mb-8'>Most of our airline tickets are non-refundable. Airline Refunds/Future credits are subject to airline fare rules, policies and procedures.</div>
  </v-container>
</template>

<script>

  export default {
    name: 'TermsAndConditions',

    created: function () {
      document.title = "Flights365 | Terms And Conditions";
    },

    components: {
    },
  }
</script>
<style>
.infotable, .infotable th, .infotable td {
  border: 1px solid black;
  border-collapse: collapse;
}

.infotable td {
  padding: 10px;
  text-align: center;
}
</style>
