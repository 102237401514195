<template>
  <div>
    <div class='plane-bg'>
      <v-container fluid>
        <div class='white--text text-md-h2 text-h5 text-center mt-4 font-weight-light' style="margin: 1.5rem; text-shadow:0px 0px 13px black"><span :class="{'font-weight-regular': $vuetify.breakpoint.xs}">Book Cheap Domestic & International Flights</span></div>
        <search-widget style="max-width: 76rem; background-color: rgba(255, 255, 255,0.4); border: 1px solid rgba(255, 255, 255,0.2);" />
      </v-container>
    </div>
    <v-container class='px-md-16' style="display: flex; max-width: 65.5rem; margin: auto; flex-direction: column; justify-content: center;">

      <!-- <carousel class='my-10' :navigationEnabled="isMobile() ? false : true" :autoplay="true" :scrollPerPage="false" :perPage='isMobile() ? 1 : 2' :paginationEnabled='false' loop>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fnellie.christoff%2Fposts%2F4671913749493882&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmariya.mineva.58%2Fposts%2F277440180560914&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2FRuzhkaRaynova%2Fposts%2F10222851806816996&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmilenadelevaivanova%2Fposts%2F10157919688053062&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpetia.petrova.7547%2Fposts%2F4504820969533177&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
        <slide class='px-4'>
          <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fdani.radkova%2Fposts%2F10225068977093286&show_text=true&width=500" width="100%" height="250" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </slide>
      </carousel> -->

      <v-row class='mt-8' dense justify="center">
        <v-col cols='12' md='4' v-for='(banner, id) in banners' :key='id'>
          <router-link class='text-subtitle-2 text--primary footer-link' :to='"/landing/"+banner.searchterm'>
            <div v-ripple :class="banner.classname">
              Flight Deals to
              <hr class='mb-2 mt-2 ml-3' style="border-top:1px solid white; width: 50%;"/>
              <div class='mb-2 text-h4 font-weight-bold ml-6' style="text-shadow: black 2px 2px 4px;">{{banner.destination}}</div>
              <div class='mt-4 text-body-1 text-right mr-1'>price from</div>
              <div class='mb-2 text-h5 text-right font-weight-bold' style="text-shadow: black 2px 2px 4px;">${{banner.price}}</div>
            </div>
          </router-link>
        </v-col>
      </v-row>

      <v-row justify='center' style='margin: 34px 0px;' class='mb-2 grey lighten-3 pt-5'>
        <v-row justify='center' no-gutters class='primary--text text-h5 font-weight-bold mb-2 text-center'><v-col cols='auto'>Sign-up Now for Exclusive Email-only Offers</v-col></v-row>
        <v-row justify='center' no-gutters class='mb-2 text--secondary text-center'><v-col cols='auto'>Be a privileged member to access discounted coupons, special offers and promotional deals.</v-col></v-row>
        <v-row justify='center' dense>
          <v-col cols='auto'>
            <v-text-field v-model='signupEmail' dense append='sdf' solo outlined placeholder='Enter your email'></v-text-field>
          </v-col>
          <v-col cols='auto'>
            <v-btn :disabled='!isSignUpEmailValid' @click='signUp' block height='40' color='primary'>Sign Up</v-btn>
          </v-col>
        </v-row>
      </v-row>

      <v-row class='mt-md-8' no-gutters justify='center' align='center' style='/*height: 200px;*/'>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-cancel</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/24.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Free cancellation within 24 hrs
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='isMobile() ? 48 : 64'>mdi-face-agent</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/support.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Exceptional Customer Service
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-currency-usd</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/savings.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Big Savings
              Hidden Fares
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-seal</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/offers.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Special Offers on Over 500 Airlines
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-row class='mx-16 my-4 text-caption' justify='space-around' style="max-width: 65.5rem; margin: 2.625rem auto !important;padding-top: 1rem;/* border-top: 1px solid rgb(67, 172, 249); */ ">
      <v-col cols='auto' md='auto' style="text-align:center">
        <v-img width='150px' src='@/assets/winner-2020.png'></v-img>
      </v-col>
      <v-col cols='auto' md='auto' style="text-align:center">
        <v-row justify='center' no-gutters class='primary--text text-subtitle-2 font-weight-bold mb-2'>
          <v-col cols='12' class='text-h6'>
            Awards
          </v-col>
        </v-row>
        <v-row justify='center' no-gutters>
          <v-col cols='12' class='text-body-1'>
            Bulgaria's Leading Travel Agency<br/>for 2017, 2018, 2019 and 2020
          </v-col>
        </v-row>
        <v-row justify='center' no-gutters class='mt-4'>
          <v-col cols='12' class='text-body-1'>
            Bulgaria's Leading Travel<br/> Management Company 2020
          </v-col>
        </v-row>
      </v-col>
      <v-col cols='auto' md='auto' style="text-align:center">
        <v-row justify='center' no-gutters class='primary--text text-subtitle-2 font-weight-bold mb-2'>
          <v-col cols='12' class='text-h6'>
            Also nominated for
          </v-col>
        </v-row>
        <v-row justify='center' no-gutters>
          <v-col cols='12' class='text-body-1'>
            Bulgaria's Leading<br/> Travel Agency 2021
          </v-col>
        </v-row>
        <v-row justify='center' no-gutters class='mt-4'>
          <v-col cols='12' class='text-body-1'>
            Bulgaria's Leading Travel<br/> Management Company 2021
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
  import SearchWidget from '../components/SearchWidget'

  export default {
    name: 'Home',

    data: () => ({
      banners: [
        {destination: 'Bulgaria', price: '467', searchterm: 'bulgaria', classname: 'bulgaria lpage'},
        {destination: 'Philippines', price: '31', searchterm: 'philippines', classname: 'philippines lpage'},
        {destination: 'Miami', price: '327', searchterm: 'miami', classname: 'miami lpage'},
        {destination: 'Brazil', price: '565', searchterm: 'brazil', classname: 'brazil lpage'},
        {destination: 'India', price: '405', searchterm: 'india', classname: 'india lpage'},
        {destination: 'Japan', price: '355', searchterm: 'japan', classname: 'japan lpage'},
        {destination: 'Iceland', price: '355', searchterm: 'iceland', classname: 'iceland lpage'},
        {destination: 'Italy', price: '355', searchterm: 'italy', classname: 'italy lpage'},
        {destination: 'China', price: '355', searchterm: 'china', classname: 'china lpage'},
        {destination: 'Australia', price: '355', searchterm: 'australia', classname: 'australia lpage'},
        {destination: 'Greece', price: '355', searchterm: 'greece', classname: 'greece lpage'},
        {destination: 'Hawaii', price: '355', searchterm: 'hawaii', classname: 'hawaii lpage'},
      ],

      signupEmail: ''
    }),

    computed: {
      isSignUpEmailValid() {
        if (!this.signupEmail.includes('@'))
          return false
        if (this.signupEmail.split('@')[0].length < 1 || this.signupEmail.split('@')[1].length < 3)
          return false
        if (!this.signupEmail.split('@')[1].includes('.'))
            return false
        
        return true
      }
    },

    created: function () {
      document.title = "Flights365 | Home";
    },

    mounted: function () {
      this.$store.state.expandSearch = true
    },

    components: {
      SearchWidget,
    },

    methods: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile
      },

      quickSearchTo(destination) {
        this.$store.state.toSearch = ''
        this.$store.state.toLocations = []
        this.$store.state.to = ''
        document.querySelector('.plane-bg').click() // HACK
        setTimeout(() => {
          document.activeElement.blur()
          document.querySelector('#locationInputTo').focus()
          window.scrollTo(0, 0)
          this.$store.state.toSearch = destination
          document.querySelector('#locationInputTo').click()
        }, 100)
      },

      signUp() {
        if(this.isSignUpEmailValid) {
          this.$http.post('https://flights365.com/others/signup/', {'email': this.signupEmail})
          alert('You have been signed up!')
        }
      },
    }
  }
</script>
<style>
.plane-bg {
  background-image: url('~@/assets/sunset.jpg');
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  padding: 20px 1rem;
}
@media only screen and (min-width: 1265px) {
  .plane-bg {
    background-position-y: center;
    padding: 140px 1rem;
  }
}

.bulgaria {
background-image: url('~@/assets/bulgaria.jpg');
}
.croatia {
background-image: url('~@/assets/croatia.jpg');
}
.florida {
background-image: url('~@/assets/florida.jpg');
}
.poland {
background-image: url('~@/assets/poland.jpg');
}
.israel {
background-image: url('~@/assets/israel.jpg');
}
.philippines {
background-image: url('~@/assets/philippines.jpg');
}
.miami {
background-image: url('~@/assets/miami.jpg');
}
.india {
background-image: url('~@/assets/india.jpg');
}
.japan {
background-image: url('~@/assets/japan.jpg');
}
.iceland {
background-image: url('~@/assets/iceland.jpg');
}
.brazil {
background-image: url('~@/assets/brazil.jpg');
}
.italy {
background-image: url('~@/assets/italy.jpg');
}
.china {
background-image: url('~@/assets/china.jpg');
}
.australia {
background-image: url('~@/assets/australia.jpg');
}
.greece {
background-image: url('~@/assets/greece.jpg');
}
.hawaii {
background-image: url('~@/assets/hawaii.jpg');
}

.lpage {
  text-align:left;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  padding:10px;
  color:white;
  cursor: pointer;
}

</style>
