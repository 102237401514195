<template>
  <v-container class='text-h5'>
    <div class='primary--text text-h6 my-6'>Frequently asked questions</div>
    <v-select :items='sections' v-model='activeSection'></v-select>
    <div class="text-body-2" v-if='activeSection == "Air reservation"'>
      <div class='text--primary subtitle-2 my-5'>a. If I booked wrong date and time outside of 24 hours period</div>
      <div class='mb-8 ml-6'>I. Most airlines allow us to make a change on the Dates and time of a reservation. For any changes to the reservation, kindly contact us +1-833-833-3088 and the agents will check on the airline penalties and rules for your specific ticket</div>
      <div class='text--primary subtitle-2 my-5'>b. If booked wrong Airport</div>
      <div class='mb-8 ml-6'>I. Most airlines allow us to make a change on the Dates and time of a reservation. For any changes to the reservation, kindly contact us +1-833-833-3088 and the agents will check on the airline penalties and rules for your specific ticket</div>
      <div class='text--primary subtitle-2 my-5'>c. If booked wrong Name</div>
      <div class='mb-8 ml-6'>I. Names can’t be changed for any reason. You can contact us +1-833-833-3088 and our agents will check with the airlines if any changes are possible.</div>
      <div class='text--primary subtitle-2 my-5'>d. How to Contact a travel consultant</div>
      <div class='mb-8 ml-6'>I. You can call on +1-833-833-3088 for assistance on your reservation. You may also email your questions/ comments/ concerns to support@flights365.com .</div>
      <div class='text--primary subtitle-2 my-5'>e. May I add additional passengers to existing booking</div>
      <div class='mb-8 ml-6'>I. If you need to add additional passengers to an existing booking you can call us 1-833-833-3088 or email: support@flights365.com for assistance.</div>
      <div class='text--primary subtitle-2 my-5'>f. Rules for reservation for Child travelling alone (UMNR – Unaccompanied Minor)</div>
      <div class='mb-8 ml-6'>I. A child below the age of 18 alone is considered an Unaccompanied Minor by the airlines. Every airline has their own rules and regulations regarding UMNR. For reservations of UMNR please contact us 1-833-833-3088</div>
      <div class='text--primary subtitle-2 my-5'>g. How may I travelling with Infant</div>
      <div class='mb-8 ml-6'>I. An infant is considered a child 0-24 months. You may travel with infant in lap at highly discounted price, usually 10% from adult fare. If you prefer to add more comfort most airlines suggest you purchase a seat for the infant. Please visit airlines website for specific rules and regulations. On long-haul flights we may secure for you bassinet. </div>
      <div class='text--primary subtitle-2 my-5'>h. How to contact Airlines</div>
      <div class='mb-8 ml-6'>I. Please visit our Airlines page which will provide you with toll-free numbers. You also may visit directly the airlines website for more information</div>
      <div class='text--primary subtitle-2 my-5'>i. I’m not able to book selected fare or flight</div>
      <div class='mb-8 ml-6'>I. The airlines inventory changes frequently, and there is a possibility that you select a flight option and until the time you confirm the booking there is revision in the fares and seats gone away you may face difficulty in completing your reservation</div>
      <div class='text--primary subtitle-2 my-5'>j. How to cancel my reservation</div>
      <div class='mb-8 ml-6'>I. We offer special fares which are mostly non-refundable. You can contact us 1-833-833-3088 and agents will check penalties and rules. Once you decide to cancel the reservation it’s subject to the fare change/cancellation rules.</div>
      <div class='text--primary subtitle-2 my-5'>k. How to select a seat</div>
      <div class='mb-8 ml-6'>I. Seat numbers will be automatically assigned after your booking has been ticketed on all flights. To change or request specific seat please call us 1-833-833-3088</div>
      <div class='text--primary subtitle-2 my-5'>l. May I use airline credits/vouchers</div>
      <div class='mb-8 ml-6'>I. We’re unable to accept vouchers/credits as partial or full payments of airline ticket on our website. You can contact the issuing airline directly in order to use your credit/voucher. </div>
      <div class='text--primary subtitle-2 my-5'>m. Can I hold booking?</div>
      <div class='mb-8 ml-6'>I. We can hold booking only for phone booking. Airline regulations prohibit us from holding reservations via our website.</div>
      <div class='text--primary subtitle-2 my-5'>n. Child/Discounts</div>
      <div class='mb-8 ml-6'>I. For US domestic travel airlines do not offer any discounts. For international travel, child and infant fare very depending on the airline.</div>
    </div>
    <div v-if='activeSection == "Payment process"'>
        <div class='text--primary subtitle-2 my-5'>a. Payment method</div>
        <div class='mb-8 ml-6'>I. The form of payment accepted to purchase on our website is a valid US or Canadian credit or debit card. Debit cards may be used but are generally subject to low daily limits that may cause the payment process to be rejected, i.e. Credit card declined. We accept the following types of major credit cards: American Express, Visa, Master Card, Discover, and Diners Club. Only one card may be used to purchase the ticket(s) on a given reservation. To complete the purchase process, you will need to enter information about the credit card and have this information validated before a ticket or a final confirmation is issued. If the entered credit card information is not valid then an error will be generated, and an e-mail sent to you (at the e-mail address specified by you) indicating that the credit card has been declined. In order to keep the reservation, you must call +1-833-833-3088 and provide the agent with valid credit card information within 24 hours of making the reservation, or the reservation may be terminated. Most credit card declines are the result of inaccurate information being entered into the website payment page such as transposing credit card digits, having the wrong security code or expiration date, or the name on the card and address are inconsistent</div>
        <div class='text--primary subtitle-2 my-5'>b. Debit cards</div>
        <div class='mb-8 ml-6'>I. While credit cards are suggested we do accept Visa and Mastercard debit cards. Debit cards normally have a lower daily limit which can cause complications when purchasing a ticket online. It’s recommended to contact your bank prior purchasing to ensure daily limit will cover the cost of your booking</div>
        <div class='text--primary subtitle-2 my-5'>c. All prices in US dollars</div>
        <div class='mb-8 ml-6'>I. All of our fares are listed in USD. We do accept Canadian credit/debit card and the final charges on your statement will be in CAD.</div>
        <div class='text--primary subtitle-2 my-5'>d. Can I pay with Canadian credit/debit cards</div>
        <div class='mb-8 ml-6'>I. We do accept Canadian debit/credit cards. </div>
        <div class='text--primary subtitle-2 my-5'>e. Credit card declined</div>
        <div class='mb-8 ml-6'>I. There are many reasons a card can be declined: Insufficient funds, low daily spending limit, billing address verification failed or internet fraud screening by our bank. You will be notified if your card is declined and you’ll have to contact the credit card company to clear the issue. Once problem is resolved you can contact us to have the booking confirmed</div>
        <div class='text--primary subtitle-2 my-5'>f. Do you charge service fee for purchasing the ticket through our website</div>
        <div class='mb-8 ml-6'>I. Yes, all service fees and taxes are included into your total when searching flight on our website. </div>
        <div class='text--primary subtitle-2 my-5'>g. Different charges on your credit card</div>
        <div class='mb-8 ml-6'>I. You may see different charges on your credit/debit card for accounting reasons. However, they will not exceed the final amount you agreed to pay online</div>
        <div class='text--primary subtitle-2 my-5'>h. Review/confirm your reservation</div>
        <div class='mb-8 ml-6'>I. After you complete booking process you can review your reservation on the confirmation page and you will receive separate email with your booking. Once we confirm your tickets with the airline you’ll receive separate email with your ticket numbers. If you don’t receive your final confirmation it may be because your email provider has blocked it as possible spam.</div>
        <div class='text--primary subtitle-2 my-5'>i. Need copy of your itinerary</div>
        <div class='mb-8 ml-6'>I. If you need copy of your itinerary, please call us 1-833-833-3088 or email: support@flights365.com</div>
    </div>
    <div v-if='activeSection == "Travelling process"'>
      <div class='text--primary subtitle-2 my-5'>a. Travel with e-ticket</div>
      <div class='mb-8 ml-6'>I. E-tickets make travel convenient as it eliminates the worry of loss or theft of your paper tickets. You’ll be required to carry government issue ID for check-in. Some airlines allows check-in online and print your boarding passes 24-36 hours in advance.</div>
      <div class='mb-8 ml-6'>II. Change airports - Itineraries will sometimes involve an airport transfer when airlines don’t fly to your destination from one airport to another such as EWR and JFK in New York City. The switch of airports is carried out at the travelers own expense and will require the traveler to collect their luggage and transfer to the next airport where he/she will then check-in with the next scheduled airline. </div>
    </div>
  </v-container>
</template>

<script>

  export default {
    name: 'Faq',

    metaInfo() {
      return {
        meta: [
          { name: 'description', content: `Frequently asked questions regarding online ticket reservation` }
        ]
      }
    },


    components: {
    },

    created: function () {
      document.title = "Flights365 | FAQ";
    },

    data: () => ({
      sections: ['Air reservation', 'Payment process', 'Travelling process'],
      activeSection: 'Air reservation'
    })
  }
</script>
