<template>
  <v-container class='text-subtitle-1'>
    <div class='primary--text text-h6 my-6'>Flights365 Privacy Policy for Information Collection & Use</div>
    <div class='mb-8'>Enrollment In order to use certain areas of this Website, you must first complete the enrollment form and we will generate the user name and password for you, if you meet the criteria. During registration you are required to give contact information such as name and email address and other business details. We use this information to contact you about the services on our site in which you have expressed interest and to maintain the details with us as your identity.</div>
    <div class='mb-8'>You have the option to provide additional information to us; we encourage you to submit this information so we can provide you a more personalized experience on our site.</div>
    <div class='mb-8'>Children’s Privacy No part of Flights365 is directed towards persons below the age of 18. If you are under the age of 18 but at least 13 years of age, you may use Flights365 only with the involvement of a parent or guardian who agrees to be bound by the Terms and Conditions.</div>
    <div class='mb-8'>Flights365 Advertisers and 3rd Party Websites Flights365 website and email may contain links to various other sites. While Flights365 every effort to ensure that our advertisers post clear and complete Privacy Policies and observe appropriate data practices, each of these sites has a privacy policy that may differ from that of Flights365. The privacy practices of other websites and companies are not covered by this policy.</div>
    <div class='mb-8'>Except as specifically set forth below, it is our policy not to provide your personally identifiable information to our advertisers unless you make the choice to share it with them. We occasionally pass personally identifiable information via a secure interface to certain of our advertisers for the purpose of making it easier for you to sign up for their offers, however, this information is only to be used by the advertiser if you agree to submit the information to the advertiser for the purposes of completing an offer</div>
    <div class='mb-8'>We occasionally hire other companies to provide limited services on our behalf, such as handling the processing and delivery of mailings, providing customer support, processing transactions, or performing statistical analysis of our services. We will only provide those companies the personal information they need to deliver the service. They are required to maintain the confidentiality of your information and are prohibited from using that information for any other purpose.</div>
    <div class='mb-8'>We may share non-personally identifiable information collected in aggregate, anonymous form with advertisers or other third parties so that they may better evaluate what products and services are most appealing to different segments of our member base. We do not disclose your first name, last name, address, email address, or any other personally identifiable information to these third parties unless you give your express consent.</div>
    <div class='mb-8'>We occasionally send the information we gather about our Members to trusted third-party data processors to organize, process and /or analyze the data on our behalf. We use this information to enhance our program offerings; namely to tailor advertisements, updates, and offers to your particular interests and to ensure that your opt-out requests are honored. All such third-party data processors are prohibited from using your personal information except to provide these services to Flights365. They are contractually bound to observe the strict policy stated herein, so you can rest assured that the confidentiality of your personally identifiable information will be maintained.</div>
    <div class='primary--text text-h6 my-6'>Information Sharing and Disclosure</div>
    <div class='mb-8'>Our Use of Your Information We use customer contact information from the Enrollment form to send you information about Flights365 and promotional material from our partners. Your contact information is also used to contact you when necessary.</div>
    <div class='mb-8'>We use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information. Finally, we may use your IP address to help protect ourselves and our partners from fraud.</div>
    <div class='mb-8'>We use demographic and profile data to tailor your experience at our site, showing you content that we think you might be interested in, and displaying the content according to your preferences.</div>
    <div class='mb-8'>Our Disclosure of Your Information We never use or share the personally identifiable information provided to us online in ways unrelated to the ones described in this Privacy Policy without also providing you an opportunity to opt-out or otherwise prohibit such unrelated uses.</div>
    <div class='mb-8'>Flights365 will never sell or rent for consideration any personally identifiable information about you or your business to any third party without first receiving your permission.</div>
    <div class='mb-8'>Flights365 cannot ensure that all of your personally identifiable information will never be disclosed in ways not described in this Privacy Policy. For example, we may be required to disclose personally identifiable information to the government or by court order under certain circumstances, or other situations may arise requiring such disclosure. In addition, you authorize us to disclose any information about you to law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate.</div>
    <div class='mb-8'>This site contains links to other sites. Flights365 is not responsible for the privacy practices or the content of such Websites. Contact You can submit any comment, request, question that you may have regarding our privacy policy by contacting us at support@flights365.com.</div>
    <div class='primary--text text-h6 my-6'>Bank and credit card fees</div>
    <div class='mb-8'>We value your business and respect the privacy of your information, Flights365 as an entity do not request any customer to make any payments after the entire process of E-ticket is completed. We suggest if you receive any call or email from anyone claiming to be from Flights365 or from Airline directly and requesting for Gift cards or payment from any other means please inform us and validate only if you receive emails from Flights365.com. For any issues related to charges please write it to support@flights365.com or call at 1-833-833-3088.</div>

  </v-container>
</template>

<script>

  export default {
    name: 'PrivacyPolicy',

    created: function () {
      document.title = "Flights365 | Privacy Policy";
    },

    components: {
    },
  }
</script>
