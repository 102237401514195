<template>
  <v-container>
    <v-snackbar
      v-model="showAlert"
      :timeout="4000"
    >
      {{ alertMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showAlert = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      :value="this.flight != null"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      ref='bookingDialog'
    >
      <v-card v-if='this.flight != null'>
        <v-container v-if='step == 0'>
          <v-form ref='bookingForm' v-model='formValid'>
            <v-card class='mb-10'>
              <v-card-title style='background-color: rgba(67, 172, 249, 0.1);'>
                <v-btn icon @click="emitClose">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class='font-weight-bold'>Flight details</v-toolbar-title>
                <v-spacer></v-spacer>
                <div>
                  <v-row no-gutters>
                    <v-col cols='12' class='font-weight-bold text-h5 text-right'>
                      {{getCurrencySymbol()}}{{getAvgFlightCost(flight)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters class='hidden-sm-and-down'>
                    <v-col cols='12' class='text-caption text--secondary'>
                      Price per person (incl. taxes & fees)
                    </v-col>
                  </v-row>
                </div>
                <div class='text-caption text--secondary hidden-md-and-up ml-5'>Price per person (incl. taxes & fees)</div>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div class='mb-3 text--primary'>
                  <div class='font-weight-bold text-subtitle-1 text--primary'>
                    Baggage allowance
                  </div>
                  <div>
                    <v-row align='center'>
                      <v-col cols='2' class='text-right'>
                        <v-icon large color='primary'>mdi-bag-checked</v-icon>
                      </v-col>
                      <v-col cols='4'>
                        <v-row no-gutters>
                          <v-col cols='12' class='text-subtitle-2'>
                            Hand Carry
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col cols='12' class='text-body-2'>
                            <span>1PC upto 7KG</span>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols='2' class='text-right'>
                        <v-icon large color='primary'>{{getCheckedBagAllowance(flight) > 0 ? 'mdi-bag-suitcase' : 'mdi-bag-suitcase-off'}}</v-icon>
                      </v-col>
                      <v-col cols='4' class='text-caption text-md-subtitle-2'>
                        {{getCheckedBagAllowance(flight) > 0 ? (getCheckedBagAllowance(flight)+'PC Checked Bag') : 'No Checked Bag'}}
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-divider class='mt-5' :inset='!isMobile()'></v-divider>
                <div :key='id' v-for='(citypair, id) in flight.Citypairs'>
                  <div class='font-weight-bold text-subtitle-1 text--primary'>
                    <span v-if='$store.state.flightType != "multi"'>
                      {{id == 0 ? 'Departure' : 'Return'}}
                    </span>
                    <span v-if='$store.state.flightType == "multi"'>
                      Flight {{id+1}}
                    </span>
                  </div>
                  <div :key='sid' v-for='(flightsegment, sid) in citypair.FlightSegment'>
                    <v-row class='my-4' justify='center' no-gutters v-if='flightsegment.LayoverTime != ""'>
                      <v-col cols='8' class='rounded pa-1 text-center' style='background-color: rgba(67, 172, 249, 0.1);'>
                        {{flightsegment.OriginAirportName}} ({{flightsegment.DepartureLocationCode}}) {{flightsegment.LayoverTime}}
                      </v-col>
                    </v-row>
                    <v-row class='my-4 text--primary' no-gutters justify='center' align='center'>
                      <v-col cols='4' md='2'>
                        <v-img contain max-height='35' class='ml-auto mr-auto' :src='getCarrierLogoUrlBySegment(flightsegment, flight)'></v-img>
                      </v-col>
                      <v-col cols='2' class='hidden-sm-and-down'>
                        <v-row dense>
                          <v-col class='text-body-2' cols='12'>
                            {{flight.FareType == 'JCB' ? flight.ValidatingCarrierName : flightsegment.MarketingAirlineName}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col class='text-subtitle-1' cols='12'>
                            Flight ({{flightsegment.FlightNumber}})
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols='3' class='text-center'>
                        <v-row class='text-md-subtitle-1 text-caption' align='center' no-gutters>
                          <v-col md='4' cols='6'>
                            {{formatTime(flightsegment.DepartureDateTime)}}
                          </v-col>
                          <v-col md='8' cols='6'>
                            <v-row no-gutters>
                              <v-col class='text-md-subtitle-1 text-caption' cols='12'>
                                <span class='hidden-sm-and-down'>{{flightsegment.OriginAirportName}} (</span>{{flightsegment.DepartureLocationCode}}<span class='hidden-sm-and-down'>)</span>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col class='text-md-body-2 text-caption' cols='12'>
                                {{formatDate(flightsegment.DepartureDateTime)}}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols='3' class='text-center'>
                        <v-row class='text-md-subtitle-1 text-caption' align='center' no-gutters>
                          <v-col md='4' cols='6'>
                            {{formatTime(flightsegment.ArrivalDateTime)}}
                          </v-col>
                          <v-col md='8' cols='6'>
                            <v-row no-gutters>
                              <v-col class='text-md-subtitle-1 text-caption' cols='12'>
                                <span class='hidden-sm-and-down'>{{flightsegment.DestinationAirportName}} (</span>{{flightsegment.ArrivalLocationCode}}<span class='hidden-sm-and-down'>)</span>
                              </v-col>
                            </v-row>
                            <v-row no-gutters>
                              <v-col class='text-md-body-2 text-caption' cols='12'>
                                {{formatDate(flightsegment.ArrivalDateTime)}}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols='2' class='primary--text text-center text-md-subtitle-1 text-caption'>
                        <v-icon class='mr-1 hidden-sm-and-down' color='primary'>mdi-clock</v-icon>{{flightsegment.Duration}}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider :inset='!isMobile()'></v-divider>
                </div>
              </v-card-text>
            </v-card>
            <v-card class='mb-10'>
              <v-card-title class='font-weight-bold' style='background-color: rgba(67, 172, 249, 0.1);'>
                Traveller Details
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <div :key='id' v-for='(passanger, id) in passangerData'>
                  <v-row :dense='isMobile()'>
                    <v-col cols='2' md='1'>
                      <v-text-field :dense='isMobile()' v-model='passanger.type' readonly></v-text-field>
                    </v-col>
                    <v-col cols='5' md='3'>
                      <v-text-field :dense='isMobile()' name='firstname' v-model='passanger.name' label='First Name*' :rules='[ruleRequired]'></v-text-field>
                    </v-col>
                    <v-col cols='5' md='3'>
                      <v-text-field :dense='isMobile()' name='lastname' v-model='passanger.family' label='Last Name*' :rules='[ruleRequired]'></v-text-field>
                    </v-col>
                    <v-col cols='3' md='2'>
                      <v-select :dense='isMobile()' :items='monthNames' v-model='passanger.month' label='Month*' :rules='[ruleRequired]'></v-select>
                    </v-col>
                    <v-col cols='3' md='1'>
                      <v-select :dense='isMobile()' :items='days' v-model='passanger.day' label='Day*' :rules='[ruleRequired]'></v-select>
                    </v-col>
                    <v-col cols='3' md='1'>
                      <v-select :dense='isMobile()' :items='years' v-model='passanger.year' label='Year*' :rules='[ruleRequired]'></v-select>
                    </v-col>
                    <v-col cols='3' md='1'>
                      <v-select :dense='isMobile()' :items="genders" v-model='passanger.gender' block label='Gender*' :rules='[ruleRequired]'></v-select>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols='6' md='4'>
                      <v-checkbox @change='getInsuranceQuote()' :disabled='passanger.type == "INF"' v-if='getBaggagePrice(flight, 0)' block :dense='isMobile()' v-model='passanger.paidBaggageDeparture' color='primary' hide-details>
                        <template v-slot:label><span class='font-weight-regular text-body-2' :class='{"primary--text": passanger.paidBaggageDeparture}'>Add 1PC checked bag for departure for <span class='font-weight-bold'>{{getBaggagePrice(flight, 0)}}$ </span></span></template>
                      </v-checkbox>
                    </v-col>
                    <v-col v-if='$store.state.flightType == "round"' cols='6' md='4'>
                      <v-checkbox @change='getInsuranceQuote()' :disabled='passanger.type == "INF"' v-if='getBaggagePrice(flight, 1)' block :dense='isMobile()' v-model='passanger.paidBaggageReturn' color='primary' hide-details>
                        <template v-slot:label><span class='font-weight-regular text-body-2' :class='{"primary--text": passanger.paidBaggageReturn}'>Add 1PC checked bag for return for <span class='font-weight-bold'>{{getBaggagePrice(flight, 1)}}$ </span></span></template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-divider class='mb-5'></v-divider>
                </div>
              </v-card-text>
            </v-card>
            <v-card class='mb-10'>
              <v-card-title class='' style='background-color: rgba(67, 172, 249, 0.1);'>
                Price Details
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row :dense='isMobile()' class='text-md-subtitle-1 text-caption text--primary'>
                  <v-col cols='3'>
                    Travelers
                  </v-col>
                  <v-col cols='2'>
                    Count
                  </v-col>
                  <v-col cols='2'>
                    Base Fare
                  </v-col>
                  <v-col cols='3'>
                    Taxes & Fees
                  </v-col>
                  <v-col cols='2'>
                    Subtotal
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-row :dense='isMobile()' class='text-md-body-1 text-caption' v-for='(fare, id) in flight.Fares' :key='id'>
                  <v-col cols='3'>
                    {{fare.PaxType}}
                  </v-col>
                  <v-col cols='2'>
                    {{getPassangerCountByType(fare.PaxType)}}
                  </v-col>
                  <v-col cols='2'>
                    {{getCurrencySymbol()}}{{formatPrice(fare.BaseFare)}}
                  </v-col>
                  <v-col cols='3'>
                    {{getCurrencySymbol()}}{{formatPrice(fare.Taxes)}}
                  </v-col>
                  <v-col cols='2'>
                    {{getCurrencySymbol()}}{{formatPrice((fare.BaseFare+fare.Taxes)*getPassangerCountByType(fare.PaxType))}}
                  </v-col>
                </v-row>
                <v-row v-if='getTotalBaggagePrice(flight)' :dense='isMobile()' class='text-md-body-1 text-caption'>
                  <v-col cols='3'>
                  </v-col>
                  <v-col cols='2'>
                  </v-col>
                  <v-col cols='2'>
                  </v-col>
                  <v-col cols='3'>
                    Extra Baggage
                  </v-col>
                  <v-col cols='2'>
                    {{getCurrencySymbol()}}{{formatPrice(getTotalBaggagePrice(flight))}}
                  </v-col>
                </v-row>
                <v-row :dense='isMobile()' class='text-md-body-1 text-caption font-weight-bold'>
                  <v-col cols='3'>
                  </v-col>
                  <v-col cols='2'>
                  </v-col>
                  <v-col cols='2'>
                  </v-col>
                  <v-col cols='3'>
                    Total
                  </v-col>
                  <v-col cols='2'>
                    {{getCurrencySymbol()}}{{formatPrice(sumFares(flight)+getTotalBaggagePrice(flight))}}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class='mb-10'>
              <v-card-title class='font-weight-bold' style='background-color: rgba(67, 172, 249, 0.1);'>
                Payment Details
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row :dense='isMobile()'>
                  <v-col cols='12' md='12'>
                    <v-checkbox v-model='isHold' color='#001871' label='Hold Reservation (24 hr)' hide-details></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if='!isHold' :dense='isMobile()'>
                  <v-col cols='12' md='3'>
                    <v-text-field name='cardholdername' v-model='bookingCardHolderName' label='Card Holder Name*' :rules='[ruleRequired, ruleMin4]'></v-text-field>
                  </v-col>
                  <v-col cols='12' md='3'>
                    <v-text-field :value='bookingCardNumber' @input='formatCardNumber' placeholder='XXXX-XXXX-XXXX-XXXX' label='Card Number*' counter maxlength="19" :persistent-placeholder='false' :rules='[ruleRequired, ruleCardCode]'></v-text-field>
                  </v-col>
                  <v-col cols='4' md='2'>
                    <v-select :items='expiryMonths' v-model='bookingExpiryDate[0]' label='Exp. Month*' :rules='[ruleRequired]'></v-select>
                  </v-col>
                  <v-col cols='4' md='2'>
                    <v-select :items='expiryYears' v-model='bookingExpiryDate[1]' label='Exp. Year*' :rules='[ruleRequired]'></v-select>
                  </v-col>
                  <v-col cols='4' md='2'>
                    <v-text-field v-model='bookingCvv' label='CVV*' counter maxlength="4" :rules='[ruleRequired, ruleCVV]'></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class='mb-10'>
              <v-card-title class='font-weight-bold' style='background-color: rgba(67, 172, 249, 0.1);'>
                Billing and Contact Information
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <!-- <v-row :dense='isMobile()'>
                  <v-col cols='12'>
                    <v-text-field label='Name*' :rules='[ruleRequired, ruleMin4]'></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row v-if='!isHold' :dense='isMobile()'>
                  <v-col cols='12' md='12'>
                    <v-text-field name='address' v-model='bookingAddress' label='Address*' :rules='[ruleRequired, ruleMin4]'></v-text-field>
                  </v-col>
                  <!-- <v-col cols='12' md='6'>
                    <v-text-field v-model='bookingAddress2' label='Address 2*' :rules='[ruleMin4]'></v-text-field>
                  </v-col> -->
                </v-row>
                <v-row v-if='!isHold' :dense='isMobile()'>
                  <v-col cols='6' md='3'>
                    <v-autocomplete :items='countryNames' v-model='bookingCountry' label='Country*' :rules='[ruleRequired]' @change='bookingState = "NA"'></v-autocomplete>
                  </v-col>
                  <v-col cols='6' md='3'>
                    <v-autocomplete :disabled='bookingCountry != "US" && bookingCountry != "CA"' :items='bookingCountry == "US" ? usStateNames : caStateNames' item-text='text' v-model='bookingState' label='State*' :error-messages='bookingCountry != "US" && bookingCountry != "CA" ? "" : (bookingState == "NA" ? "This is required" : "")'></v-autocomplete>
                  </v-col>
                  <v-col cols='6' md='3'>
                    <v-text-field name='city' v-model='bookingCity' label='City*' :rules='[ruleRequired]'></v-text-field>
                  </v-col>
                  <v-col cols='6' md='3'>
                    <v-text-field name='zipcode' v-model='bookingZip' label='ZIP Code*' :rules='[ruleRequired]'></v-text-field>
                  </v-col>
                </v-row>
                <v-row :dense='isMobile()'>
                  <v-col cols='12' md='12'>
                    <v-text-field name='phonenumber' v-model='bookingPhoneNumber' label='Phone Number*' prefix='+' :rules='[ruleRequired]'></v-text-field>
                  </v-col>
                  <!-- <v-col cols='12' md='6'>
                    <v-text-field v-model='bookingAltPhoneNumber' label='Alternate Phone Number*' prefix='+'></v-text-field>
                  </v-col> -->
                </v-row>
                <v-row :dense='isMobile()'>
                  <v-col cols='12' md='6'>
                    <v-text-field name='email' v-model='bookingEmail' label='Email*' :rules='[ruleRequired, ruleEmail]'></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-text-field v-model='bookingConfirmEmail' label='Confirm Email*' :rules='[ruleRequired]' :error-messages='bookingEmail == bookingConfirmEmail ? "" : "Emails do not match"'></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class='mb-10' v-if='!isHold'>
              <v-card-title class='font-weight-bold' style='background-color: #001871; color: white;'>
                Travel Guard&reg; Travel Insurance
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row :dense='isMobile()'>
                  <v-col cols='12' md='12'>
                    <div style='border: 1px solid #001871; color: #001871; border-radius: 5px;' class='pa-6'>
                      <div class='font-weight-bold text-subtitle-1'>Coverage includes</div>
                      <v-row class='font-weight-bold mt-4' style='color: #1352DE;'>
                        <v-col cols='6'>
                          Trip Cancellation <v-icon color='#00BF6F' class='ml-2'>mdi-check</v-icon>
                        </v-col>
                        <v-col cols='6'>
                          Trip Delay <v-icon color='#00BF6F' class='ml-2'>mdi-check</v-icon>
                        </v-col>
                      </v-row>
                      <v-row class='font-weight-bold' style='color: #1352DE;'>
                        <v-col cols='6'>
                          Trip Interruption <v-icon color='#00BF6F' class='ml-2'>mdi-check</v-icon>
                        </v-col>
                        <v-col cols='6'>
                          Baggage Delay <v-icon color='#00BF6F' class='ml-2'>mdi-check</v-icon>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols='12'>
                    <v-checkbox
                      color='#001871'
                      :disabled='bookingCountry != "US" || bookingState == "MT" || insuranceQuote == null'
                      v-model="insuranceEnabled"
                      hint="I have read and understand the Description of Coverage, Important Disclosures, and agree to the terms and conditions of the insurance coverage provided..."
                      persistent-hint
                    >
                      <template v-slot:label>
                        <span v-if='bookingCountry == "US" && bookingState != "MT" && insuranceQuote != null' style='color: #001871;' class='font-weight-bold'>Yes, include travel insurance for ${{insuranceQuote}}</span>
                        <span v-if='bookingCountry != "US" || bookingState == "MT" || insuranceQuote == null' style='color: #001871;' class='font-weight-bold'>Insurance is not available for your country.</span>
                        <span class='text--secondary ml-2 text-caption'>(Available to U.S. residents only)</span>
                      </template>

                      <template v-slot:message>
                        <span class='text-caption text--secondary'>I have read and understand the <a target="_blank" style='color: #1352DE;' :href='international ? "https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=US&StateCode=AL&ProductCode=109438&PlanCode=P1&FileType=PROD_PLAN_GM" : "https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=US&StateCode=AL&ProductCode=109437&PlanCode=P1&FileType=PROD_PLAN_GM"'>Policy of Insurance</a> and <a target="_blank" style='color: #1352DE;' href='https://www.travelguard.com/legal/disclaimer'>Important Disclosures</a> and agree to the terms and conditions of the insurance coverage provided.</span>
                      </template>

                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols='12' class='font-weight-bold text-caption text--primary'>
                    Coverage is offered by Travel Guard Group, Inc., and limitations will apply: read <a target="_blank" style='color: #1352DE;' href='https://www.travelguard.com/legal/disclaimer'>Important Disclosures</a> 
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class='mb-10'>
              <v-card-title class='font-weight-bold' style='background-color: rgba(67, 172, 249, 0.1);'>
                Policies & Review
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row justify='center' class='text--primary text-center'>
                  <v-col cols='12'>
                    Please confirm that the dates and times of flights and names of travelers are accurate. Tickets are non-transferable and name changes are not permitted.
                  </v-col>
                </v-row>
                <v-row justify='center' class='text--primary text-center hidden-sm-and-down'>
                  <v-col cols='12'>
                    **Passenger names must match with the government-issued photo ID/Passport exactly Traveler 1 : <v-btn text class='primary--text' small @click='goToTravellers'>Make Changes</v-btn>
                  </v-col>
                </v-row>
                <v-row justify='center' class='text--primary text-center hidden-sm-and-down'>
                  <v-col cols='12'>
                    Total price shown includes all applicable taxes and our fees. These tickets are non-refundable and some airlines may charge additional baggage fees or other fees. Discounted tickets do not allow airline frequent flyer mileage accrual. PLEASE NOTE: Fares are not guaranteed until ticketed.
                  </v-col>
                </v-row>
                <v-divider class='mb-5 mt-5'></v-divider>
                <v-row justify='center' class='text-center'>
                  <v-col cols='12' md='8'>
                    By clicking 'Confirm & Book', I agree that I have read and accepted the Flights356's <router-link to='/terms-and-conditions' target="_blank">Terms and Conditions</router-link> and <router-link to='/privacy-policy' target="_blank">Privacy Policy</router-link>
                  </v-col>
                </v-row>
                <v-row justify='center'>
                  <v-col cols='12' lg='auto' md='auto'>
                    <v-btn id='confirm-and-book' x-large block color='primary' :disabled='!repriced' :loading='booking' @click='bookFlight()'>{{!repriced ? 'Verifying price & availability' : (isHold ? 'Confirm & Hold' : 'Confirm & Book')}}</v-btn>
                  </v-col>
                </v-row>
                <v-row dense justify='center' class='text-center'>
                  <v-col cols='12' md='8'>
                    You will be charged: {{getCurrency()}} {{getCurrencySymbol()}}{{formatPrice(sumFares(flight)+getTotalBaggagePrice(flight))}} <span v-if='insuranceQuote != null && insuranceEnabled == true'>+ USD ${{insuranceQuote}} for insurance</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-container>
        <v-container class='confirmation-dialog' v-if='step == 1 && pnrData != null'>
          <v-btn small outlined @click="emitClose">
            <v-icon>mdi-arrow-left</v-icon> Back to flights
          </v-btn>
          <div class='text-h6 my-4 font-weight-bold text-center'>Thank you! Your booking has been confirmed!</div>
          <div class='d-flex flex-wrap justify-space-around'>
            <div class='border pa-2 my-2 rounded elevation-3 binfo-box'>
              GDS PNR: <span class='primary--text font-weight-bold'>{{pnr}}</span>
            </div>
            <div class='border pa-2 my-2 rounded elevation-3 binfo-box'>
              Insurance Policy: <span class='primary--text font-weight-bold'>{{policyNumber}}</span>
            </div>
            <div class='border pa-2 my-2 rounded elevation-3 binfo-box'>
              Booking Date:  <span class='primary--text font-weight-bold'>{{dateOfBooking}}</span>
            </div>
          </div>
          <div class='mb-3'>
            <v-row dense class='text-center text-md-h6 text-subtitle-2 mb-2'>
              <v-col cols='12'>
                Flight Details
              </v-col>
            </v-row>
            <div :key='id' v-for='(citypair, id) in this.splitFlightSegmentsToCitypairs(this.joinCitypairs(pnrData.FlightItinerary[0].Citypairs).FlightSegment, flight.Citypairs)'>
              <div class='font-weight-bold text-md-h6 text-subtitle-2 text--primary'>
                {{id == 0 ? 'Departure' : 'Return'}}
              </div>
              <div :key='sid' v-for='(flightsegment, sid) in citypair.FlightSegment'>
                <v-row class='my-4' justify='center' no-gutters v-if='sid != 0'>
                  <v-col cols='12' style='border: 1px solid #43acf9;' class='rounded pa-1 text-center primary--text'>
                    {{flight.Citypairs[id].FlightSegment[sid].OriginAirportName}} ({{flightsegment.DepartureLocationCode[0]}}) {{flight.Citypairs[id].FlightSegment[sid].LayoverTime}}
                  </v-col>
                </v-row>
                <v-row class='my-4 text--primary' no-gutters justify='center' align='center'>
                  <v-col cols='4' md='2'>
                    <v-img contain max-height='35' class='ml-auto mr-auto' :src='getCarrierLogoUrl(flightsegment.MarketingAirline)'></v-img>
                  </v-col>
                  <v-col cols='2' class='hidden-sm-and-down'>
                    <v-row dense>
                      <v-col class='text-body-2' cols='12'>
                        {{flight.FareType == 'JCB' ? flight.ValidatingCarrierName : flightsegment.MarketingAirlineName}}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class='text-subtitle-1' cols='12'>
                        Flight ({{flightsegment.FlightNumber[0]}})
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols='4' class='text-center'>
                    <v-row align='center' dense>
                      <v-col md='4' cols='6'>
                        {{formatTime(flightsegment.DepartureDateTime)}}
                      </v-col>
                      <v-col md='8' cols='6'>
                        <v-row dense>
                          <v-col class='text-md-subtitle-1 text-caption' cols='12'>
                            {{flightsegment.DepartureLocationCode[0]}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col class='text-md-body-2 text-caption' cols='12'>
                            {{formatDate(flightsegment.DepartureDateTime)}}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols='4' class='text-center'>
                    <v-row align='center' dense>
                      <v-col md='4' cols='6'>
                        {{formatTime(flightsegment.ArrivalDateTime)}}
                      </v-col>
                      <v-col md='8' cols='6'>
                        <v-row dense>
                          <v-col class='text-md-subtitle-1 text-caption' cols='12'>
                            {{flightsegment.ArrivalLocationCode[0]}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col class='text-md-body-2 text-caption' cols='12'>
                            {{formatDate(flightsegment.ArrivalDateTime)}}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col cols='2' class='primary--text text-center text-md-subtitle-1 text-md-caption'>
                    <v-icon class='mr-1 hidden-sm-and-down' color='primary'>mdi-clock</v-icon>{{flightsegment.Duration}}
                  </v-col> -->
                </v-row>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <div>
            <v-row dense class='text-md-h6 text-subtitle-2'>
              <v-col cols='12'>
                Traveller Details
              </v-col>
            </v-row>
            <v-row dense class='primary white--text text-caption text-md-body-1 font-weight-bold'>
              <v-col cols='5'>Name</v-col>
              <v-col cols='2'>Type</v-col>
              <v-col cols='3'>DOB</v-col>
              <v-col cols='2'>Gender</v-col>
            </v-row>
            <v-row dense :key='id' v-for='(passanger, id) in pnrData.PaxDetail' class='text-caption text-md-body-2 border-bottom border-right'>
              <v-col class='border-left' cols='5'>{{passanger.FirstName+' '+passanger.LastName}}</v-col>
              <v-col class='border-left' cols='2'>{{passanger.PaxType[0]}}</v-col>
              <v-col class='border-left' cols='3'>{{passanger.DateOfBirth[0]}}</v-col>
              <v-col class='border-left' cols='2'>{{passanger.Gender[0]}}</v-col>
            </v-row>
          </div>
          <div class='mt-6'>
            <v-row dense style='' class='text-md-h6 text-subtitle-2'>
              <v-col cols='12'>
                Price Details
              </v-col>
            </v-row>
            <v-row dense class='primary white--text text-caption text-md-body-1 font-weight-bold'>
              <v-col cols='3'>Passenger</v-col>
              <v-col cols='1'>No</v-col>
              <v-col cols='2'>Base</v-col>
              <v-col cols='3'>Taxes & Fees</v-col>
              <v-col cols='3'>Total</v-col>
            </v-row>
            <v-row dense :key='id' v-for='(fare, id) in pnrData.FlightItinerary[0].Fares' class='text-caption text-md-body-2 border-bottom border-right'>
              <v-col cols='3' class='border-left'>
                {{fare.PaxType[0]}}
              </v-col>
              <v-col cols='1' class='border-left'>
                {{getPassangerCountByType(fare.PaxType[0])}}
              </v-col>
              <v-col cols='2' class='border-left'>
                {{getCurrencySymbol()}}{{formatPrice(fare.BaseFare[0])}}
              </v-col>
              <v-col cols='3' class='border-left'>
                {{getCurrencySymbol()}}{{formatPrice(fare.Taxes[0])}}
              </v-col>
              <v-col cols='3' class='border-left'>
                {{getCurrencySymbol()}}{{formatPrice((parseFloat(fare.BaseFare[0])+parseFloat(fare.Taxes[0]))*getPassangerCountByType(fare.PaxType[0]))}}
              </v-col>
            </v-row>
            <v-row v-if='getTotalBaggagePrice(flight)' dense class='text-md-body-2 text-caption border-bottom border-right'>
              <v-col class='border-left' cols='3'>
              </v-col>
              <v-col cols='1'>
              </v-col>
              <v-col cols='2'>
              </v-col>
              <v-col class='border-left' cols='3'>
                Extra Baggage
              </v-col>
              <v-col class='border-left' cols='3'>
                {{getCurrencySymbol()}}{{formatPrice(getTotalBaggagePrice(flight))}}
              </v-col>
            </v-row>
            <v-row dense class='text-md-body-2 text-caption border-bottom border-right'>
              <v-col class='border-left' cols='3'>
              </v-col>
              <v-col class='' cols='1'>
              </v-col>
              <v-col class='' cols='2'>
              </v-col>
              <v-col class='font-weight-bold border-left' cols='3'>
                Ticket Total ({{getCurrency()}})
              </v-col>
              <v-col class='border-left' cols='3'>
                {{getCurrencySymbol()}}{{formatPrice(sumFares({Fares: pnrData.FlightItinerary[0].Fares.map(fare => {return ({CurrencyCode: fare.CurrencyCode[0], BaseFare: parseFloat(fare.BaseFare[0]), Taxes: parseFloat(fare.Taxes[0]), CCFee: fare.CCFee[0], FullFare: fare.FullFare[0], PaxType: fare.PaxType[0]})})})+getTotalBaggagePrice(flight))}}
              </v-col>
            </v-row>
            <v-row v-if='insuranceQuote != null && insuranceEnabled == true' dense class='text-md-body-2 text-caption border-bottom border-right'>
              <v-col class='border-left' cols='3'>
              </v-col>
              <v-col cols='1'>
              </v-col>
              <v-col cols='2'>
              </v-col>
              <v-col class='font-weight-bold border-left' cols='3'>
                Insurance Total
              </v-col>
              <v-col class='border-left' cols='3'>
                ${{insuranceQuote}}
              </v-col>
            </v-row>
          </div>
          
          <v-alert v-if='!this.isHold' color='grey darken-3' dark class='text-center mt-3'>
            <v-row no-gutters align='center'>
              <v-col cols='6'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    Charged on card:
                  </v-col>
                  <v-col cols='12' class='font-weight-bold text-caption text-md-body-1'>
                    XXXX-XXXX-XXXX-{{bookingCardNumber.split('-')[bookingCardNumber.split('-').length-1]}}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='6' class='font-weight-bold'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    {{getCurrency()}} ${{formatPrice(sumFares({Fares: pnrData.FlightItinerary[0].Fares.map(fare => {return ({CurrencyCode: fare.CurrencyCode[0], BaseFare: parseFloat(fare.BaseFare[0]), Taxes: parseFloat(fare.Taxes[0]), CCFee: fare.CCFee[0], FullFare: fare.FullFare[0], PaxType: fare.PaxType[0]})})})+getTotalBaggagePrice(flight))}}
                  </v-col>
                  <v-col cols='12' v-if='insuranceQuote != null && insuranceEnabled == true'>
                    + USD ${{insuranceQuote}} for insurance
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-alert>

          <v-alert color='black' outlined>
            <div class='font-weight-bold'>Please Note:</div>
            <div>All fares are quoted in {{getCurrency()}}.</div>
            <div>Your credit card may be billed in multiple charges totaling the above amount.</div>
            <div>Some airlines may charge Baggage Fees.</div>
          </v-alert>

          <div class='my-5 text-center font-weight-bold'>Your e-ticket will be emailed shortly, once your credit card verification has been completed</div>
          <v-alert dark color='primary'>
            <span class='black--text'>Note:</span> We are handling your reservation and will send you confirmation once this has been finished and a ticket has been issued. It would be ideal if you take a note that your purchase will be completed only when the ticket has been issued. Fares are not guarenteed until ticketed. In the rare event fares increase, you may opt to cancel your booking by contacting our customer support help desk.
          </v-alert>

          <v-btn outlined color='primary' :block='isMobile()' @click='saveConfirmation'>Save this confirmation</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.border {
  border: 1px solid #43acf9;
}

.border-left {
  border-left: 1px solid #0000001f;
}

.border-right {
  border-right: 1px solid #0000001f;
}

.border-bottom {
  border-bottom: 1px solid #0000001f;
}

.border-top {
  border-top: 1px solid #0000001f;
}

.binfo-box {
  min-width: 300px;
  text-align: center;
}
</style>

<script>
import WorldData from '@/utils/WorldData.js'

export default {
  name: "BookingWidget",

  data: () => ({
    step: 0,
    passangerData: [],
    bookingEmail: '',
    bookingConfirmEmail: '',
    bookingCity: '',
    bookingZip: '',
    bookingCountry: 'US',
    bookingState: 'NA',
    bookingCardNumber: '',
    bookingCardType: 'MI',
    bookingCvv: '',
    bookingAddress: '',
    bookingAddress2: '',
    bookingPhoneNumber: '1',
    bookingAltPhoneNumber: '',
    bookingCardHolderName: '',
    bookingExpiryDate: ['', ''],
    monthNames: [
      { text: "January", value: 1 },
      { text: "February", value: 2 },
      { text: "March", value: 3 },
      { text: "April", value: 4 },
      { text: "May", value: 5 },
      { text: "June", value: 6 },
      { text: "July", value: 7 },
      { text: "August", value: 8 },
      { text: "September", value: 9 },
      { text: "October", value: 10 },
      { text: "November", value: 11 },
      { text: "December", value: 12 }
    ],
    days: Array(31).fill(1).map((x, y) => x + y),
    years: Array(110).fill(new Date().getFullYear()).map((x, y) => x - y),
    expiryMonths: Array(12).fill(1).map((x, y) => (x + y).toString()),
    expiryYears: Array(10).fill(new Date().getFullYear()).map((x, y) => (x + y).toString().slice(-2)),
    booking: false,
    genders: [
      { text: "Male", value: "M" },
      { text: "Female", value: "F" },
    ],
    pnr: '',
    pnrData: null,
    refNumber: '',
    policyNumber: '',
    dateOfBooking: '',
    countryNames: WorldData.countries,
    usStateNames: WorldData.usStates,
    caStateNames: WorldData.caStates,
    formValid: false,
    repriced: false,
    ruleRequired: v => !!v || 'This is required',
    ruleMin4: v => (v && v.length >= 4) || 'Must be atleast 4 characters',
    ruleCardCode: v => (v && v.length == 19) || 'Invalid card code',
    ruleCVV: v => (v && (v.length == 3 || v.length == 4)) || 'Invalid CVV',
    ruleZip: v => !isNaN(v) || 'Invalid zip code',
    ruleEmail: v => /.+@.+/.test(v) || 'E-mail must be valid',
    showAlert: false,
    alertMsg: '',
    insuranceEnabled: false,
    insuranceQuote: null,
    international: false,
    bookingExpireTime: 20, // minutes
    isHold: false,
  }),

  watch: {
    'flight': function (n, o) {
      this.insuranceQuote = null
      this.travelGuardProductDetails = null
      this.international = false
      if ( o == null ) { // If getting first flight information
        this.repriced = false
        this.step = 0

        this.passangerData = []
        for (let i = 0; i < this.oldAdults; i++) {
          this.passangerData.push({type: 'ADT', name: '', family: '', month: null, day: null, year: null, gender: null, paidBaggageDeparture: false, paidBaggageReturn: false})
        }
        for (let i = 0; i < this.oldChildren; i++) {
          this.passangerData.push({type: 'CHD', name: '', family: '', month: null, day: null, year: null, gender: null, paidBaggageDeparture: false, paidBaggageReturn: false})
        }
        for (let i = 0; i < this.oldInfants; i++) {
          this.passangerData.push({type: 'INF', name: '', family: '', month: null, day: null, year: null, gender: null, paidBaggageDeparture: false, paidBaggageReturn: false})
        }
      }
      else { // If getting reprice information
        this.repriced = true

        if (this.flight != null) {
          if (this.bookingCountry == 'US' && this.bookingState != 'MT' && this.bookingState != 'NA')
            this.insuranceEnabled = true
          else
            this.insuranceEnabled = false
          this.getInsuranceQuote('NJ')
        }
      }

      if (this.flight != null) {

        setTimeout (() => {
          this.alertMsg = 'Your session has expired...'
          this.showAlert = true
          this.emitClose()
        }, this.bookingExpireTime*1000*60)
      }
    },

    'bookingState': function (/*n, o*/) {
      this.getInsuranceQuote()
      if (this.bookingCountry == 'US' && this.bookingState != 'MT' && this.bookingState != 'NA')
        this.insuranceEnabled = true
      else
        this.insuranceEnabled = false
    },

    'bookingCountry': function (n) {
      if (n == 'US' && this.bookingState != 'NA')
        this.insuranceEnabled = true
      else
        this.insuranceEnabled = false
    },

    'isHold': function () {
      this.insuranceEnabled = false
    }
  },

  props: ["flight", "old-adults", "old-children", "old-infants"],

  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    emitClose() {
      this.$emit("close")
      this.$router.push('/flights')
    },

    getAvgFlightCost (flight) { // Per person
      let adultPrice = 0
      let childrenPrice = 0
      flight.Fares.forEach(fare => {
          if (fare.PaxType == 'ADT')
              adultPrice = fare.Taxes+fare.BaseFare
          else if (fare.PaxType == 'CHD')
              childrenPrice = fare.Taxes+fare.BaseFare
      });
      let avgPrice = (adultPrice+childrenPrice)/(childrenPrice == 0 ? 1 : 2)
      return avgPrice.toFixed(2);
    },

    getCarrierLogoUrlBySegment (segment, flight) {
      if (flight.FareType == 'JCB') // Show generic logo for hidden flights
        return this.backend_address+'/img/'+flight.ValidatingCarrierCode+'.gif';
      else
        return 'https://images.trippro.com/AirlineImages/AirLine/GDS/images/'+segment.FlightLogoName;
    },

    getCarrierLogoUrl (carrierCode) {
      return this.backend_address+'/img/'+carrierCode+'.gif';
    },

    formatTime (time) {
      let fTime = new Date(time)
      return fTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    },

    formatDate (date) {
      let fDate = new Date(date)
      if (!this.isMobile())
        return fDate.toLocaleString('en-US', {weekday: 'long', month: 'long', day: 'numeric' })
      else
        return fDate.toLocaleString('en-US', {month: 'short', day: 'numeric' })
    },

    getPassangerCountByType(type) {
      if (type == 'ADT')
        return this.oldAdults
      else if (type == 'CHD')
        return this.oldChildren
      else if (type == 'INF')
        return this.oldInfants
    },

    getPassangerNamesByType(type) {
      let passangersByType = this.passangerData.filter(passanger => {return passanger.type == type})
      return passangersByType.map(passanger => {return [passanger.name, passanger.family]})
    },

    formatPassangersTravelGuard (state = null) {
      let passangersFormated = []
      let pId = 1
      this.flight.Fares.forEach(fare => {
        for (let x = 0; x < this.getPassangerCountByType(fare.PaxType); x++)
        {
          passangersFormated.push({
            "travelerIdentifier": pId.toString(),
            "addresses": [
                {
                    "type": "Residency",
                    "isoStateOrProvince": state == null ? this.bookingState : state,
                    "isoCountry": this.bookingCountry
                }
            ],
            "travelerTripPrice": [
                {
                "isoCurrencyCode": this.getCurrency(),
                "value": this.formatPrice(fare.BaseFare+fare.Taxes+(this.passangerData[pId-1].paidBaggageDeparture ? this.getBaggagePrice(this.flight, 0) : 0)+(this.passangerData[pId-1].paidBaggageReturn ? this.getBaggagePrice(this.flight, 1) : 0))
                }
            ]
          })
          pId++;
        }
      })
      return passangersFormated
    },

    formatPassangersTravelGuardPurchase () {
      let passangersFormated = []
      let pId = 1
      this.flight.Fares.forEach(fare => {
        for (let x = 0; x < this.getPassangerCountByType(fare.PaxType); x++)
        {
          passangersFormated.push({
            "travelerIdentifier": pId.toString(),
            "prefix": "",
            "firstName": this.getPassangerNamesByType(fare.PaxType)[x][0],
            "middleName": "",
            "lastName": this.getPassangerNamesByType(fare.PaxType)[x][0],
            "suffix": "",
            "passengerType": ({'ADT': 'Adult', 'CHD': 'Child', 'INF': 'Infant'})[fare.PaxType],
            "addresses": [
              {
                "type": "Residency",
                "street1": this.bookingAddress,
                "city": this.bookingCity,
                "isoStateOrProvince": this.bookingState,
                "postalCode": this.bookingZip,
                "isoCountry": this.bookingCountry
              }
            ],
            "travelerTripPrice": [
              {
                "isoCurrencyCode": this.getCurrency(),
                "value": this.formatPrice(fare.BaseFare+fare.Taxes+(this.passangerData[pId-1].paidBaggageDeparture ? this.getBaggagePrice(this.flight, 0) : 0)+(this.passangerData[pId-1].paidBaggageReturn ? this.getBaggagePrice(this.flight, 1) : 0))
              }
            ],
            "contactDetails": [
              {
                "type": "Email",
                "value": this.bookingEmail
              }
            ],
          })
          pId++;
        }
      })
      return passangersFormated
    },

    formatFlightsTravelGuard () {
      let flightsFormated = []
      let cid = 0
      this.flight.Citypairs.forEach(citypair => {
        flightsFormated.push({
            "order": cid+1,
            "startDateTime": citypair.FlightSegment[0].DepartureDateTime.split('T')[0],
            "endDateTime": citypair.FlightSegment[this.flight.Citypairs[0].FlightSegment.length-1].ArrivalDateTime.split('T')[0],
            "locations": [
                {
                    "type": "Origin",
                    "locationValue": [
                        {
                            "type": "IATAAirport",
                            "value": citypair.FlightSegment[0].DepartureLocationCode
                        }
                    ]
                },
                {
                    "type": "Destination",
                    "locationValue": [
                        {
                            "type": "IATAAirport",
                            "value": citypair.FlightSegment[this.flight.Citypairs[0].FlightSegment.length-1].ArrivalLocationCode
                        }
                    ]
                }
            ]
        })
        cid++
      })

      return flightsFormated
    },

    // getCheckedBagAllowance(flight) {
    //   if (flight.IsBasicEconomyItin == true)
    //     return 0
    //   else
    //     if (['TK', 'QR', 'EY', 'SQ', 'CX', 'BR', 'EK'].includes(flight.ValidatingCarrierCode))
    //       return 2
    //     else
    //       return 1
    // },

    // getCheckedBagAllowance(flight) {
    //   if (!flight.SegmentBaggage.ALLOWED)
    //     return 0
    //   return Math.min.apply(0, Object.values(flight.SegmentBaggage.ALLOWED).map((segment) => {return segment[0].pieces.split('P')[0]}))
    // },

    getCheckedBagAllowance(flight) {
      let segmentAllowances = []
      flight.Citypairs.forEach(citypair => {
        citypair.FlightSegment.forEach(flightsegment => {
          segmentAllowances.push(flightsegment.baggageAllowance.split('P')[0])
        })
      })
      return Math.min.apply(0, segmentAllowances)
    },  

    // sumBaggagePrices(flight) { // NO LONGER USED.. BAGGAGES ARE NOW FOR EACH DIRECTION getBaggagePrice
    //   if (!Object.keys(flight.SegmentBaggage).includes('CHARGED')) {// If no Charged or empty
    //     return 0
    //   }
    //   else {
    //     return Object.entries(flight.SegmentBaggage.CHARGED).map(segment => {return segment[1].filter( pricing => {return pricing.pieces == '1P'} )[0].amount}).reduce((price, next_price) => {return price+next_price})
    //   }
    // },

    getBaggagePrice(flight, direction) {
      if (!('SegmentBaggage' in flight)) {
        return 0
      }
      
      if (!Object.keys(flight.SegmentBaggage).includes('CHARGED')) {// If no Charged or empty
        return 0
      }
      else {
        console.log(flight.SegmentBaggage.CHARGED)
        if (direction == 0)
          if ((this.$store.state.from+'-'+this.$store.state.to) in flight.SegmentBaggage.CHARGED)
            return flight.SegmentBaggage.CHARGED[this.$store.state.from+'-'+this.$store.state.to][0].amount
          else
            return 0
        else if (direction == 1)
          if ((this.$store.state.to+'-'+this.$store.state.from) in flight.SegmentBaggage.CHARGED)
            return flight.SegmentBaggage.CHARGED[this.$store.state.to+'-'+this.$store.state.from][0].amount
          else
            return 0
      }
    },

    getTotalBaggagePrice(flight) {
      return (this.getBaggagePrice(flight, 0)*this.passangerData.filter(passanger => {return passanger.paidBaggageDeparture == true}).length)+(this.getBaggagePrice(flight, 1)*this.passangerData.filter(passanger => {return passanger.paidBaggageReturn == true}).length)
    },
    
    formatPrice (price) {
      return parseFloat(price).toFixed(2)
    },

    sumFares (flight) {
      let price = 0
      flight.Fares.forEach(fare => {
        price = price+(fare.BaseFare+fare.Taxes)*this.getPassangerCountByType(fare.PaxType)
      });
      return price
    },

    pad(n, width = 2, z = 0) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },

    formatCardNumber(number) {
      if (number != '') {
        if (number.toString()[0] == '3')
          this.bookingCardType = 'AX'
        else if (number.toString()[0] == '4')
          this.bookingCardType = 'VI'
        else if (number.toString()[0] == '5')
          this.bookingCardType = 'CA'
        else if (number.toString()[0] == '6')
          this.bookingCardType = 'DS'
        else
          this.bookingCardType = 'MI'

        this.bookingCardNumber = number.replace(/-/g, '').match(/[\s\S]{1,4}/g).join('-')
      }
      else
        this.bookingCardNumber = number
    },

    splitFlightSegmentsToCitypairs(flightsegments, refCitypairs) {
      let stopsGo = 0
      let stopsReturn = 0
      if (refCitypairs.length == 1) {
        stopsGo = refCitypairs[0].FlightSegment.length
        return [{FlightSegment: flightsegments.slice(0, stopsGo)}]
      }
      else if (refCitypairs.length == 2) {
        stopsGo = refCitypairs[0].FlightSegment.length
        stopsReturn = refCitypairs[1].FlightSegment.length
        return [{FlightSegment: flightsegments.slice(0, stopsGo)}, {FlightSegment: flightsegments.slice(stopsGo, stopsReturn+stopsGo)}]
      }
    },

    joinCitypairs(citypairs) {
      let joined = []
      citypairs.forEach(citypair => {
        joined.push(...citypair.FlightSegment)
      });
      return {'FlightSegment': joined}
    },

    goToTravellers() {
      document.getElementsByClassName("v-dialog--fullscreen")[0].scrollTop = 300
      console.log(this.bookingCountry != "US" || this.bookingState == "MT" || this.insuranceQuote == null)
      console.log('test')
    },

    isFormValid () {
      this.$refs.bookingForm.validate()
      this.saveConfirmation()
      return this.formValid && (this.bookingEmail == this.bookingConfirmEmail)
    },

    bookFlight () {
      if (this.isFormValid()) {
        this.booking = true
        let bookData = {
          address:	this.bookingAddress,
          address2:	this.bookingAddress2,
          Altphone:	this.bookingAltPhoneNumber,
          card:	this.bookingCardNumber.split('-').join(''),
          cardType: this.bookingCardType,
          city:	this.bookingCity,
          country:	this.bookingCountry,
          cvv:	this.bookingCvv,
          data:	this.passangerData.map(passanger => {return {PaxType: passanger.type, year: passanger.year, month: this.pad(passanger.month), day: this.pad(passanger.day), Gender: passanger.gender, FirstName: passanger.name, LastName: passanger.family, MiddleName: "", DateOfBirth: this.pad(passanger.month)+'/'+this.pad(passanger.day)+'/'+passanger.year, UserTitle: (passanger.gender == 'M' ? 'Mr' : 'Miss')}}),
          email:	this.bookingEmail,
          expriy:	this.bookingExpiryDate[0]+'/20'+this.bookingExpiryDate[1],
          name:	this.bookingCardHolderName,
          phone:	this.bookingPhoneNumber,
          state:	this.bookingState,
          zip:	this.bookingZip,
        }
        this.$http.post(this.backend_address+(!this.isHold ? "/api/v1/booking/" : "/api/v1/booking/hold/")+this.flight.ItineraryId, bookData).then((response) => {

          if ('PNR' in response.data.response) {
            window.fbq('track', 'Purchase', {currency: "USD", value: this.sumFares(this.flight)+this.getTotalBaggagePrice(this.flight)})
            this.pnr = response.data.response.PNR
            this.refNumber = response.data.response.ReferenceNumber
            this.dateOfBooking = this.formatDate(new Date())
            this.$http.get(this.backend_address+"/api/v1/pnr/"+this.pnr).then((response) => {
              this.pnrData = response.data.response
              this.booking = false
              this.step = 1
              this.$router.push('/flights/booking/success')
              window.gtag('event', 'conversion', {
                'send_to': 'AW-321549015/MyASCIrHheYCENflqZkB',
                'transaction_id': this.pnr
              });

              // Purchase insurance
              if (this.insuranceEnabled && !this.isHold)
                this.purchaseInsurance()
            })
          }
          else {
            alert(response.data.response.ErrorText)
            this.booking = false
            this.$router.push('/flights/booking/error')
          }
        })
      }
      else {
        this.goToTravellers()
      }
    },

    saveConfirmation () {
      this.$html2canvas(document.querySelector('.confirmation-dialog')).then((canvas) => {
        this.saveAs(canvas.toDataURL(), 'confirmation.png');
      });
    },

    saveAs (uri, filename) {
      var link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;

        //Firefox requires the link to be in the body
        document.body.appendChild(link);

        //simulate click
        link.click();

        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    getCurrencySymbol() {
      if (this.$store.state.flights.length > 0) {
        if (this.$store.state.flights[0].Fares[0].CurrencyCode == 'CAD')
          return 'C$'
        else
          return '$'
      }
      else
        return null
    },

    getCurrency() {
      if (this.$store.state.flights.length > 0) {
        return this.$store.state.flights[0].Fares[0].CurrencyCode
      }
      else
        return null
    },

    checkIfContainsInternational(callback) {
      let airports = []
      this.flight.Citypairs.forEach(citypair => {
        citypair.FlightSegment.forEach(segment => {
          airports.push(segment.ArrivalLocationCode)
          airports.push(segment.DepartureLocationCode)
        })
      })
      this.$http.post('https://flights365.com/domint', {'keyText': airports}).then((response) => {
        callback(response.data[0].InternationalApts)
      })
    },

    getInsuranceQuote(state = null) {
      this.checkIfContainsInternational((containsInternational) => {
        this.international = containsInternational ? true : false;
        let passangerData = this.formatPassangersTravelGuard(state)
        let flightsData = this.formatFlightsTravelGuard()
        let currentDate = new Date(new Date() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0]
        let reqData = {
          "partnerId": "05621232",
          "quoteRequest": 
          {
            "trips": [
              {
              "initialdepositdate": currentDate,
                "tripIdentifier": "1",
                "totalTripPrice": [],
                "bookingItems": flightsData
              }
            ],
            "travelers": passangerData,
            "productDetails": [
              {
          
                "productCode": containsInternational ? "945601" : "946901", 


                "tripIdentifiers": [
                  {
                    "tripIdentifier": "1"
                  }
                ],
                "travelerIdentifiers": this.formatPassangersTravelGuard(state).map(passanger => { return { "travelerIdentifier": passanger.travelerIdentifier, "insuredType": "PrimaryInsured"} }),
                "purchaseDateTime": currentDate
              }
            ]
          }
        }

        this.insuranceQuote = null
        this.travelGuardProductDetails = null

        if (this.bookingState != 'NA' || state != null) {
          this.$http.post(this.backend_address+'/api/v1/search/quoteinsurance', {quotedata: reqData}).then((response) => {
            if (response.data.quoteResponse.quoteResponses[0].purchaseRequest != null) {
              if (response.data.quoteResponse.quoteResponses[0].purchaseRequest.productDetails.length)
              {
                this.insuranceQuote = response.data.quoteResponse.quoteResponses[0].purchaseRequest.productDetails[0].amounts[0].amountValues[0].value
                this.travelGuardProductDetails = response.data.quoteResponse.quoteResponses[0].purchaseRequest.productDetails
              }
              else // Agency is not approved to sell for plan at this time
                alert('Insurance is not available for your state. We are sorry for the inconvenience!')
            }
          })
        }

      })
    },

    purchaseInsurance() {
      // let passangerData = this.formatPassangersTravelGuard()
      let flightsData = this.formatFlightsTravelGuard()
      let currentDate = new Date(new Date() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0]
      let reqData = 
      {
        "schemaVersion": 2.5,
        "partnerId": "05621232",
        "language": "en",
        "purchaseRequest": {
          "trips": [
            {
              "tripIdentifier": "1",
              "tripType": ({'round': 'RT', 'single': 'OW', 'multi': 'MT'})[this.$store.state.flightType],
              "initialDepositDate": currentDate,
              "finalPaymentDate": "",
              "totalTripPrice": [],
              "referenceNumbers": [
                {
                  "type": "itineraryNumber",
                  "value": this.flight.ItineraryId
                }
              ],
              "bookingItems": flightsData
            }
          ],
          "travelers": this.formatPassangersTravelGuardPurchase(),
          "productDetails": this.travelGuardProductDetails,
          "paymentDetail": {
            "cardDetails": [
              {
                "cardholderName": this.bookingCardHolderName,
                "cardholderAddress": {
                  "type": "Residency",
                  "isoStateOrProvince": this.bookingState,
                  "postalCode": this.bookingZip,
                  "isoCountry": this.bookingCountry
                },
                "cardType": this.bookingCardType,
                "cardNumber": this.bookingCardNumber.split('-').join(''),
                "amount": {
                  "isoCurrencyCode": this.getCurrency(),
                  "value": this.insuranceQuote
                },
                "expirationDate": this.pad(this.bookingExpiryDate[0])+'/20'+this.bookingExpiryDate[1],
                "securityCode": this.bookingCvv
              }
            ]
          },
          "fulfillmentOption": "ONLINE"
        }
      }

      this.$http.post(this.backend_address+'/api/v1/search/purchaseinsurance', {purchasedata: reqData}).then((response) => {
        this.policyNumber = response.data.purchaseResponse.policyResponses[0].policyDetail.policyNumber
      })
    }

  },
};
</script>
