<template>
  <v-container>
    <v-skeleton-loader
      v-if="this.$store.state.searching"
      type="article, list-item-three-line"
    ></v-skeleton-loader>
    <!-- <div class='font-weight-light mb-4' v-if='!this.$store.state.searching && this.$store.state.flights.length != 0'>Showing flights from <span class='font-weight-bold'>{{this.firstFrom[0]}}</span> to <span class='font-weight-bold'>{{this.firstTo[0]}}</span>. Departing on <span class='font-weight-bold'>{{departureDate}}</span><span v-if='returnDate != ""'>, returning on <span class='font-weight-bold'>{{returnDate}}</span></span>.</div> -->
    <div v-if='$store.state.bookingFormFlight == null'>
      <v-card class='mb-5' outlined :key='id' v-for='(flight, id) in this.getPageFlights()'>
        <v-card-title style='background-color: rgba(67, 172, 249, 0.1);'>
          <v-row align='center'>
            <v-col cols='2' class='hidden-sm-and-down'>
              <v-img contain max-height='35' :src='getCarrierLogoUrl(flight)' class='ml-auto mr-auto'></v-img>
            </v-col>
            <v-col cols='6' md='4' class='text-center'>
              <v-row>
                <v-col cols='12' class='text-subtitle-1'>
                  {{flight.ValidatingCarrierName}}
                </v-col>
              </v-row>
              <v-row class='hidden-sm-and-down' no-gutters>
                <v-col cols='12' class='weight-light text-caption text--secondary'>
                  {{flightNumberLabel(flight)}}
                </v-col>
              </v-row>
            </v-col>
            <v-divider style='height: 43px; align-self: center;' vertical inset></v-divider>
            <v-col cols='6' class='hidden-md-and-up'>
              <v-btn id='book-mobile' small class='hidden-md-and-up' depressed block color='primary' @click='showBookingForm(flight)'>Book<span class='ml-2 font-weight-bold'>{{getCurrencySymbol()}}{{getAvgFlightCost(flight)}}</span></v-btn>
            </v-col>
            <v-col class='hidden-sm-and-down' cols='1'>
              <v-icon large color='primary'>{{getCheckedBagAllowance(flight) > 0 ? 'mdi-bag-suitcase' : 'mdi-bag-suitcase-off'}}</v-icon>
            </v-col>
            <v-col cols='3' md='2' class='text-caption text-md-subtitle-2 hidden-sm-and-down'>
              <v-row no-gutters>
                <v-col cols='12'>
                  {{getCheckedBagAllowance(flight) > 0 ? ((paidBaggage[id] == true ? getCheckedBagAllowance(flight)+1 : getCheckedBagAllowance(flight))+'PC Checked Bag') : 'No Checked Bag'}}
                </v-col>
              </v-row>
              <!-- <v-row v-if='sumBaggagePrices(flight)' no-gutters>
                <v-col cols='12'>
                  <v-switch v-model='paidBaggage[id]' class="mt-0" color='primary' dense hide-details>
                    <template v-slot:label><span class='font-weight-light text-body-2'>Add 1PC for <span class='font-weight-regular'>{{sumBaggagePrices(flight)}}$ </span></span></template>
                  </v-switch>
                </v-col>
              </v-row> -->
            </v-col>
            <v-divider style='height: 43px; align-self: center;' vertical inset></v-divider>
            <v-col class='hidden-sm-and-down' cols='1'>
              <v-icon large color='primary'>mdi-bag-checked</v-icon>
            </v-col>
            <v-col class='hidden-sm-and-down' cols='3' md='2'>
              <v-row no-gutters>
                <v-col cols='12' class='text-md-subtitle-2 text-caption'>
                  Hand Carry
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols='12' class='text-md-body-2 text-caption'>
                  <span class='hidden-sm-and-down'>1PC upto 7KG</span>
                  <span class='hidden-md-and-up'>1PC (7KG)</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class='mt-2'>
            <v-col cols='12' md='10'>
              <v-row align='center' class='text-md-subtitle-1 text-caption text--primary font-weight-bold' :key='id' v-for='(citypair, id) in flight.Citypairs'>
                <v-col cols='4' md='2' class='text-center'>
                  <v-row class='hidden-md-and-up' no-gutters>
                    <v-col cols='12'>
                      {{citypair.FlightSegment[0].DepartureLocationCode}}
                    </v-col>
                    <v-col class='font-md-weight-regular' cols='12'>
                      {{formatTime(citypair.FlightSegment[0].DepartureDateTime)}}
                    </v-col>
                  </v-row>
                  <v-row class='hidden-sm-and-down' no-gutters>
                    <v-col cols='12'>
                      {{citypair.FlightSegment[0].DepartureLocationCode}} {{formatTime(citypair.FlightSegment[0].DepartureDateTime)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols='12' class='weight-light text-caption text--secondary'>
                      {{formatDate(citypair.FlightSegment[0].DepartureDateTime)}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols='4' md='6'>
                  <v-row no-gutters>
                    <v-col cols='12' class='text-caption text-center primary--text hidden-md-and-up'>
                      {{citypair.Duration}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols='12'>
                      <v-img class='hidden-sm-and-down' contain max-height='26' src='@/assets/flightmap.png'></v-img>
                      <v-img class='hidden-md-and-up' contain max-height='26' src='@/assets/flightmap-m.png'></v-img>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class=''>
                    <v-col cols='12' class='text-caption text-center primary--text'>
                      {{stopsLabel(citypair)}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols='4' md='2' class='text-center'>
                  <v-row class='hidden-md-and-up' no-gutters>
                    <v-col cols='12'>
                      {{citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalLocationCode}}
                    </v-col>
                    <v-col class='font-md-weight-regular' cols='12'>
                      {{formatTime(citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalDateTime)}}
                    </v-col>
                  </v-row>
                  <v-row class='hidden-sm-and-down' no-gutters>
                    <v-col cols='12'>
                      {{citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalLocationCode}} {{formatTime(citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalDateTime)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols='12' class='weight-light text-caption text--secondary'>
                      {{formatDate(citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalDateTime)}}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols='2' class='hidden-sm-and-down primary--text text-center text-caption text-md-subtitle-2'>
                  <v-row dense>
                    <v-col cols='12'>
                      <v-icon class='mr-1' color='primary'>mdi-clock</v-icon>{{citypair.Duration}} 
                    </v-col>
                  </v-row>
                  <v-row v-if='sumIntermediateStops(citypair)' dense>
                    <v-col cols='12'>
                      {{sumIntermediateStops(citypair)}} stop(s), no plane change
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12' md='2' align-self='center' class='text-center'>
              <span class='text-h5 text--primary font-weight-bold hidden-sm-and-down'><span class='primary--text'>{{getCurrencySymbol()}}</span>{{getAvgFlightCost(flight)}}</span>
              <div class='mt-1 mb-2 hidden-sm-and-down' style='text-transform: capitalize' v-if='flight.BrandName != null'>{{flight.BrandName.toLowerCase()}}</div>
              <v-btn id='book-desktop' class='hidden-sm-and-down' depressed block color='primary' @click='showBookingForm(flight)'>Book Now <span class='ml-4 font-weight-bold hidden-md-and-up'>({{getCurrencySymbol()}}{{getAvgFlightCost(flight)}})</span></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class='pa-0 hidden-sm-and-down'>
          <v-btn tile small depressed block color='primary' @click='expandCard(id)'>{{id == expandedCard ? 'Hide details' : 'Show details'}}</v-btn>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="expandedCard == id">
            <v-divider></v-divider>

            <v-card-text>
              <v-btn-toggle
                v-model="expandedInfoType"
                mandatory
              >
                <v-btn small value='flight'>Flight information</v-btn>
                <v-btn small value='price'>Price information</v-btn>
                <v-btn small value='farerules' @click='getFareRules(flight)'>Fare rules</v-btn>
              </v-btn-toggle>

              <div v-if='expandedInfoType == "flight"'>
                <v-card v-for='(citypair, id) in flight.Citypairs' :key='id' class='my-4' elevation='2' >
                  <v-card-title>
                    <v-row no-gutters class='text-subtitle-1'>
                      <v-col cols='3' class='font-weight-bold'>
                        <span v-if='$store.state.flightType != "multi"'>
                          <v-icon color='primary'>{{id == 0 ? 'mdi-airplane-takeoff' : 'mdi-airplane-landing'}}</v-icon>
                          {{id == 0 ? 'Departure' : 'Return'}}
                        </span>
                        <span v-if='$store.state.flightType == "multi"'>
                          <v-icon color='primary'>mdi-airplane-takeoff</v-icon>
                          Flight {{id+1}}
                        </span>
                      </v-col>
                      <v-col cols='4'>
                        {{citypair.FlightSegment[0].DepartureDisplayName}} ({{citypair.FlightSegment[0].DepartureLocationCode}}) - ({{citypair.FlightSegment[citypair.FlightSegment.length-1].ArrivalLocationCode}})
                      </v-col>
                      <v-col cols='3'>
                        {{formatDate(citypair.FlightSegment[0].DepartureDateTime)}}
                      </v-col>
                      <v-col cols='2'>
                        {{citypair.Duration}}
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class='text-center'>
                    <div :key='sid' v-for='(flightsegment, sid) in citypair.FlightSegment'>
                      <v-row class='my-4' justify='center' no-gutters v-if='flightsegment.LayoverTime != ""'>
                        <v-col cols='8' class='rounded pa-1' style='background-color: rgba(67, 172, 249, 0.1);'>
                          {{flightsegment.OriginAirportName}} ({{flightsegment.DepartureLocationCode}}) {{flightsegment.LayoverTime}}
                        </v-col>
                      </v-row>
                      <v-row class='my-4 text--primary' no-gutters justify='center' align='center'>
                        <v-col cols='2'>
                          <v-img contain max-height='35' class='ml-auto mr-auto' :src='getCarrierLogoUrlBySegment(flightsegment, flight)'></v-img>
                        </v-col>
                        <v-col cols='2'>
                          <v-row dense>
                            <v-col class='text-body-2' cols='12'>
                              {{flight.FareType == 'JCB' ? flight.ValidatingCarrierName : flightsegment.MarketingAirlineName}}
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class='text-subtitle-1' cols='12'>
                              Flight ({{flightsegment.FlightNumber}})
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols='3'>
                          <v-row align='center' dense>
                            <v-col cols='4'>
                              {{formatTime(flightsegment.DepartureDateTime)}}
                            </v-col>
                            <v-col cols='8'>
                              <v-row dense>
                                <v-col class='text-subtitle-1' cols='12'>
                                  {{flightsegment.OriginAirportName}} ({{flightsegment.DepartureLocationCode}})
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col class='text-body-2' cols='12'>
                                  {{formatDate(flightsegment.DepartureDateTime)}}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols='3'>
                          <v-row align='center' dense>
                            <v-col cols='4'>
                              {{formatTime(flightsegment.ArrivalDateTime)}}
                            </v-col>
                            <v-col cols='8'>
                              <v-row dense>
                                <v-col class='text-subtitle-1' cols='12'>
                                  {{flightsegment.DestinationAirportName}} ({{flightsegment.ArrivalLocationCode}})
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col class='text-body-2' cols='12'>
                                  {{formatDate(flightsegment.ArrivalDateTime)}}
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols='2' class='primary--text'>
                          <v-row dense>
                            <v-col cols='12'>
                              <v-icon class='mr-1' color='primary'>mdi-clock</v-icon>{{flightsegment.Duration}}
                            </v-col>
                          </v-row>
                          <!-- <v-row dense>
                            <v-col cols='12'>
                              <v-btn :loading='moreDetailsLoadingSid == sid && moreDetailsLoadingCid == id' @click='showMoreDetails(flightsegment, flight, id, sid)' small color="primary">More Details</v-btn>
                            </v-col>
                          </v-row> -->
                          <v-row dense v-if='flightsegment.IntermediateStops.length'>
                            <v-col cols='12'>
                              {{flightsegment.IntermediateStops.length}} stop(s), no plane change
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </div>

              <v-card elevation='2' v-if='expandedInfoType == "price"'>
                <v-card-title>
                  <v-row dense class='text-subtitle-2'>
                    <v-col cols='2'>
                      TRAVELERS
                    </v-col>
                    <v-col cols='1'>
                      COUNT
                    </v-col>
                    <v-col cols='2'>
                      REFUND
                    </v-col>
                    <v-col cols='1'>
                      EXCHANGE
                    </v-col>
                    <v-col cols='2'>
                      BASE FARE
                    </v-col>
                    <v-col cols='2'>
                      TAXES & FEES
                    </v-col>
                    <v-col cols='2'>
                      SUBTOTAL
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row dense v-for='(fare, id) in flight.Fares' :key='id'>
                    <v-col cols='2'>
                      {{fare.PaxType}}
                    </v-col>
                    <v-col cols='1'>
                      {{getPassangerCountByType(fare.PaxType)}}
                    </v-col>
                    <v-col cols='2'>
                      <v-tooltip :disabled='fare.BeforeRefundPenaltyAllowed == false || fare.BeforeRefundPenalty == 0' bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on"><v-icon class='mr-2' color='primary' v-if='fare.BeforeRefundPenaltyAllowed == true && fare.BeforeRefundPenalty != 0'>mdi-information</v-icon>{{fare.BeforeRefundPenaltyAllowed ? (fare.BeforeRefundPenalty == 0 ? 'Free' : 'Yes') : 'No'}}</span>
                        </template>
                        <span>Penalty: {{getCurrencySymbol()}}{{fare.BeforeRefundPenalty}}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols='1'>
                      <v-tooltip :disabled='fare.BeforeExchangePenaltyAllowed == false || fare.BeforeExchangePenalty == 0' bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on"><v-icon class='mr-2' color='primary' v-if='fare.BeforeExchangePenaltyAllowed == true && fare.BeforeExchangePenalty != 0'>mdi-information</v-icon>{{fare.BeforeExchangePenaltyAllowed ? (fare.BeforeExchangePenalty == 0 ? 'Free' : 'Yes') : 'No'}}</span>
                        </template>
                        <span>Penalty: {{getCurrencySymbol()}}{{fare.BeforeExchangePenalty}}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols='2'>
                      {{getCurrencySymbol()}}{{formatPrice(fare.BaseFare)}}
                    </v-col>
                    <v-col cols='2'>
                      {{getCurrencySymbol()}}{{formatPrice(fare.Taxes)}}
                    </v-col>
                    <v-col cols='2'>
                      {{getCurrencySymbol()}}{{formatPrice((fare.BaseFare+fare.Taxes)*getPassangerCountByType(fare.PaxType))}}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols='8'>
                    </v-col>
                    <v-col class='font-weight-bold' cols='2'>
                      Total
                    </v-col>
                    <v-col class='font-weight-bold' cols='2'>
                      {{getCurrencySymbol()}}{{formatPrice(sumFares(flight))}}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              
              <v-card elevation='2' v-if='expandedInfoType == "farerules"'>
                <v-card-text class=''>
                  <!-- <div class='mb-2'><v-icon color='primary'>mdi-airplane</v-icon><b class='ml-2'>Aircraft model</b> - {{flightsegment.AirEquipmentType}}</div> -->
                  <!-- <div class='mb-2'><v-icon color='primary'>mdi-food</v-icon><b class='ml-2'>Meals</b> - {{getMeals(flight, `${flightsegment.DepartureLocationCode}-${flightsegment.ArrivalLocationCode}`)}}</div> -->
                  <div class='mb-2'><v-icon color='primary'>mdi-clipboard-alert</v-icon><b class='ml-2'>Fare rules</b>:</div>
                  <v-divider></v-divider>
                  <div style='overflow-y: auto; height: 150px;' class='text-caption' v-html="fareRules.replace(/\n/g, '<br/>').replace(/ /g, '&nbsp')"></div>
                  <v-divider></v-divider>
                </v-card-text>
              </v-card>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </div>

    <v-card flat v-if='!this.$store.state.searching && (this.$store.state.flights.length == 0) && this.$store.state.hasSearched'>
      <v-card-text class='text-subtitle-1 text-md-h6'>
        We are sorry, there are no results for the specified time period.
      </v-card-text>
    </v-card>

    <v-pagination
      v-model="$store.state.currentPage"
      :length="getPageCount()"
      @input="onPageChange()"
      v-if="!this.$store.state.searching && (this.$store.state.flights.length > 0)"
    ></v-pagination>

    <booking-widget :flight='$store.state.bookingFormFlight' @close='closeBookingForm' :old-adults='oldAdults' :old-children='oldChildren' :old-infants='oldInfants' ></booking-widget>

    <v-dialog v-if='moreDetails != null' :value="moreDetails != null" width="500" >
      <v-card>
        <v-card-title class="primary white--text">
          {{moreDetails.DepartureLocationCode}} - {{moreDetails.ArrivalLocationCode}} details
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='mt-4'>
          <div class='mb-2'><v-icon color='primary'>mdi-airplane</v-icon><b class='ml-2'>Aircraft model</b> - {{moreDetails.AirEquipmentType}}</div>
          <div class='mb-2'><v-icon color='primary'>mdi-food</v-icon><b class='ml-2'>Meals</b> - {{getMeals(moreDetailsFlight, `${moreDetails.DepartureLocationCode}-${moreDetails.ArrivalLocationCode}`)}}</div>
          <div class='mb-2'><v-icon color='primary'>mdi-clipboard-alert</v-icon><b class='ml-2'>Fare rules</b>:</div>
          <div style='overflow-y: auto; height: 150px;' class='text-caption' v-html="fareRules.replace(/\n/g, '<br/>').replaceAll(' ', '&nbsp')"></div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="moreDetails = null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import BookingWidget from '../components/BookingWidget'

  export default {
    name: 'ResultWidget',

    data: () => ({
      resultsOnPage: 20,
      expandedCard: null,
      expandedInfoType: 'flight',
      oldAdults: 1,
      oldChildren: 0,
      oldInfants: 0,
      moreDetails: null,
      moreDetailsFlight: null,
      moreDetailsLoadingSid: null,
      moreDetailsLoadingCid: null,
      fareRules: 'Retrieving fare rules...',
      paidBaggage: []
    }),

    mounted: function () {
      this.oldAdults = this.$store.state.adults
      this.oldChildren = this.$store.state.children
      this.oldInfants = this.$store.state.infants

      this.paidBaggage = Array(this.resultsOnPage).fill(false)
    },

    watch: {
      '$store.state.flights': function () {
          this.oldAdults = this.$store.state.adults
          this.oldChildren = this.$store.state.children
          this.oldInfants = this.$store.state.infants

          this.paidBaggage = Array(this.resultsOnPage).fill(false)
      }
    },

    props: ['filteredFlights', 'page'],

    computed: {
      firstFrom: function () {
        if (this.$store.state.flights.length > 0)
            return [this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureDisplayName]
        else
            return ['', '']
      },
      firstTo: function () {
        if (this.$store.state.flights.length > 0)
            return [this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].ArrivalLocationCode, this.$store.state.flights[0].Citypairs[0].FlightSegment[this.$store.state.flights[0].Citypairs[0].FlightSegment.length-1].DestinationAirportName]
        else
            return ['', '']
      },
      departureDate: function () {
        if (this.$store.state.flights.length > 0)
            return new Date(this.$store.state.flights[0].Citypairs[0].FlightSegment[0].DepartureDateTime).toLocaleString('en-US', {day: '2-digit', month: '2-digit'})
        else
            return ''
      },
      returnDate: function () {
        if (this.$store.state.flights.length > 0) {
          if (this.$store.state.flights[0].Citypairs.length > 1)
            return new Date(this.$store.state.flights[0].Citypairs[1].FlightSegment[0].DepartureDateTime).toLocaleString('en-US', {day: '2-digit', month: '2-digit'})
          else
            return ''
        }
        else
            return ''
      }
    },
    
    methods: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile
      },
      
      getTotalFlightCost (flight) { // For one passanger
        return (flight.Fares[0].Taxes+flight.Fares[0].BaseFare).toFixed(2);
      },

      getAvgFlightCost (flight) { // Per person
          let adultPrice = 0
          let childrenPrice = 0
          flight.Fares.forEach(fare => {
              if (fare.PaxType == 'ADT')
                  adultPrice = fare.Taxes+fare.BaseFare
              else if (fare.PaxType == 'CHD')
                  childrenPrice = fare.Taxes+fare.BaseFare
          });
          let avgPrice = (adultPrice+childrenPrice)/(childrenPrice == 0 ? 1 : 2)
          return avgPrice.toFixed(2);
      },

      getPassangerCountByType(type) {
        if (type == 'ADT')
          return this.oldAdults
        else if (type == 'CHD')
          return this.oldChildren
        else if (type == 'INF')
          return this.oldInfants
      },
      
      formatPrice (price) {
        return price.toFixed(2)
      },

      sumFares (flight) {
        let price = 0
        flight.Fares.forEach(fare => {
          price = price+(fare.BaseFare+fare.Taxes)*this.getPassangerCountByType(fare.PaxType)
        });
        return price
      },

      sumIntermediateStops(citypair) {
        console.log(citypair.FlightSegment.map(segment => {return segment.IntermediateStops.length}).reduce((prev, next) => {return prev+next}))
        return citypair.FlightSegment.map(segment => {return segment.IntermediateStops.length}).reduce((prev, next) => {return prev+next})
      },

      getCarrierLogoUrl (flight) {
        return this.backend_address+'/img/'+flight.ValidatingCarrierCode+'.gif';
      },

      getCarrierLogoUrlBySegment (segment, flight) {
        if (flight.FareType == 'JCB') // Show generic logo for hidden flights
          return this.backend_address+'/img/'+flight.ValidatingCarrierCode+'.gif';
        else
          return 'https://images.trippro.com/AirlineImages/AirLine/GDS/images/'+segment.FlightLogoName;
      },

      // getCheckedBagAllowance(flight) {
      //   if (flight.IsBasicEconomyItin == true)
      //     return 0
      //   else
      //     if (['TK', 'QR', 'EY', 'SQ', 'CX', 'BR', 'EK'].includes(flight.ValidatingCarrierCode))
      //       return 2
      //     else
      //       return 1
      // },

      // getCheckedBagAllowance(flight) {
      //   if (!flight.SegmentBaggage.ALLOWED)
      //    return 0
      //   return Math.min.apply(0, Object.values(flight.SegmentBaggage.ALLOWED).map((segment) => {return segment[0].pieces.split('P')[0]}))
      // },

      getCheckedBagAllowance(flight) {
        let segmentAllowances = []
        flight.Citypairs.forEach(citypair => {
          citypair.FlightSegment.forEach(flightsegment => {
            segmentAllowances.push(flightsegment.baggageAllowance.split('P')[0])
          })
        })
        return Math.min.apply(0, segmentAllowances)
      },      

      sumBaggagePrices(flight) {
        if (!Object.keys(flight.SegmentBaggage).includes('CHARGED')) {// If no Charged or empty
          return 0
        }
        else {
          return Object.entries(flight.SegmentBaggage.CHARGED).map(segment => {return segment[1].filter( pricing => {return pricing.pieces == '1P'} )[0].amount}).reduce((price, next_price) => {return price+next_price})
        }
      },

      getMeals(flight, route) {
        if (route in flight.SegmentMeals)
          return flight.SegmentMeals[route]
        else
          return 'No meals'
      },

      showMoreDetails(flightsegment, flight, cid, sid) {
        this.moreDetailsLoadingCid = cid
        this.moreDetailsLoadingSid = sid
        this.$http.post(this.backend_address+'/api/v1/farerules/'+flight.ItineraryId).then((response) => {
          this.moreDetails = flightsegment;
          this.moreDetailsFlight = flight;
          this.moreDetailsLoadingCid = null
          this.moreDetailsLoadingSid = null
          if (response.data.response != '')
            this.fareRules = response.data.response.SubSection[0].Text[0]
          else
            this.fareRules = 'Cannot retrieve fare rules'
        })
      },

      getFareRules (flight) {
        this.fareRules = 'Retrieving fare rules...'
        this.$http.post(this.backend_address+'/api/v1/farerules/'+flight.ItineraryId).then((response) => {
          if (response.data.response != '')
            this.fareRules = response.data.response.SubSection[0].Text[0]
          else
            this.fareRules = 'Cannot retrieve fare rules'
        })
      },

      flightNumberLabel (flight) {
        if (flight.Citypairs.length == 2) { // Round
          let flightNumbersGo = flight.Citypairs[0].FlightSegment.map((segment) => {return segment.FlightNumber}) 
          let flightNumbersReturn = flight.Citypairs[1].FlightSegment.map((segment) => {return segment.FlightNumber})
          // return 'Flight ( '+flightNumbersGo.join(' / ')+' ), Flight ( '+flightNumbersReturn.join(' / ')+' )'
          return 'Flight ( '+flightNumbersGo.join(' / ')+' / '+flightNumbersReturn.join(' / ')+' )'
        }
        else { // Single
          let flightNumbersGo = flight.Citypairs[0].FlightSegment.map((segment) => {return segment.FlightNumber}) 
          return 'Flight ( '+flightNumbersGo.join(' / ')+' )';
        }
      },

      formatTime (time) {
        let fTime = new Date(time)
        return fTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      },

      formatDate (date) {
        let fDate = new Date(date)
        if (!this.isMobile())
          return fDate.toLocaleString('en-US', {weekday: 'long', month: 'long', day: 'numeric' })
        else
          return fDate.toLocaleString('en-US', {month: 'short', day: 'numeric' })
      },

      stopsLabel (citypair) {
        if (citypair.NoOfStops != 0)
        {
          let stopsList = citypair.FlightSegment.map((segment) => {return segment.ArrivalLocationCode;})
          stopsList.pop()
          let stops = ' ('+stopsList.join(', ')+')'
          if (!this.isMobile())
            return citypair.NoOfStops + ' stop (s)'+stops
          else
            return citypair.NoOfStops + ' stops'
        }

        return 'No stops'
      },

      expandCard (id) {
        if (this.expandedCard == id)
          this.expandedCard = null
        else
          this.expandedCard = id

        this.expandedInfoType = 'flight'
      },

      getPageCount () {
        let pages = Math.ceil(this.filteredFlights.length/this.resultsOnPage);
        return pages;
      },

      chunk (arr, chunkSize) {
        if (chunkSize <= 0) throw "Invalid chunk size";
        var R = [];
        for (var i=0,len=arr.length; i<len; i+=chunkSize)
          R.push(arr.slice(i,i+chunkSize));
        return R;
      },

      getPageFlights () {
        return this.chunk(this.filteredFlights, this.resultsOnPage)[this.$store.state.currentPage-1]
      },

      onPageChange () {
        window.scrollTo(0, 0)
        this.expandedCard = null
        this.paidBaggage = Array(this.resultsOnPage).fill(false)
      },

      showBookingForm(flight) {
        this.$router.push('/flights/booking')
        this.$store.state.bookingFormFlight = flight // Load first data and then wait for reprice and reload the data
        this.$http.post(this.backend_address+"/api/v1/search/reprice/"+flight.ItineraryId, {adult: this.oldAdults, child: this.oldChildren, infant: this.oldInfants}).then((response) => {
          if ('ErrorCode' in response.data.response) {// If itinerary no longer exists
            this.$store.state.bookingFormFlight = null
            alert('Sorry... This flight is no longer available.')
            return
          }
          let flight_reprice = response.data.response
          if (flight_reprice.FareType == 'JCB') { // Hide JCB carrier data
            if (['LH', 'SN', 'LX', 'OS', 'BA', 'IB', 'KL', 'AF'].includes(flight_reprice.ValidatingCarrierCode)) {
              flight_reprice.ValidatingCarrierCode = 'MAJOR_EU'
              flight_reprice.ValidatingCarrierName = 'Major EU airline'
            }
            else if (['AC', 'UA', 'DL', 'AA'].includes(flight_reprice.ValidatingCarrierCode)) {
              flight_reprice.ValidatingCarrierCode = 'MAJOR_US'
              flight_reprice.ValidatingCarrierName = 'Major US airline'
            }
          }
          if (this.$store.state.bookingFormFlight != null)
            this.$store.state.bookingFormFlight = response.data.response
        })
      },

      closeBookingForm () {
        this.$store.state.bookingFormFlight = null
      },

      getCurrencySymbol() {
        if (this.$store.state.flights.length > 0) {
          if (this.$store.state.flights[0].Fares[0].CurrencyCode == 'CAD')
            return 'C$'
          else
            return '$'
        }
        else
          return null
      },

    },

    components: {
      BookingWidget
    }
  }
</script>
