<template>
  <v-container>
    <v-row justify='center' v-if='articles'>
      <v-col :cols='12' md='4' :key='index' v-for='(article, index) in articles.filter(article => {return article.hidden == 0})'>
        <v-card @click='showArticle(article)' v-if='maximized == null'>
          <v-img :src='"https://flights365.com/blogbanners/"+article.id+".jpg?"+Date.now()'></v-img>
          <v-card-title>
            {{article.title}}
          </v-card-title>
          <v-card-text v-html="formatContent(article.content).slice(0, 200)+'...'">
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if='maximized != null' cols='6'>
        <v-card>
          <v-img :src='"https://flights365.com/blogbanners/"+maximized.id+".jpg?"+Date.now()'></v-img>
          <v-card-title>
            <h1>{{maximized.title}}</h1>
          </v-card-title>
          <v-card-text class='article' v-html="formatContent(maximized.content)">
          </v-card-text>
          <v-card-actions>
            <v-btn @click='showAll()' text>
              Go back to all articles
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Blog',

    created: function () {
      document.title = "Flights365 | Blog";
      this.$http.get('https://www.flights365.com/blogposts/').then(response => {
        this.articles = this.generateLinkNames(response.data)
        if (this.getArticleFromLink(this.$route.params.link))
          this.showArticle(this.getArticleFromLink(this.$route.params.link))
      })
    },

    mounted: function () {
    },

    data: () => ({
      articles: null,
      maximized: null
    }),

    components: {
    },

    methods: {
      formatContent (content) {
        return content.replace(/\n/g, '<br/>')
      },

      showArticle (article) {
        this.$router.push(`/blog/${article['link']}`)
        this.maximized = article
        document.title = "Flights365 | "+article.title;
      },

      getArticleFromLink (link) {
        let article = this.articles.filter(article => {return article.link == link})
        if (article.length)
          return article[0]
        else
          return null
      },

      generateLinkNames (articles) {
        let articlesWithLinkNames = []
        articles.forEach((article) => {
          let articleWithLinkName = article
          articleWithLinkName['link'] = `${article.title.split(' ').map(w => {return w.toLowerCase()}).join('-')}-${article.id}`
          articlesWithLinkNames.push(articleWithLinkName)
        })
        return articlesWithLinkNames
      },

      showAll () {
        this.maximized = null
        this.$router.push('/blog')
        document.title = "Flights365 | Blog";
      }
    }
  }
</script>
<style type="text/css">
  .article img {
    width: 100%;
  }
</style>