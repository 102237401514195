<template>
  <v-container class='text-subtitle-1'>
    <div class='primary--text text-h6 my-6'>Airline Contacts</div>
    <div class='text--primary text-subtitle-2 mt-2'>Aer Lingus</div>
    <div class='mb-2'>800-474-7424</div>
    <div class='text--primary text-subtitle-2 mt-2'>Aeroflot</div>
    <div class='mb-2'>866-879-7647</div>
    <div class='text--primary text-subtitle-2 mt-2'>Aerolinas Argentinas</div>
    <div class='mb-2'>800-333-0276</div>
    <div class='text--primary text-subtitle-2 mt-2'>Aeromexico</div>
    <div class='mb-2'>877-262-0455</div>
    <div class='text--primary text-subtitle-2 mt-2'>Aeropostal</div>
    <div class='mb-2'>800-284-6637</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Botswana</div>
    <div class='mb-2'>713-626-0134</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Caledonia</div>
    <div class='mb-2'>800-254-7251</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Canada</div>
    <div class='mb-2'>888-247-2262</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air China</div>
    <div class='mb-2'>800-882-8122</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Europa</div>
    <div class='mb-2'>902-401-501</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Fiji</div>
    <div class='mb-2'>800-227-4446</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air France</div>
    <div class='mb-2'>800-237-2747</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air India</div>
    <div class='mb-2'>800-223-7776</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Jamaica</div>
    <div class='mb-2'>800-920-4225</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Madagascar</div>
    <div class='mb-2'>800-821-3388</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Malta</div>
    <div class='mb-2'>800-756-2582</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Mauritius</div>
    <div class='mb-2'>800-537-1182</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Namibia</div>
    <div class='mb-2'>800-626-4242</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air New Zealand</div>
    <div class='mb-2'>800-262-1234</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Pacific</div>
    <div class='mb-2'>800-227-4446</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Portugal (TAP)</div>
    <div class='mb-2'>800-221-7370</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Sunshine</div>
    <div class='mb-2'>800-327-8900</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Tahiti Nui</div>
    <div class='mb-2'>877-824-4846</div>
    <div class='text--primary text-subtitle-2 mt-2'>AirTran Airways</div>
    <div class='mb-2'>800-247-8726</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Vanuatu</div>
    <div class='mb-2'>800-677-4277</div>
    <div class='text--primary text-subtitle-2 mt-2'>Air Zimbabwe</div>
    <div class='mb-2'>800-742-3006</div>
    <div class='text--primary text-subtitle-2 mt-2'>Alaska Airlines</div>
    <div class='mb-2'>800-654-5669</div>
    <div class='text--primary text-subtitle-2 mt-2'>Alitalia</div>
    <div class='mb-2'>800-223-5730</div>
    <div class='text--primary text-subtitle-2 mt-2'>All Nippon Airways</div>
    <div class='mb-2'>800-235-9262</div>
    <div class='text--primary text-subtitle-2 mt-2'>American Airlines</div>
    <div class='mb-2'>800-433-7300</div>
    <div class='text--primary text-subtitle-2 mt-2'>Asiana Airlines</div>
    <div class='mb-2'>800-227-4262</div>
    <div class='text--primary text-subtitle-2 mt-2'>Austrian Airlines</div>
    <div class='mb-2'>800-843-0002</div>
    <div class='text--primary text-subtitle-2 mt-2'>Avianca</div>
    <div class='mb-2'>800-284-2622</div>
    <div class='text--primary text-subtitle-2 mt-2'>Bangkok Airways</div>
    <div class='mb-2'>866-226-4565</div>
    <div class='text--primary text-subtitle-2 mt-2'>British Airways</div>
    <div class='mb-2'>800-247-9297</div>
    <div class='text--primary text-subtitle-2 mt-2'>Caribbean Star Airlines</div>
    <div class='mb-2'>800-920-4225</div>
    <div class='text--primary text-subtitle-2 mt-2'>Cathay Pacific Airways</div>
    <div class='mb-2'>800-233-2742</div>
    <div class='text--primary text-subtitle-2 mt-2'>China Airlines</div>
    <div class='mb-2'>800-227-5118</div>
    <div class='text--primary text-subtitle-2 mt-2'>China Eastern Airlines</div>
    <div class='mb-2'>866-588-0825</div>
    <div class='text--primary text-subtitle-2 mt-2'>China Southern</div>
    <div class='mb-2'>888-338-8988</div>
    <div class='text--primary text-subtitle-2 mt-2'>Condor</div>
    <div class='mb-2'>866-960-7915</div>
    <div class='text--primary text-subtitle-2 mt-2'>Copa Airlines</div>
    <div class='mb-2'>800-359-2672</div>
    <div class='text--primary text-subtitle-2 mt-2'>Corsair</div>
    <div class='mb-2'>800-677-0720</div>
    <div class='text--primary text-subtitle-2 mt-2'>Croatia Airlines</div>
    <div class='mb-2'>888-462-7628</div>
    <div class='text--primary text-subtitle-2 mt-2'>Czech Airlines</div>
    <div class='mb-2'>855-359-2932</div>
    <div class='text--primary text-subtitle-2 mt-2'>Delta Air Lines</div>
    <div class='mb-2'>800-221-1212</div>
    <div class='text--primary text-subtitle-2 mt-2'>Egyptair</div>
    <div class='mb-2'>1-212-581-5600</div>
    <div class='text--primary text-subtitle-2 mt-2'>El Al Israel Airlines</div>
    <div class='mb-2'>800-223-6700</div>
    <div class='text--primary text-subtitle-2 mt-2'>Emirates Air</div>
    <div class='mb-2'>800-777-3999</div>
    <div class='text--primary text-subtitle-2 mt-2'>Ethiopian Airlines</div>
    <div class='mb-2'>800-445-2733</div>
    <div class='text--primary text-subtitle-2 mt-2'>Estonian Air</div>
    <div class='mb-2'>800-397-1354</div>
    <div class='text--primary text-subtitle-2 mt-2'>EVA Airways</div>
    <div class='mb-2'>800-695-1188</div>
    <div class='text--primary text-subtitle-2 mt-2'>Finnair</div>
    <div class='mb-2'>800-950-5000</div>
    <div class='text--primary text-subtitle-2 mt-2'>Frontier Airlines</div>
    <div class='mb-2'>800-432-1359</div>
    <div class='text--primary text-subtitle-2 mt-2'>Gulf Air</div>
    <div class='mb-2'>888-359-4853</div>
    <div class='text--primary text-subtitle-2 mt-2'>Hawaiian Airlines</div>
    <div class='mb-2'>800-367-5320</div>
    <div class='text--primary text-subtitle-2 mt-2'>Iberia</div>
    <div class='mb-2'>800-772-4642</div>
    <div class='text--primary text-subtitle-2 mt-2'>Icelandair</div>
    <div class='mb-2'>800-223-5500</div>
    <div class='text--primary text-subtitle-2 mt-2'>Japan Airlines</div>
    <div class='mb-2'>800-525-3663</div>
    <div class='text--primary text-subtitle-2 mt-2'>Jet Airways (India)</div>
    <div class='mb-2'>877-835-9538</div>
    <div class='text--primary text-subtitle-2 mt-2'>JetBlue Airways</div>
    <div class='mb-2'>800-538-2583</div>
    <div class='text--primary text-subtitle-2 mt-2'>Kenya Airways</div>
    <div class='mb-2'>866-536-9224</div>
    <div class='text--primary text-subtitle-2 mt-2'>KLM</div>
    <div class='mb-2'>866-434-0320</div>
    <div class='text--primary text-subtitle-2 mt-2'>Korean Air</div>
    <div class='mb-2'>800-438-5000</div>
    <div class='text--primary text-subtitle-2 mt-2'>Kuwait Airways</div>
    <div class='mb-2'>800-458-9248</div>
    <div class='text--primary text-subtitle-2 mt-2'>LanChile Airlines</div>
    <div class='mb-2'>866-435-9526</div>
    <div class='text--primary text-subtitle-2 mt-2'>Lufthansa</div>
    <div class='mb-2'>800-645-3880</div>
    <div class='text--primary text-subtitle-2 mt-2'>Malaysia</div>
    <div class='mb-2'>800-552-9264</div>
    <div class='text--primary text-subtitle-2 mt-2'>Philippine Airlines</div>
    <div class='mb-2'>800-435-9725</div>
    <div class='text--primary text-subtitle-2 mt-2'>Qantas Airways</div>
    <div class='mb-2'>800-227-4500</div>
    <div class='text--primary text-subtitle-2 mt-2'>Royal Air Maroc</div>
    <div class='mb-2'>800-344-6726</div>
    <div class='text--primary text-subtitle-2 mt-2'>Royal Jordanian Airlines</div>
    <div class='mb-2'>212-949-0050</div>
    <div class='text--primary text-subtitle-2 mt-2'>SAS Scandinavian Airlines</div>
    <div class='mb-2'>800-221-2350</div>
    <div class='text--primary text-subtitle-2 mt-2'>Saudia Arabian Airlines</div>
    <div class='mb-2'>800-472-8342</div>
    <div class='text--primary text-subtitle-2 mt-2'>Singapore Airlines</div>
    <div class='mb-2'>800-742-3333</div>
    <div class='text--primary text-subtitle-2 mt-2'>South African</div>
    <div class='mb-2'>800-722-9675</div>
    <div class='text--primary text-subtitle-2 mt-2'>Spirit Airlines</div>
    <div class='mb-2'>801-401-2200</div>
    <div class='text--primary text-subtitle-2 mt-2'>SriLankan</div>
    <div class='mb-2'>877-915-2652</div>
    <div class='text--primary text-subtitle-2 mt-2'>Sun Country Airlines</div>
    <div class='mb-2'>800-924-6184</div>
    <div class='text--primary text-subtitle-2 mt-2'>SWISS</div>
    <div class='mb-2'>877-359-7947</div>
    <div class='text--primary text-subtitle-2 mt-2'>TAM - Brazilian Airlines</div>
    <div class='mb-2'>888-235-9826</div>
    <div class='text--primary text-subtitle-2 mt-2'>TAP Air Portugal</div>
    <div class='mb-2'>800-221-7370</div>
    <div class='text--primary text-subtitle-2 mt-2'>Thai Air</div>
    <div class='mb-2'>800-426-5204</div>
    <div class='text--primary text-subtitle-2 mt-2'>Turkish Airlines</div>
    <div class='mb-2'>800-874-8875</div>
    <div class='text--primary text-subtitle-2 mt-2'>Ukraine Intl Airlines</div>
    <div class='mb-2'>800-876-0114</div>
    <div class='text--primary text-subtitle-2 mt-2'>United Airlines</div>
    <div class='mb-2'>800-864-8331</div>
    <div class='text--primary text-subtitle-2 mt-2'>Virgin Atlantic</div>
    <div class='mb-2'>800-862-8621</div>
    <div class='text--primary text-subtitle-2 mt-2'>WestJet Airlines</div>
    <div class='mb-2'>888-937-8538</div>

  </v-container>
</template>

<script>

  export default {
    name: 'AirlinesContacts',

    created: function () {
      document.title = "Flights365 | Airline Contacts";
    },

    components: {
    },
  }
</script>
