<template>
  <v-app>
    <v-app-bar app color='white' height="80">
      <v-img
        style='cursor: pointer;'
        class="mx-2 hidden-md-and-up"
        src='@/assets/logo_ball.png'
        max-width="60"
        contain
        @click = 'goToPage("home")'
      ></v-img>
      <v-img
        style='cursor: pointer;'
        class="mx-2 hidden-sm-and-down"
        src='@/assets/logo_768.png'
        max-width="300"
        contain
        @click = 'goToPage("home")'
      ></v-img>
      <v-spacer></v-spacer>

      <div>
        <v-row align='center' no-gutters class='primary--text text-md-body-1 text-subtitle-1 font-weight-bold'>
          <v-col class='hidden-sm-and-down' cols='auto'>
            <v-icon :size='35' class='primary--text mr-4' aria-hidden="false">
              mdi-cellphone-sound
            </v-icon>
          </v-col>
          <v-col>
            <div class='text-caption secondary--text hidden-sm-and-down'>Call Us Toll-Free for Phone-Exclusive Deals</div>
            <div><a class='no-underline' href='tel:18338333088'>1-833-833-3088</a><span class='hidden-sm-and-down'> | <a class='no-underline' href='mailto:support@flights365.com'>support@flights365.com</a></span></div>
          </v-col>
        </v-row>
      </div>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="light-blue--text text--accent-4"
        >
          <v-list-item v-for='(link, id) in links' :to='link.link' :key='id'>
            <v-list-item-icon>
              <v-icon>{{link.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{link.name}}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <div>
        <router-view />
      </div>
    </v-main>
    <v-footer class='hidden-sm-and-down d-md-block pt-6' color='grey lighten-3'>
      <v-row justify='center'>
        <v-col cols='3' class='px-16'>
          <v-img
            style='cursor: pointer;'
            src='@/assets/logo_768.png'
            max-width="200"
            contain
            @click = 'goToPage("home")'
          ></v-img>
          <div class='text-subtitle-2 mt-2'>
            Flights365 is an expert in international discounted airfares. You can book online or over the phone. Free cancellation with 24 hour of the purchase.
          </div>
          <div class='font-weight-bold text-body-1 my-2'>
            Follow Us
          </div>
          <v-row>
            <v-col cols='auto'><a class='footer-link' target='_blank' href='https://www.facebook.com/flights365/'><v-icon large color='primary'>mdi-facebook</v-icon></a></v-col>
            <v-col cols='auto'><a class='footer-link' target='_blank' href='https://www.instagram.com/flights_365/'><v-icon large color='primary'>mdi-instagram</v-icon></a></v-col>
            <v-col cols='auto'><a class='footer-link' target='_blank' href='https://twitter.com/FLIGHTS3651'><v-icon large color='primary'>mdi-twitter</v-icon></a></v-col>
            <v-col cols='auto'><a class='footer-link' target='_blank' href='https://www.linkedin.com/company/flight365'><v-icon large color='primary'>mdi-linkedin</v-icon></a></v-col>
          </v-row>
        </v-col>
        <v-col cols='2'>
          <div class='text-subtitle-1 font-weight-bold'>TRAVELER TOOLS</div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[10].link'>{{links[10].name}}</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[3].link'>{{links[3].name}}</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[4].link'>{{links[4].name}}</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[5].link'>{{links[5].name}}</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[9].link'>{{links[9].name}}</router-link></div>
        </v-col>
        <v-col cols='2'>
          <div class='text-subtitle-1 font-weight-bold'>DEALS</div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/bulgaria'>Cheap tickets to Bulgaria</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/philippines'>Cheap tickets to Philippines</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/miami'>Cheap tickets to Miami</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/brazil'>Cheap tickets to Brazil</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/india'>Cheap tickets to India</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/japan'>Cheap tickets to Japan</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/iceland'>Cheap tickets to Iceland</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/italy'>Cheap tickets to Italy</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/china'>Cheap tickets to China</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/australia'>Cheap tickets to Australia</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/greece'>Cheap tickets to Greece</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' to='/landing/hawaii'>Cheap tickets to Hawaii</router-link></div>
        </v-col>
        <v-col cols='auto'>
          <div class='text-subtitle-1 font-weight-bold'>LEGAL</div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[6].link'>{{links[6].name}}</router-link></div>
          <div><router-link class='text-subtitle-2 text--primary footer-link' target='_blank' :to='links[7].link'>{{links[7].name}}</router-link></div>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols='3'>
          <v-row no-gutters>
            <v-col cols='4' style="text-align:center">
              <v-img width='150px' src='@/assets/winner-2020.png'></v-img>
            </v-col>
            <v-col cols='4' style="text-align:center">
              <v-row justify='center' no-gutters class='primary--text font-weight-bold mb-2'>
                <v-col cols='12' class='text-subtitle-1 font-weight-bold'>
                  Awards
                </v-col>
              </v-row>
              <v-row justify='center' no-gutters>
                <v-col cols='12' class='text-subtitle-2 font-weight-regular'>
                  2017, 2018, 2019 and 2020
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='4' style="text-align:center">
              <v-row justify='center' no-gutters class='primary--text font-weight-bold mb-2'>
                <v-col cols='12' class='text-subtitle-1 font-weight-bold'>
                  Nominated
                </v-col>
              </v-row>
              <v-row justify='center' no-gutters>
                <v-col cols='12' class='text-subtitle-2 font-weight-regular'>
                  Bulgaria's Leading<br/> Travel Agency 2021
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify='center'>
        <v-col cols='auto' class='text-body-1'>
          127 West 30th Street, 10th Floor, New York NY 10001
        </v-col>
      </v-row>
      <v-row dense justify='center'>
        <v-col cols='auto' class='text-body-1 font-weight-bold'>
          Copyright © 2021. All Rights Reserved Flights365.com Inc
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo() {
    return {
      meta: [
        { name: 'description', content: `Flights365 is an OTA (online travel agency) that specializes in providing customers with the best fares available for domestic and international` }
      ]
    }
  },

  data: () => ({
    drawer: false,
    group: 1,
    links: [
      {'name': 'Home', 'link': '/home', 'icon': 'mdi-home'},
      {'name': 'Flights', 'link': '/flights', 'icon': 'mdi-airplane-takeoff'},
      {'name': 'Fares', 'link': '/fares', 'icon': 'mdi-file-table'},
      {'name': 'About Us', 'link': '/about', 'icon': 'mdi-information'},
      {'name': 'Contact Us', 'link': '/contact', 'icon': 'mdi-card-account-phone'},
      {'name': 'FAQ', 'link': '/faq', 'icon': 'mdi-frequently-asked-questions'},
      {'name': 'Terms and conditions', 'link': '/terms-and-conditions', 'icon': 'mdi-clipboard-text-outline'},
      {'name': 'Privacy Policy', 'link': '/privacy-policy', 'icon': 'mdi-shield-lock'},
      {'name': 'Airline Contacts', 'link': '/airlines-contacts', 'icon': 'mdi-format-list-bulleted'},
      {'name': 'Credit Card Authorization', 'link': '/credit-card-authorization', 'icon': 'mdi-credit-card'},
      {'name': 'Blog', 'link': '/blog', 'icon': 'mdi-book-open'},
    ]
  }),

  methods: {
    goToPage (page) {
      this.$router.push('/'+page)
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  }
};
</script>

<style>
a.footer-link {
  text-decoration: none;
}

a.footer-link:active {
  text-decoration: none;
}

.no-underline {
  text-decoration: none;
}
</style>
