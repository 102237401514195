<template>
  <v-container>
    <div class='d-flex justify-end'>
        <v-btn @click='scrollMatrix("left")' small color='primary' class='hidden-sm-and-down mr-1'><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-btn @click='scrollMatrix("right")' small color='primary' class='hidden-sm-and-down'><v-icon>mdi-arrow-right</v-icon></v-btn>
    </div>
    <v-card class='mt-3'>
        <v-row dense>
            <v-col cols='5' md='3'>
                <div style=''>
                    <v-row dense class='' align='center' justify='center'>
                        <v-col class='border-bottom-grey'>
                            <v-btn @click='emitClearFilters()' block tile text>All airlines</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if='hasNonStop()' dense class='' align='center' justify='center'>
                        <v-col class='border-bottom-grey'>
                            <v-btn @click='emitFilterByStopCount("n")' block tile text>Non Stop</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if='hasOneStop()' dense class='' align='center' justify='center'>
                        <v-col class='border-bottom-grey'>
                            <v-btn @click='emitFilterByStopCount("s")' block tile text>1 stops</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if='hasMultiStop()' dense class='mb-2' align='center' justify='center'>
                        <v-col class='border-bottom-grey'>
                            <v-btn @click='emitFilterByStopCount("m")' block tile text>1+ stops</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col style='position: relative;' cols='7' md='9'>
                <div class='matrix' ref='matrix'>
                    <v-row dense class='flex-nowrap' align='center'>
                        <v-col class='border-bottom-left-grey d-flex justify-center' @click='emitFilterByCarrier(carrier)' v-ripple style='cursor: pointer; position: relative;' :key='id' v-for='(carrier, id) in getCarriers()'><v-img contain class='mx-2' height='36' width='80' :src='getCarrierLogoUrl(carrier)'></v-img><div v-if='filterCarrier == carrier && filterStops == ""' class='black lighten-1 active-overlay'></div></v-col>
                    </v-row>
                    <v-row v-if='hasNonStop()' dense class='flex-nowrap' align='center'>
                        <v-col class='border-bottom-left-grey d-flex justify-center' @click='emitFilterByCarrierAndStopCount(carrier, "n", getCheapestNonStop(carrier).length > 0)' v-ripple style='cursor: pointer; position: relative;' :key='id' v-for='(carrier, id) in getCarriers()'><div class='mx-2 text-center align-center justify-center d-flex font-weight-bold' style='height: 36px; width: 80px;' ><div>{{getCheapestNonStop(carrier)}}</div></div><div v-if='(filterCarrier == carrier && filterStops == "") || (filterCarrier == carrier && filterStops == "n")' class='black lighten-1 active-overlay'></div></v-col>
                    </v-row>
                    <v-row v-if='hasOneStop()' dense class='flex-nowrap' align='center'>
                        <v-col class='border-bottom-left-grey d-flex justify-center' @click='emitFilterByCarrierAndStopCount(carrier, "s", getCheapestOneStop(carrier).length > 0)' v-ripple style='cursor: pointer; position: relative;' :key='id' v-for='(carrier, id) in getCarriers()'><div class='mx-2 text-center align-center justify-center d-flex font-weight-bold' style='height: 36px; width: 80px;' ><div>{{getCheapestOneStop(carrier)}}</div></div><div v-if='(filterCarrier == carrier && filterStops == "") || (filterCarrier == carrier && filterStops == "s")' class='black lighten-1 active-overlay'></div></v-col>
                    </v-row>
                    <v-row v-if='hasMultiStop()' dense class='flex-nowrap mb-2' align='center'>
                        <v-col class='border-bottom-left-grey d-flex justify-center' @click='emitFilterByCarrierAndStopCount(carrier, "m", getCheapestMultiStop(carrier).length > 0)' v-ripple style='cursor: pointer; position: relative;' :key='id' v-for='(carrier, id) in getCarriers()'><div class='mx-2 text-center align-center justify-center d-flex font-weight-bold' style='height: 36px; width: 80px;' ><div>{{getCheapestMultiStop(carrier)}}</div></div><div v-if='(filterCarrier == carrier && filterStops == "") || (filterCarrier == carrier && filterStops == "m")' class='black lighten-1 active-overlay'></div></v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols='12' class='text-caption font-weight-light text-center'>
                Prices are per person, include all taxes and fees. Additional baggage fees may apply.
            </v-col>
        </v-row>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'MatrixWidget',

    data: () => ({
      
    }),

    mounted: function () {
        this.getCarriers()
    },

    props: ['flights', 'filter-carrier', 'filter-stops'],
    
    methods: {
        scrollMatrix (direction) {
            if (direction == 'left')
                this.$refs.matrix.scrollLeft -= 105
            else
                this.$refs.matrix.scrollLeft += 105
        },
        
        getCarriers() {
            let flightCarriers = this.flights.map((flight) => {return {'Code': flight.ValidatingCarrierCode, 'Price': this.getAvgFlightCost(flight)}}).sort((a, b) => { return a.Price-b.Price }).map(flight => {return flight.Code})
            let carriers = flightCarriers.filter(function(item, pos, self) {
                return self.indexOf(item) == pos;
            })
            return carriers
        },

        getCarrierLogoUrl (carrier) {
            return this.backend_address+'/img/'+carrier+'.gif';
        },

        emitFilterByCarrier (carrier) {
            this.$emit('filter-by-carrier', carrier)
        },

        emitFilterByStopCount (stops) {
            this.$emit('filter-by-stop-count', stops)
        },

        emitFilterByCarrierAndStopCount (carrier, stops, search = true){
            // stops [ n: Non Stop, s: One Stop, m: Multi Stop ]

            if (search)
                this.$emit('filter-by-carrier-stops', carrier, stops)
        },

        emitClearFilters () {
            this.$emit('clear-filters')
        },

        getTotalFlightCost (flight) {
            return (flight.Fares[0].Taxes+flight.Fares[0].BaseFare).toFixed(2);
        },

        getAvgFlightCost (flight) { // Per person
            let adultPrice = 0
            let childrenPrice = 0
            flight.Fares.forEach(fare => {
                if (fare.PaxType == 'ADT')
                    adultPrice = fare.Taxes+fare.BaseFare
                else if (fare.PaxType == 'CHD')
                    childrenPrice = fare.Taxes+fare.BaseFare
            });
            let avgPrice = (adultPrice+childrenPrice)/(childrenPrice == 0 ? 1 : 2)
            return avgPrice.toFixed(2);
        },

        hasNonStop () {
            let flights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (flight.Citypairs[0].FlightSegment.length == 1)
                else
                    return ((flight.Citypairs[0].FlightSegment.length == 1) && (flight.Citypairs[1].FlightSegment.length == 1))
            })
            return flights.length > 0
        },

        hasOneStop () {
            let flights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (flight.Citypairs[0].FlightSegment.length == 2)
                else
                    return ((flight.Citypairs[0].FlightSegment.length == 2) || (flight.Citypairs[1].FlightSegment.length == 2))
            })
            return flights.length > 0
        },

        hasMultiStop () {
            let flights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (flight.Citypairs[0].FlightSegment.length > 2)
                else
                    return ((flight.Citypairs[0].FlightSegment.length > 2) || (flight.Citypairs[1].FlightSegment.length > 2))
            })
            return flights.length > 0
        },

        getCheapestNonStop (carrier) {
            // Get Non Stop flights by carrier
            let carrierFlights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (carrier == flight.ValidatingCarrierCode) && (flight.Citypairs[0].FlightSegment.length == 1)
                else
                    return (carrier == flight.ValidatingCarrierCode) && ((flight.Citypairs[0].FlightSegment.length == 1) && (flight.Citypairs[1].FlightSegment.length == 1))
            })
            // Get Cheapest
            let carrierPrices = carrierFlights.map((carrierFlight) => {return this.getAvgFlightCost(carrierFlight)})
            return Math.min.apply(Math, carrierPrices) === Infinity ? '' : (this.getCurrencySymbol()+Math.min.apply(Math, carrierPrices).toFixed(2))
        },

        getCheapestOneStop (carrier) {
            // Get One Stop flights by carrier
            let carrierFlights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (carrier == flight.ValidatingCarrierCode) && (flight.Citypairs[0].FlightSegment.length == 2)
                else
                    return (carrier == flight.ValidatingCarrierCode) && ((flight.Citypairs[0].FlightSegment.length == 2 && flight.Citypairs[1].FlightSegment.length <= 2) || (flight.Citypairs[0].FlightSegment.length <= 2 && flight.Citypairs[1].FlightSegment.length == 2))
            })            
            // Get Cheapest
            let carrierPrices = carrierFlights.map((carrierFlight) => {return this.getAvgFlightCost(carrierFlight)})
            return Math.min.apply(Math, carrierPrices) === Infinity ? '' : (this.getCurrencySymbol()+Math.min.apply(Math, carrierPrices).toFixed(2))
        },

        getCheapestMultiStop (carrier) {
            // Get Multi Stop flights by carrier
            let carrierFlights = this.flights.filter((flight) => {
                if (flight.Citypairs.length == 1)
                    return (carrier == flight.ValidatingCarrierCode) && (flight.Citypairs[0].FlightSegment.length > 2)
                else
                    return (carrier == flight.ValidatingCarrierCode) && ((flight.Citypairs[0].FlightSegment.length > 2) || (flight.Citypairs[1].FlightSegment.length > 2))
            })            
            // Get Cheapest
            let carrierPrices = carrierFlights.map((carrierFlight) => {return this.getAvgFlightCost(carrierFlight)})
            return Math.min.apply(Math, carrierPrices) === Infinity ? '' : (this.getCurrencySymbol()+Math.min.apply(Math, carrierPrices).toFixed(2))
        },

        getCurrencySymbol() {
        if (this.$store.state.flights.length > 0) {
          if (this.$store.state.flights[0].Fares[0].CurrencyCode == 'CAD')
            return 'C$'
          else
            return '$'
        }
        else
          return null
        }
    }
  }
</script>

<style>
.matrix {
    overflow-y: hidden;
}

.btn-right {
    position: absolute !important;
    right: 0;
    top: -34%;
    z-index: 4;
}

.btn-left {
    position: absolute !important;
    right: 6%;
    top: -34%;
    z-index: 4;
}

.border-bottom-left-grey {
    border-bottom: 1px dashed #80808040;
    border-left: 1px dashed #80808040;
    border-collapse: collapse;
}

.border-bottom-grey {
    border-bottom: 1px dashed #80808040;
    border-collapse: collapse;
}

.active-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.2;
}
</style>
