<template>
  <v-container fluid>
    <v-row>
      <v-col cols='12'>
        <search-widget/>
        <v-snackbar
          v-model="showAlert"
          :timeout="4000"
        >
          {{ alertMsg }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="showAlert = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols='12' lg='3'>
        <filter-widget :sort='sort' :carrier='filterCarrier' :connectionPoints='filterConnectionPoints' :allConnectionPoints='getConnectionPoints()' :departureTime='filterDepartureTime' :returnTime='filterReturnTime' :departureDuration='filterDepartureDuration' :returnDuration='filterReturnDuration' :layOver='filterLayOver' :baggageAllowance='filterBaggage' :stops='filterStops' @sort='sortBy' @filter-by-carrier='filterByCarrier' @filter-by-depart-time='filterByDepartTime' @filter-by-return-time='filterByReturnTime' @filter-by-depart-duration='filterByDepartDuration' @filter-by-return-duration='filterByReturnDuration' @filter-by-connection-points='filterByConnectionPoints' @clear-filters='clearFilters' @filter-by-layover='filterByLayOver' @filter-by-baggage='filterByBaggage' @filter-by-stop-count='filterByStopCount' />
      </v-col>
      <v-col cols='12' lg='9'>
        <matrix-widget :flights='this.$store.state.flights' v-if='this.$store.state.flights.length > 0' :filter-carrier='filterCarrier' @filter-by-carrier='filterByCarrier' :filter-stops='filterStops' @filter-by-stop-count='filterByStopCount' @filter-by-carrier-stops='filterByCarrierAndStopCount' @clear-filters='clearFilters' />
        <v-container class='hidden-sm-and-down' v-if='this.$store.state.flights.length > 0'>
          <div class='d-flex justify-center'>
            <div>
              <v-btn-toggle v-model="sort" mandatory dense>
                <v-btn value="cheapest" x-large text active-class='primary white--text'>Cheapest</v-btn>
                <v-btn value="shortest" x-large text active-class='primary white--text'>Shortest</v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-container>
        <result-widget :filtered-flights='filteredFlights' />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SearchWidget from '../components/SearchWidget'
  import ResultWidget from '../components/ResultWidget'
  import MatrixWidget from '../components/MatrixWidget'
  import FilterWidget from '../components/FilterWidget'

  export default {
    name: 'Flights',

    data: () => ({
      flightsExpireTime: 10, // minutes
      matches: 'exact',
      sort: 'cheapest',
      filterCarrier: '',
      filterStops: '',
      filterDepartureTime: [0, 1439],
      filterReturnTime: [0, 1439],
      filterDepartureDuration: [0, 144000],
      filterReturnDuration: [0, 144000],
      filterConnectionPoints: [],
      filterLayOver: 0,
      filterBaggage: 0,
      showAlert: false,
      alertMsg: ''
    }),

    created: function () {
      document.title = "Flights365 | Search For Flights";
    },

    watch: {
      '$store.state.flights': function () {
        this.clearFilters()
      }
    },

    computed: {
      filteredFlights: function () {
        let flights = []
        if (this.matches == 'exact')
          flights = JSON.parse(JSON.stringify(this.$store.state.flights))
        else if (this.matches == 'alternative')
          flights = JSON.parse(JSON.stringify(this.$store.state.altFlights))

        // Sort
        flights = flights.sort((a, b) => {
          if (this.sort == 'shortest')
            if (a.Citypairs.length == 1)
              return (this.timeStringToMinutes(a.Citypairs[0].Duration)-this.timeStringToMinutes(b.Citypairs[0].Duration))
            else
              return (this.timeStringToMinutes(a.Citypairs[0].Duration)+this.timeStringToMinutes(a.Citypairs[1].Duration))-(this.timeStringToMinutes(b.Citypairs[0].Duration)+this.timeStringToMinutes(b.Citypairs[1].Duration))
          else // Cheapest
            if (this.getAvgFlightCost(a) == this.getAvgFlightCost(b)) // Secondary sorting criteria
              if (a.Citypairs.length == 1)
                return (this.timeStringToMinutes(a.Citypairs[0].Duration)-this.timeStringToMinutes(b.Citypairs[0].Duration))
              else
                return (this.timeStringToMinutes(a.Citypairs[0].Duration)+this.timeStringToMinutes(a.Citypairs[1].Duration))-(this.timeStringToMinutes(b.Citypairs[0].Duration)+this.timeStringToMinutes(b.Citypairs[1].Duration))
            else // Primary sorting criteria
              return this.getAvgFlightCost(a)-this.getAvgFlightCost(b)
        })

        // Filter by carrier
        flights = flights.filter((flight) => {
          return (flight.ValidatingCarrierCode == this.filterCarrier) || this.filterCarrier == '';
        })
        
        // Filter by stops [ n: Non Stop, s: One Stop, m: Multi Stop ]
        flights = flights.filter((flight) => {
          if (this.filterStops == 'n') {
            if (flight.Citypairs.length == 1)
                return flight.Citypairs[0].FlightSegment.length == 1
            else
                return (flight.Citypairs[0].FlightSegment.length == 1) && (flight.Citypairs[1].FlightSegment.length == 1)
          }

          else if (this.filterStops == 's') {
            if (flight.Citypairs.length == 1)
                return flight.Citypairs[0].FlightSegment.length == 2
            else
                return (flight.Citypairs[0].FlightSegment.length == 2 && flight.Citypairs[1].FlightSegment.length <= 2) || (flight.Citypairs[0].FlightSegment.length <= 2 && flight.Citypairs[1].FlightSegment.length == 2)
          }

          else if (this.filterStops == 'm') {
            if (flight.Citypairs.length == 1)
                return flight.Citypairs[0].FlightSegment.length > 2
            else
                return (flight.Citypairs[0].FlightSegment.length > 2) || (flight.Citypairs[1].FlightSegment.length > 2)
          }

          else {
            return true
          }

        })

        // Filter by departure time
        flights = flights.filter((flight) => {
          let d = new Date(flight.Citypairs[0].FlightSegment[0].DepartureDateTime)
          d.setYear(2000)
          d.setMonth(0)
          d.setDate(1)
          d.setSeconds(0)

          return (d >= this.minutesToDate(this.filterDepartureTime[0])) && (d <= this.minutesToDate(this.filterDepartureTime[1]))
        })

        // Filter by return time
        flights = flights.filter((flight) => {
          let d = new Date(flight.Citypairs[flight.Citypairs.length-1].FlightSegment[0].DepartureDateTime)
          d.setYear(2000)
          d.setMonth(0)
          d.setDate(1)
          d.setSeconds(0)

          return (d >= this.minutesToDate(this.filterReturnTime[0])) && (d <= this.minutesToDate(this.filterReturnTime[1]))
        })

        // Filter by departure duration
        flights = flights.filter((flight) => {
          let departureDuration = flight.Citypairs[0].Duration

          return (this.filterDepartureDuration[0] <= this.timeStringToMinutes(departureDuration)) && (this.filterDepartureDuration[1] >= this.timeStringToMinutes(departureDuration))
        })

        // Filter by return duration
        flights = flights.filter((flight) => {
          let returnDuration = flight.Citypairs[flight.Citypairs.length-1].Duration

          return (this.filterReturnDuration[0] <= this.timeStringToMinutes(returnDuration)) && (this.filterReturnDuration[1] >= this.timeStringToMinutes(returnDuration))
        })

        // Filter by connection points
        flights = flights.filter((flight) => {
          let flightConnectionPoints = []
          flight.Citypairs.forEach(citypair => {
            let pairConnectionPoints = []
            citypair.FlightSegment.forEach(flightsegment => {
              pairConnectionPoints.push(flightsegment.ArrivalLocationCode)
            });
            pairConnectionPoints.pop()
            Array.prototype.push.apply(flightConnectionPoints, pairConnectionPoints)
          });
          
          let hasOnlyAllowedConPoints = 1
          flightConnectionPoints.forEach(connectionPoint => {
            hasOnlyAllowedConPoints = hasOnlyAllowedConPoints * (this.filterConnectionPoints.includes(connectionPoint))
          });

          return hasOnlyAllowedConPoints;
        })

        // Filter by layover
        flights = flights.filter((flight) => {
          // let totalLayover = 0
          // flight.Citypairs.forEach(citypair => {
          //   citypair.FlightSegment.forEach(flightsegment => {
          //     totalLayover = totalLayover+this.timeStringToMinutes(flightsegment.LayoverTime)
          //   });
          // });

          let totalLayoverGo = 0
          flight.Citypairs[0].FlightSegment.forEach(flightsegment => {
            totalLayoverGo = totalLayoverGo+this.timeStringToMinutes(flightsegment.LayoverTime)
          });

          let totalLayoverReturn = 0

          if (flight.Citypairs.length > 1) {
            flight.Citypairs[1].FlightSegment.forEach(flightsegment => {
              totalLayoverReturn = totalLayoverReturn+this.timeStringToMinutes(flightsegment.LayoverTime)
            });
          }
          
          return (Math.max(totalLayoverGo, totalLayoverReturn) <= this.filterLayOver) || (this.filterLayOver == 0);
        })

        // Filter by baggage
        flights = flights.filter((flight) => {
          if (this.filterBaggage == 1)
            return this.getCheckedBagAllowance(flight) > 0
          else
            return true
        })

        return flights;
      },

      maxFlightDepartureDuration: function () {
        let durations = this.$store.state.flights.map(flight => {
          return this.timeStringToMinutes(flight.Citypairs[0].Duration)
        })
        return Math.max.apply(Math, durations) === -Infinity ? 0 : Math.max.apply(Math, durations)
      },

      minFlightDepartureDuration: function () {
        let durations = this.$store.state.flights.map(flight => {
          return this.timeStringToMinutes(flight.Citypairs[0].Duration)
        })
        return Math.min.apply(Math, durations) === Infinity ? 0 : Math.min.apply(Math, durations)
      },

      maxFlightReturnDuration: function () {
          let durations = this.$store.state.flights.map(flight => {
            return this.timeStringToMinutes(flight.Citypairs[flight.Citypairs.length-1].Duration)
          })
          return Math.max.apply(Math, durations) === -Infinity ? 0 : Math.max.apply(Math, durations)
      },

      minFlightReturnDuration: function () {
          let durations = this.$store.state.flights.map(flight => {
            return this.timeStringToMinutes(flight.Citypairs[flight.Citypairs.length-1].Duration)
          })
          return Math.min.apply(Math, durations) === Infinity ? 0 : Math.min.apply(Math, durations)
      }

    },

    mounted: function () {
      this.$root.$on('search-end', this.onSearchEnd)
    },

    methods: {
      setResultPage (page) {
        this.$store.state.currentPage = page
      },

      sortBy(type) {
        this.sort = type
      },

      clearFilters () {
        this.filterCarrier = ''
        this.filterStops = ''
        this.filterDepartureTime = [0, 1439]
        this.filterReturnTime = [0, 1439]
        this.filterDepartureDuration = [this.minFlightDepartureDuration, this.maxFlightDepartureDuration]
        this.filterReturnDuration = [this.minFlightReturnDuration, this.maxFlightReturnDuration]
        this.filterLayOver = 0
        this.filterConnectionPoints = this.getConnectionPoints().map(point => {return point.value})
        this.setResultPage(1)
      },

      filterByCarrier (code) {
        // this.clearFilters()
        this.setResultPage(1)
        this.filterCarrier = code
      },

      filterByStopCount (stops) {
        // stops [ n: Non Stop, s: One Stop, m: Multi Stop ]
        // this.clearFilters()
        this.setResultPage(1)
        this.filterStops = stops
      },

      filterByCarrierAndStopCount (code, stops) {
        // stops [ n: Non Stop, s: One Stop, m: Multi Stop ]
        this.clearFilters()
        this.filterCarrier = code
        this.filterStops = stops
      },

      filterByDepartTime (departTime) {
        this.setResultPage(1)
        this.filterDepartureTime = departTime
      },

      filterByReturnTime (returnTime) {
        this.setResultPage(1)
        this.filterReturnTime = returnTime
      },

      filterByDepartDuration (departDuration) {
        this.setResultPage(1)
        this.filterDepartureDuration = departDuration
      },

      filterByReturnDuration (returnDuration) {
        this.setResultPage(1)
        this.filterReturnDuration = returnDuration
      },

      filterByLayOver (minutes) {
        this.setResultPage(1)
        this.filterLayOver = minutes
      },

      filterByConnectionPoints (points) {
        this.setResultPage(1)
        this.filterConnectionPoints = points
      },

      filterByBaggage (allow) {
        this.setResultPage(1)
        this.filterBaggage = allow
      },

      onSearchEnd () {
        // setTimeout (() => {
        //   this.$root.$emit('search')
        //   if (this.$store.state.bookingFormFlight == null) // If booking dialog not opened - show alert
        //   {
        //     this.alertMsg = 'Your session has expired... Searching again.'
        //     this.showAlert = true
        //   }
        // }, this.flightsExpireTime*1000*60)
      },

      getCheckedBagAllowance(flight) {
        if (flight.IsBasicEconomyItin == true)
          return 0
        else
          if (['TK', 'QR', 'EY', 'SQ', 'CX', 'BR', 'EK'].includes(flight.ValidatingCarrierCode))
            return 2
          else
            return 1
      },

      minutesToDate (minutes) {
          let h = parseInt(minutes/60)
          let m = minutes-(h*60)
          let date = new Date(2000, 0, 1, h, m, 0)
          return date;
      },

      timeStringToMinutes (tstring) {
        if (tstring == null)
          return 0

        let time = tstring.split(' ')
        let d = 0
        let h = 0
        let m = 0
        if (time.length == 3) {
          d = parseInt(time[0].slice(0, -1))
          h = parseInt(time[1].slice(0, -1))
          m = parseInt(time[2].slice(0, -1))
        }
        else if (time.length == 2) {
          h = parseInt(time[0].slice(0, -1))
          m = parseInt(time[1].slice(0, -1))
        }
        else if (time.length == 1) {
          m = parseInt(time[0].slice(0, -1))
        }

        if (tstring == '')
          return 0
        else
          return (d*60*24) + (h*60) + m
      },

      isPropValuesEqual (subject, target, propNames) {
        return propNames.every(propName => subject[propName] === target[propName]);
      },

      getUniqueItemsByProperties (items, propNames) {
        const propNamesArray = Array.from(propNames);

        return items.filter((item, index, array) =>
          index === array.findIndex(foundItem => this.isPropValuesEqual(foundItem, item, propNamesArray))
        );
      },

      getConnectionPoints () {
        let points = []
        this.$store.state.flights.forEach(flight => {
          flight.Citypairs.forEach(citypair => {
            let subpoints = []
            citypair.FlightSegment.forEach(flightsegment => {
              subpoints.push({'text': flightsegment.DestinationAirportName, 'value': flightsegment.ArrivalLocationCode})
            })
            subpoints.pop()
            Array.prototype.push.apply(points, subpoints)
          })
        })

        let connectionPoints = {};

        connectionPoints = this.getUniqueItemsByProperties(points, ['value'])
        
        return connectionPoints
      },

      getAvgFlightCost (flight) { // Per person
        let adultPrice = 0
        let childrenPrice = 0
        flight.Fares.forEach(fare => {
            if (fare.PaxType == 'ADT')
                adultPrice = fare.Taxes+fare.BaseFare
            else if (fare.PaxType == 'CHD')
                childrenPrice = fare.Taxes+fare.BaseFare
        });
        let avgPrice = (adultPrice+childrenPrice)/(childrenPrice == 0 ? 1 : 2)
        return avgPrice.toFixed(2);
      },

    },

    components: {
      SearchWidget,
      ResultWidget,
      MatrixWidget,
      FilterWidget,
    },
  }
</script>
