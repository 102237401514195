<template>
  <div>
    <div :class='"bg "+destination'>
      <v-container fluid>
        <h1 class='white--text text-md-h2 text-h5 text-center mt-4 font-weight-light' style="margin: 1.5rem; text-shadow:0px 0px 13px black"><span :class="{'font-weight-regular': $vuetify.breakpoint.xs}">Cheap tickets to <span class='text-capitalize'>{{destination}}</span></span></h1>
        <search-widget style="max-width: 76rem; background-color: rgba(255, 255, 255,0.4); border: 1px solid rgba(255, 255, 255,0.2);" />
      </v-container>
    </div>
    <v-container class='px-md-16' style="display: flex; max-width: 65.5rem; margin: auto; flex-direction: column; justify-content: center;">

      <v-row justify='center'>
        <v-col cols='12'>
          <v-expansion-panels :value='0'>
            <v-expansion-panel
              v-for="(text, i) in texts[destination]"
              :key="i"
            >
              <v-expansion-panel-header>
                <h2 class='font-weight-regular text-body-1'>{{text.title}}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{text.content}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify='center' style='margin: 34px 0px;' class='mb-2 grey lighten-3 pt-5'>
        <v-row justify='center' no-gutters class='primary--text text-h5 font-weight-bold mb-2 text-center'><v-col cols='auto'>Sign-up Now for Exclusive Email-only Offers</v-col></v-row>
        <v-row justify='center' no-gutters class='mb-2 text--secondary text-center'><v-col cols='auto'>Be a privileged member to access discounted coupons, special offers and promotional deals.</v-col></v-row>
        <v-row justify='center' dense>
          <v-col cols='auto'>
            <v-text-field dense append='sdf' solo outlined placeholder='Enter your email'></v-text-field>
          </v-col>
          <v-col cols='auto'>
            <v-btn block height='40' color='primary'>Sign Up</v-btn>
          </v-col>
        </v-row>
      </v-row>

      <v-row class='mt-md-8' no-gutters justify='center' align='center' style='/*height: 200px;*/'>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-cancel</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/24.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Free cancellation within 24 hrs
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='isMobile() ? 48 : 64'>mdi-face-agent</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/support.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Exceptional Customer Service
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-currency-usd</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/savings.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Big Savings
              Hidden Fares
            </v-col>
          </v-row>
        </v-col>
        <v-col class='mb-5' cols='6' md='3'>
          <v-row align='center' no-gutters>
            <v-col cols='5' md='4'>
              <!-- <v-icon color='primary' :size='64'>mdi-seal</v-icon> -->
              <v-img contain height='50' src='@/assets/icons/offers.png' ></v-img>
            </v-col>
            <v-col cols='7' md='8' class='font-weight-bold text-caption text-md-subtitle-1'>
              Special Offers on Over 500 Airlines
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import SearchWidget from '../components/SearchWidget'

  export default {
    name: 'Landing',
    metaInfo() {
      return {
        meta: [
          { name: 'description', content: `Looking for the best fares available for flights to ${this.capitalize(this.destination)}? Book flights to ${this.capitalize(this.destination)} with Flights365 today!` }
        ]
      }
    },

    data: () => ({
      searchTerms: {
        'hawaii': 'hawaii',
        'bulgaria': 'bulgaria',
        'philippines': 'philippines,',
        'miami': 'miami',
        'india': 'india',
        'japan': 'japan',
        'iceland': 'iceland',
        'brazil': 'brazil',
        'italy': 'italy',
        'china': 'china',
        'australia': 'australia',
        'greece': 'greece',
      },
      texts: {
        'hawaii': [
          {
            'title': 'Flights to Hawaii for your dream vacation',
             'content': 'The exotic island of Hawaii is a destination well known around the world. Many people want to visit the beautiful scenery, to learn more about the culture of the natives and enjoy the warm tropical weather in an exclusive resort. Booking flights to Hawaii have many aspects that you must have covered before planning your vacation. Let us see some of the most important things you need to know before making reservations for flights to Hawaii.'
          },
          {
            'title': 'Flights to Hawaii – your departure destination',
             'content': 'There are many people around the World that want to book flights to Hawaii but not every international airport can give you that option. So, before you start looking for hotels and resorts on the island make sure to check which airlines sell tickets for flights to Hawaii. Booking a flight to Hawaii from the United States is quite easy, because you can choose from United Airlines, Southwest Airlines, Delta Airlines, Hawaiian Airlines, and many other companies. For the people of North America, buying cheap flights to Hawaii is quite easy, especially if you are open for travelling in short-notice or during the week. If you travel internationally then you can buy tickets for flights to Hawaii from Qatar Airways, British Airways, and many more. Using big international carriers for your flights to Hawaii usually means that you will have at least 1 layover in the main hub city of the airline. For example, flying with Qatar Airways to any city in the World, including flights to Hawaii, may include your first trip to be to Doha, Qatar, where you then board on the transcontinental plane. Some airlines do not provide flights to Hawaii that land on the islands so that might include a second layover somewhere in North America and the need to change airlines in order to complete your journey. The process of booking international flights to Hawaii might be quite difficult and stressful. This is why we always advice our customers to use the services of a professional traveling agency that has a lot of experience when it comes to international flying to every part of the World, including flights to Hawaii. '
          },
          {
            'title': 'When is the best time to book flights to Hawaii?',
            'content': 'The islands are definitely a destination that you can visit throughout the entire year, but customer and travel preferences show that there is such a thing as the best time to book flights to Hawaii. The best time to book flights to Hawaii can be separated into smaller categories – when is the cheapest to visit the islands, in which part of the year you will get the best weather, when to book flights to Hawaii if you want to go surfing or whale watching and the overall most popular time to visit the islands. '
          },
          {
            'title': 'When is cheapest to book flights to Hawaii?',
            'content': 'Usually, if you want to get the lowest prices you should choose flights to Hawaii or any other tourist destinations that are outside of the so called “tourist season”. But what are our options when we want to book flights to Hawaii - a destination where every month is tourist season. Our research shows that flights to Hawaii tend to be the most expensive around Christmas and New Year’s Eve (or the entire month of December) as well as in June. But luckily close to those very busy months you can manage to find cheap flights to Hawaii in January and September! If you are on a tight budget and manage to find cheap tickets for flights to Hawaii do not forget to check the prices of accommodation on the islands as well. Hotel rooms and restaurants, for example, tend to be a little bit less expensive in the fall period which includes the months from the end of August to the end of November, sometimes through mid-December. '
          },
          {
            'title': 'When to book flights to Hawaii to enjoy perfect weather?',
            'content': 'The islands are known for their long sunny days and beautiful beaches with crystal clear water. But do not forget that due to their situation there are extensive periods of rainfalls as well as hurricane seasons and storms. If you want to book flights to Hawaii and to make sure you avoid those periods, we suggest you pick a time between April through the end of August. But be aware that even if you book your flights to Hawaii in this period of time there is still a chance of experiencing some hurricanes, because hurricane season on the islands is from the beginning of June through the end of October. Even if the dates of your flights to Hawaii match with a hurricane or a storm, be sure that you will have a lot of entertainment and things to do when in Honolulu. '
          },
          {
            'title': 'When is the most popular time for booking flights to Hawaii?',
            'content': 'If you are not a fan of the crowd and want to have a peaceful vacation without many noises, then this is the period for flights to Hawaii that is most suitable for you. According to the Hawaiian Tourism Authority the majority of tourists book flights to Hawaii in July, December being the second-busiest month. For a relaxing vacation away from people we suggest you avoid booking flights to Hawaii in the last week of April and the first week of May because this period is very busy with international tourist, mainly from Asia. '
          },
          {
            'title': 'When is the best time to book flights to Hawaii for surfing and whale watching?',
            'content': 'If you want to experience your vacation to the fullest, we suggest you book flights to Hawaii in periods that are great for surfing and whale watching. The best time to book flights to Hawaii for surfing is during the winter. On the northern islands the waves are usually larger and provide the perfect conditions for surfers. If you want to go whale watching, make sure you book flights to Hawaii in the period from December to the end of April. The first trimester of the year is considered the peak of the season and if you book flights to Hawaii in that perod you will be able to see a lot of humpback whales swimming in the water!  If you are looking for cheap flights to Hawaii, do not hesitate to check our current offers. Give yourself the best vacation possible with flights to Hawaii from flight365.com! '
          }
        ],
        'bulgaria': [
          {
            'title': 'Flights to Bulgaria – visit the land of the roses',
            'content': 'Situated in Southeastern Europe, on the Balkan Peninsula, Bulgaria is a small country with rich culture, friendly people, and lots of things to do! Booking flights to Bulgaria is a great idea for people who want to explore the Balkans, visit the Black Sea, step foot on the highest peak on the Peninsula, try traditional cuisine, and experience the culture of the oldest European country. Before booking flights to Bulgaria, make sure you check some of the tips we have prepared for you!'
          },
          {
            'title': 'Flights to Bulgaria – when is the best time to visit?',
            'content': 'Flights to Bulgaria are a great choice throughout the year – it all depends on your personal preferences.'
          },
          {
            'title': 'Book flights to Bulgaria in the winter and enjoy the ski resorts',
            'content': 'Booking flights to Bulgaria is an excellent decision for all the people who enjoy winter sports. After landing from your flights to Bulgaria in Sofia – the capital of the country – make your way to Bansko, Borovets, or Pamporovo to enjoy the perfect weather and ski conditions. These are the 3 main ski resorts in the country, but there are many more which you can explore after landing from your flights to Bulgaria. For the people who like freeride, do not hesitate to book flights to Bulgaria – the country has a lot of mountains and high peaks, as well as trails to reach them. Make sure to pack well before leaving for your flights to Bulgaria, because the weather in the winter can be quite cold.'
          }, 
          {
            'title': 'Flights to Bulgaria in the spring? Visit the flower festivals!',
            'content': 'Flowers are quite common in the country and there are many festivals that you can visit if you book flights to Bulgaria in the spring. Choose your flights to Bulgaria to Burgas or Varna and visit the annual flower festivals right next to the sandy beaches of the Black Sea. For all tourists who book flights to Bulgaria in May, make sure to head to Kazanlak, where you can participate in the annual Festival of the Roses. The Bulgarian rose is very popular around the world, used mainly in fragrances. If you book flights to Bulgaria in May, you will be able to see the traditional gathering of the roses as well as the annual pageant – The Queen Rose. The festival includes traditional dances, garments, beverages, and foods that you definitely have to try! Before leaving home from your flights to Bulgaria make sure to buy a bottle of rose water or rose fragrance to remember your trip!'
          }, 
          {
            'title': 'Hot in the summer with flights to Bulgaria from June to August',
            'content': 'The summertime is the favorite time of the locals, as well as tourists who book flights to Bulgaria. Visiting the sandy beaches of the Black Sea, exploring the ancient city of Nessebar, visiting the island of Saint Anastasia – these are some of the things you can do after landing from your flights to Bulgaria. For your convenience, you can choose flights to Bulgaria to Burgas, situated in the southern part of the coast, or flights to Bulgaria to Varna – located in the north. The hottest month in the country is August so if you want a cooler vacation, make sure to book flights to Bulgaria in June or September.'
          }, 
          {
            'title': 'Booked flights to Bulgaria to Sofia? What to do in the capital?',
            'content': 'If you have decided to book flights to Bulgaria to the capital of the country, then you will have a lot to see and do. After landing from your flights to Bulgaria make sure to visit the St. Alexander Nevsky Cathedral, located in the center of the city. Completed over 100 years ago, the Cathedral is an impressive landmark in the city and was built as a memorial to the 200,000 Russian soldiers who fought for the freedom of Bulgaria back in 1877-1878. If you enjoy nature, after you land from your flights to Bulgaria, make sure you head out to Vitosha Mountain and see the unique natural phenomenon “stone rivers” (or also known as moraines). On your way to the top make sure to stop in some of the cabins and have a cup of hot tea with herbs collected from the mountain. Once you climb to the top you will see an impressive view over the capital.'
          }, 
          {
            'title': 'Before leaving home from your flights to Bulgaria, make sure to visit Plovdiv!',
            'content': 'After landing from your flights to Bulgaria and exploring the capital, head out to Plovdiv – an ancient town with rich history, incredible architecture, and warm weather. The old town in Plovdiv deserves to be visited before heading home from your flights to Bulgaria and make sure you stay at least a couple of days!'
          }, 
          {
            'title': 'Booked flights to Bulgaria to Varna? Visit the sea garden!',
            'content': 'Varna is also known as the maritime capital of the country and it’s the third largest city in Bulgaria. Booking flights to Bulgaria to Varna is quite easy – you can simply choose Varna as your end destination to your flights to Bulgaria or catch a domestic flight from Sofia. When you land in Varna from your flights to Bulgaria make sure to visit the Sea Garden, the Historical Park, the Stone Forest, as well as the Cathedral of the Assumption of the Virgin. Before leaving home from your flights to Bulgaria make sure to see a show at the Dolphinarium and learn more about the history of the country in the Archeological Museum.'
          }, 
          {
            'title': 'Looking for affordable flights to Bulgaria? Find them on flight365.com!',
            'content': 'If you want to explore this small, but quite unique and interesting country, make sure to book your flights to Bulgaria now! On our website you will find some of the best deals for flights to Bulgaria with minimum amounts of layovers. Choose your flights to Bulgaria from every part of the world with flight365.com! Feel free to contact us for any additional information regarding your flights to Bulgaria from flight365.com!'
          }
        ],
        'philippines': [
          {
            'title': 'Flights to Philippines – explore the land of 7000 islands',
            'content': 'The exotic islands of the Philippines are home to more than 110 million people. The Philippines cover more than 115,000 square miles of land filled with volcanoes, forests, endless sandy beaches, man made landmarks, and many more things that you will want to see once you land from your flights to the Philippines. If you have decided to book an exotic vacation for you and your family, make sure to check this important information before booking flights to the Philippines.'
          },
          {
            'title': 'Booking flights to the Philippines – when to travel to enjoy nice weather?',
            'content': 'If you are looking for a relaxing vacation on the beach, enjoying the sun and warm water, then you should book flights to the Philippines in the period from December to February. Because the islands are located in the southern hemisphere, the seasons are inverted. This means that booking flights to the Philippines in December will allow you to spend Christmas and celebrate New Year’s Eve on the beach in a bikini! If you book flights to the Philippines in the summer, you will enjoy temperatures between 75- and 90-degrees Fahrenheit. Be aware that the summertime is very busy on the islands and if you want to avoid the crowd, we suggest booking flights to the Philippines another time.'
          },
          {
            'title': 'When should you avoid booking flights to the Philippines?',
            'content': 'Due to the climate in the country, there are periods when booking flights to the Philippines might not be the best idea. March and April are particularly hot on the islands with temperatures past 95 degrees so you may want to skip booking flights to the Philippines in that period.'
          },
          {
            'title': 'When is the best time to book flights to the Philippines to avoid the crowds?',
            'content': 'If you are looking for a relaxing vacation without the fuss of tourists around you, make sure to book flights to the Philippines in May or October. Even though it is considered a wet period, the rain is not constant and rarely impacts the plans of people who have booked flights to the Philippines. The weather is still not that hot and you will be able to enjoy the sunny days, relaxing atmosphere, and lush, green scenery right after you set foot on the land after the flights to Philippines.'
          },
          {
            'title': 'Booked your flights to the Philippines? What should you plan in your schedule?',
            'content': 'There are many amazing things that you can do after landing from your flights to the Philippines. It all depends on your personal preferences and desires'
          },
          {
            'title': 'After landing from the flights to the Philippines, make sure to take your time and relax in Boracay.',
            'content': 'The island of Boracay is one of the most famous islands in the Philippines because of its white, sandy beaches. You will definitely be mesmerized by the beauty of Boracay even before you land from your flights to the Philippines. The island is around 4.5 miles long and at its narrowest part, it is only 1640 feet. Once you land from your flights to the Philippines make sure анд visit the main tourist attraction – the White Beach. It stretches almost 2.5 miles, and it is filled with restaurants, hotels, diving and surfing shops, boats, cruises, and many more. Right after you land from your flights to the Philippines make sure to head to the party on the island to see amazing fire shows, live music, and authentic, traditional dances. Boracay is the best place to relax, to have fun, experience the culture, and make great memories – which makes it the perfect destination for your flights to the Philippines!'
          },
          {
            'title': 'When booking flights to Philippines make sure to leave enough time to visit the Banaue Rice Terraces',
            'content': 'Nature is beautiful in every shape, form, and size, and the vast green rice terraces of the Philippines are definitely worth your visit after landing from your flights to Philippines. Many people consider the site to be the “Eighth Wonder of the World” – the terraces are more than 2,000 years old and are carved by people in the mountain of Lfugao. The terraces are still used today by the locals to grow vegetables and grains – so make sure you head out there after landing from your flights to Philippines.'
          },
          {
            'title': 'Before heading home from your flights to Philippines make sure to see the Mayon Volcano',
            'content': 'If you are a nature lover – then the island of Luzon is the perfect place to visit after landing from your flights to the Philippines. On the island you will see the active Mayon Volcano, also known by the locals as Mount Mayon. Before heading home from your flights to the Philippines make sure to visit the perfectly shaped and symmetrical volcano, that still nurtures the destructive powers of nature.'
          },
          {
            'title': 'Landed from your flights to the Philippines? See all the man made landmarks',
            'content': 'Man мade landmarks are quite remarkable and are worth your time before heading home from your flights to the Philippines. Make sure to visit the San Agustin Church, located in Manila – the capital of the Philippines and the most common destination for flights to the Philippines. Built during the Spanish Colonial Period, this remarkable Church is constructed out of stone and has survived more than 7 major earthquakes. After landing from the flights to the Philippines make your way to Fort Santiago in Manila – a citadel that was built in order to protect the city. Before heading home from your flights to the Philippines, make sure to see the Santo Nino Basilica as well – this is the oldest Roman Catholic Church in the country, built in 1965.'
          },
          {
            'title': 'Looking for flights to the Philippines? Choose the best tickets from flights365.com!',
            'content': 'Give yourself a well-deserved vacation and book flights to the Philippines from flight365.com! Book your next flights to the Philippines with ease on our website. Browse from everywhere around the world and book flights to the Philippines for your next holiday. On flights365.com you will be able to find cheap flights to the Philippines and many other destinations. Do not forget to take a look at our current deals for flights to the Philippines. Searching for the best flights to the Philippines – simply choose on flights365.com!'
          }
        ],
        'miami': [
          {
            'title': 'Flights to Miami for an amazing adventure',
            'content': 'Situated on Florida’s southern tip, the beautiful city of Miami lures visitors from all over the world with its unique blend of urban life and mesmerizingly beautiful beaches. If you choose to book flights to Miami, you will most definitely have a lot to do and see in the city – explore the vast cultural diversity, dive into the vibrant party life, and walk under the giant palm trees, leading you to the sandy beaches and the crystal-clear ocean water. Before you start looking for flights to Miami, here is some key information that will guide you through the process.'
          },
          {
            'title': 'Flights to Miami – domestic and international',
            'content': 'If you have decided to visit the pearl of Florida and are looking for flights to Miami, you have a lot of options before you. Domestic flights to Miami are operated by a lot of commercial airlines, such as Delta, American Airlines, Spirit Airlines, JetBlue Airways and many more. Most of the domestic flights to Miami are nonstop and the vast variety of carriers allows you to find the most suitable option for your preferences and budget. For international travelers who want to book flights to Miami, there are a lot of options as well, some of the well-known being Aeroflot, LOT, Qatar Airways, Air France, Turkish Airlines, KLM, Lufthansa, and many other international airlines that have flights to Miami. Depending on the departure destination, you can choose nonstop flights to Miami, or you will have at least 1 layover either in the hub city of the airline or somewhere in the United States. Usually, direct flights to Miami can be more expensive, but you will save a lot of time – in some cases a few hours, and in others – up to a day. Before you hop on your flights to Miami make sure that you know when the best time to visit the city is!'
          },
          {
            'title': 'When is the perfect time to book your flights to Miami?',
            'content': 'The city is a year-round destination with the lowest temperature of around mid-70 degrees Fahrenheit in the winter, but this definitely does not mean that there is no such thing as the best time to book your flights to Miami.'
          },
          {
            'title': 'When is the best time to book flights to Miami?',
            'content': 'If you want to enjoy the crowd, wild parties, and nightlife, we suggest booking your flights to Miami in the period between March and May. The infamous spring break gathers young crowds from around the country, enjoying the nice weather, crazy parties, and music festivals. This means that booking flights to Miami during that period can be quite difficult and expensive and the same goes for hotel and accommodations prices as well. If you decide to book flights to Miami between March and May you can visit the traditional Carnival Miami, the International Film Festival, as well as the ULTA music festival – all held in March. Miami Fashion Week, Miami Open, and Miami Beach Pride are also great reasons to book flights to Miami in the spring. For travelers who want to book flights to Miami in the spring, but are on a tighter budget, we strongly suggest you plan ahead. Use the services of a professional traveling agency and book your flights to Miami and arrange your accommodation upfront. This way you will be able to find affordable flights to Miami, as well as hotel fairs, save some cash to spend on the vacation and still have a great time!'
          },
          {
            'title': 'When is the time to avoid booking flights to Miami?',
            'content': 'The summer is particularly hot and humid, so we advise you to skip booking flights to Miami in the period between June and August. The temperature varies in the low 90s and June marks the beginning of hurricane season. If you book your flights to Miami in the summer there are high chances that you will have a rather rainy vacation, but on the upside – usually, hotel prices and tickets for flights to Miami are at their lowest. If you decide to book flights to Miami in the summer you can enjoy the American Black Film Festival, held in June, visit the Florida Supercon in July, or experience the Coconut Grove Food & Wine Festival, that is in August.'
          },
          {
            'title': 'When is the best time to book flights to Miami for a relaxing and quiet vacation?',
            'content': 'If you want to avoid the big crowd of partying students, then we suggest you book your flights to Miami in the winter between December and February. In this period temperatures vary in the mid-70s with low chances for rain. If you book flights to Miami in the winter, you can visit the White Party Week, the Orange Bowl, the Art Basel Miami – all in December, as well as the Coconut Grove Arts Festival, which is in February. When booking flights to Miami in the period between December and February we advise you to be careful when choosing accommodation in order to find good hotel rates.'
          },
          {
            'title': 'When are the cheapest flights to Miami?',
            'content': 'The Miami International Airport is quite a big hub that allows many airlines to have direct flights to Miami at reasonable prices. If you are looking for the cheapest flights to Miami, get tickets for the period between February and the middle of March or in the summer – June through September. For flyers who are open to depart on a short notice or during the weekdays, there are many affordable flights to Miami to choose from!'
          },
          {
            'title': 'Book your flights to Miami with flight365.com!',
            'content': 'The city is an existing combination of Latin culture, amazing weather, beautiful beaches, and vivid nightlife – these are only part of the reasons you need to book flights to Miami today! Enjoy yourself and have a well-deserved vacation with flights to Miami. Find the best offers for flights to Miami at flight365.com! Browse our website and choose the most suitable flights to Miami for your specific needs.'
          }
        ],
        'india': [
          {
            'title': 'Flights to India – experience the rich culture',
            'content': 'The biggest country in the southern parts of Asia, the seventh largest nation in the world by land and the second most populated – India has been a tourist destination for quite some time. The vibrant land of contrasts between tradition and the modern world is one of the reasons many people decide to book flights to India and experience the culture, cuisine, and natural and man-made landmarks. If you are interested in booking flights to India, be sure to read our tips so you can plan the best vacation!'
          },
          {
            'title': 'When is the best time to book flights to India?',
            'content': 'Picking up the best time to book flights to India depends greatly on the purpose of your journey.'
          },
          {
            'title': 'The best time to book flights to India to get amazing weather.',
            'content': 'If you are looking for a tourist destination, where you visit some of the greatest landmarks in the country, then the perfect time to book flights to India is the period from the beginning of October to the end of March. Many tourists choose flights to India at the end of March to attend the annual Holi Festival also known as The Vibrant Festival of Colors. November is a great time to book flights to India to attend one of the most popular Hindu festivals – Diwali. If you book your flights to India in November, you will be able to see the biggest Sikh festival as well – the Gurpurab.'
          },
          {
            'title': 'The best time to book flights to India to climb the Himalaya Mountain',
            'content': 'If you want to book your flights to India so you could climb the Himalaya Mountain, then you have to choose a warmer season when the temperatures allow for safe travel in the mountain – usually between March and May. Before booking flights to India for mountain climbing, make sure you check the conditions and inform yourself on the safety of the trails.'
          },
          {
            'title': 'Booking flights to India in the winter',
            'content': 'If you book your flights to India in December and January, you may experience cooler weather, with fog and rain. April and May can be great months for travel, but before booking your flights to India you have to be aware that you might experience high humidity, as well as thunderstorms.'
          },
          {
            'title': 'Booking flights to India in the summer',
            'content': 'If you want to book flights to India in the summer (from early June to late September, early October), then we suggest you visit the northern city of Ladakh, where the weather is quite nice and warm, while the rest of the country experiences monsoon season.'
          },
          {
            'title': 'One of the most common questions that people have before booking flights to India - are tourists safe?',
            'content': 'There are many people who want to book their flights to India and experience the rich culture and landmarks, but are afraid for their safety. This is why we always recommend booking your flights to India and organizing your stay with the help of professionals. Due to its large size India has a lot of areas which are safe for tourists and others that are quite dangerous. The professional tourist agency that arranges your flights to India and accommodation knows where it is safe for you and will make sure that your stay is pleasant and memorable. If you decide to book your own flights to India and organize a trip on your own, be sure to keep vigilant at all times since pickpocketing is quite common in the tourist areas.'
          },
          {
            'title': 'Booking your flights to India – what should you visit?',
            'content': 'India has a lot of tourist attractions that are worth your time, but due to the vast size of the country visiting all of them can be quite difficult. If you want to visit the Taj Mahal monument you should book your flights to India to the capital New Delhi, or Jaipur International Airport.'
          },
          {
            'title': 'Book your flights to India to New Delhi',
            'content': 'Booking your flights to India, New Delhi is a great option – in the capital you will be able to see the beautiful Lotus Temple, as well as the Hindu temples of Akshardham, Shiv Murti, and Shiva mandir Opp Chattarpur mandir. In your list of destinations to visit when in New Delhi, do not forget the Indian Gate – it is a gate, inspired by the Arc De Triomphe in France and commemorates the fallen Indian soldiers during the First World War. For the tourists that choose to book flights to India, New Delhi, do not forget to visit the Red Fort in the city. It is named after the red sandstones that are used in its construction. In order to visit the Holy City of Varanasi you may need additional domestic flights to India, to Kanpur and then make the trip to the city by bus or by car. When booking flights to India make sure to visit the northern city of Amritsar – it is quite the view, especially the Harmandir Sahib (or the Golden temple of Amritsar). In order to visit the temple, you should book your flights to India to New Delhi and then arrange additional transportation to the city.  In the western part of the country, there is the beautiful city of Jaisalmer, or The Golden City. It is a true oasis of the traditional architecture that stands above the sandy dunes of the Thar Desert. If you decide to book flights to India, you should definitely see it!'
          },
          {
            'title': 'Book your flights to India to Mumbai',
            'content': 'If you want to book flights to India and explore only one city without a lot of traveling – then Mumbai may be the right place for you. The city is situated on the Arabian Sea and has a lot of monuments to see. If you decide to book flights to India to Mumbai, do not forget to visit the nearby city of Goa – it is a “go-to” destination for all the people who wish to have a tropical vacation by the beach.'
          },
          {
            'title': 'Book your flights to India with flight365.com!',
            'content': 'If you want to visit India and have a pleasant experience in the rich Hindu culture, tradition, and cuisine, make sure to book your flights to India from flight365.com! We will not only provide you with great prices for flights to India but will also give you valuable information and advice about your vacation. Make sure you check our flights to India so you can find the best offer for your budget. Experience India with flights to India from flight365.com!'
          }
        ],
        'japan': [
          {
            'title': 'Flights to Japan – visit the country of the rising sun',
            'content': 'Japan is a well-known Asian country that deserves a position on every traveler’s list. A combination of rich culture, traditions, hospitality, and modern development and innovation. Today we will share with you why you need to book your flights to Japan today! Many people who have booked flights to Japan and have visited the islands share that the beauty of the country is like nothing that they have seen or experienced around the world. If you have already booked your flights to Japan or you are still planning, there are a lot of things to do in every city in the country! Book your flights to Japan to experience the tradition and rich culture Japanese culture is well-known around the world, and it will leave a mark in your mind once you have experienced it.'
          },
          {
            'title': 'After booking your flights to Japan, look for a ryokan',
            'content': 'If you have booked your flights to Japan, make sure to stay at least one night in a ryokan – this is a traditional Japanese inn where you sleep on tatami mats and the low tables and chairs on the ground are waiting for you to enjoy a cup of matcha green tea.'
          },
          {
            'title': 'Make sure that the destination of your flights to Japan has an onsen',
            'content': 'When booking your flights to Japan make sure that the destination city has a traditional onsen – or a hot spring bath. The onsens can be both indoor and outdoor and most of them are shared with other people. Even if you decide to book your flights to Japan to a major city, the train system will allow you to travel with ease to every corner of the main island.'
          },
          {
            'title': 'Try on wearing a kimono',
            'content': 'Make sure to leave some space in your suitcase before you leave for your flights to Japan because once you try on a kimono, you will definitely want to buy one for home! They are available around the country with many possibilities when it comes to colors, materials, and patterns varying somewhere from 10 000 to 200 000 yen. '
          },
          {
            'title': 'Before departing on your flights to Japan, make sure to plan these',
            'content': 'If you have already booked your flights to Japan do not forget to enjoy the traditional Japanese artform of Kabuki – all male theater that dates back to the Edo period, as well as watch a geisha dance. If we tell you, right before departing on your flights to Japan, that you will be able to see these classical beauties with distinctive white faces and colorful kimonos walking down the streets of Kyoto or Gion, you will probably think we are crazy, but once you land from your flights to Japan you will see them with your own eyes.'
          },
          {
            'title': 'When you land from your flights to Japan, just have some fun!',
            'content': 'If you want to book your flights to Japan and wish to have some fun, the country has a lot to offer.'
          },
          {
            'title': 'After landing from your flights to Japan, make sure you ride the Shinkansen',
            'content': 'You can take a ride on the Shinkansen (the bullet train) which is quite an unique experience. If you choose your flights to Japan in the winter or spring and travel from Tokyo to Kyoto, you might be able to see the beautiful snowcap of Mount Fuji. The trip is 280 miles long and takes only 2 hours and 15 minutes to complete (almost as fast as the domestic flights to Japan from Tokyo to Kyoto) so you definitely have the freedom to choose flights to Japan to any airport and city. Be aware that sometimes the tickets for the Shinkansen might be more expensive than domestic flights to Japan.'
          },
          {
            'title': 'Make the most with the time between your flights to Japan and home',
            'content': 'For people who book flights to Japan and want to have a memorable time, do not miss to go go-karting on the streets of Tokyo, Osaka, or Kyoto, enjoy a day at DisneySea in central Tokyo, enjoy the night views of the city from the Harukas 300 in Osaka or the Tokyo Metropolitan Government Building in Shinjuku, Tokyo. Before you book your flights to Japan make sure to research the karaoke bars and definitely do not miss out on hiring a private booth. Oh, and when you land from your flights to Japan, especially if you are in Tokyo, go and see the famous Shibuya Crossing – the world’s busiest pedestrian crossing.'
          },
          {
            'title': 'After you land from your flights to Japan make sure to enjoy the food',
            'content': 'Food in Japan is definitely an adventure for every tourist and a form of artform to the street cooks and chefs. After you land from your flights to Japan you will probably be starving, and we promise – any city in the country won’t disappoint.'
          },
          {
            'title': 'Book your flights to Japan just to try authentic ramen',
            'content': 'If you are a fan of soup, make sure you try the traditional ramen. There are many people who book their flights to Japan only to enjoy a delicious bowl of ramen noodles. You can find it in almost any restaurant as well as in vending machines.'
          },
          {
            'title': 'Make sure you try Takoyaki',
            'content': 'You cannot go back from your flights to Japan if you have not tried Takoyaki. The traditional baked battered balls are filled with delicious octopus and are quite commonly found on the street.'
          },
          {
            'title': 'Before your flights to Japan make sure to find out where to try traditional Okonomiyaki',
            'content': 'When you land from your flights to Japan make sure you try the traditional Okonomiyaki. The dish is quite popular in Osaka and Hiroshima, and it is a savory pancake made with flour, eggs, and cabbage, topped with meat, fish flakes, and thick sauce. And, of course, you cannot return home from your flights to Japan without trying the delicious sushi. Made with fresh fish, sticky rice, and crispy seaweed, you will crave Japanese sushi long after you have come home from your flights to Japan!'
          },
          {
            'title': 'Flights to Japan with flight365.com',
            'content': 'Indulge in the rich tradition, visit the shrines, wear a kimono, sleep in a traditional inn, learn the secrets of the tea ceremony – experience the Asian culture with flights to Japan. Allow yourself to have a great time and book your flights to Japan today! Browse the available flights to Japan on our website and start your adventure now! Choose the best flights to Japan with flight365.com!'
          }
        ],
        'iceland': [
          {
            'title': 'Flights to Iceland – visit the land of fire and ice',
            'content': 'Iceland is the perfect destination for tourists who want to experience marvelous nature, gaze at the mesmerizing Northern lights, see the wild creatures of the sea, and enjoy the open road. Booking flights to Iceland can be quite the adventure itself, so before you pack your bag make sure you read our mini guide for buying tickets for flights to Iceland!'
          },
          {
            'title': 'Booking flights to Iceland – everything you need to know',
            'content': 'If you have decided to take onto the adventure called Iceland, you will quickly find out that booking flights to Iceland can be quite difficult and pricey. The international airport in Keflavik is used for (almost) all international flights to Iceland, except the Reykjavik Airport, where you can land if you book flights to Iceland from Greenland or the Faroe Islands.'
          },
          {
            'title': 'Flights to Iceland from Europe',
            'content': 'There are not a lot of airlines that will provide nonstop flights to Iceland – some of them being Icelandair, jetBlue, United SWISS, Air Canada, Lufthansa, and others. If you want to catch nonstop flights to Iceland you have to depart from London, Paris, Manchester, Frankfurt, or a couple of other airports throughout Europe.'
          },
          {
            'title': 'Flights to Iceland from Northern America and other parts of the world',
            'content': 'Direct flights to Iceland from Northern America are quite common even though they can be quite pricey. Booking flights to Iceland from other parts of the world, for example Asia or Australia, might mean at least 1 or 2 layovers before arriving at your final destination. If you are looking for cheaper flights to Iceland, we suggest you travel outside the peak season, which we will discuss in the next paragraph.'
          },
          {
            'title': 'Booking flights to Iceland – when is the best time to travel?',
            'content': 'Due to the fact that the country is situated in the far north, you can expect quite fluctuating weather.'
          },
          {
            'title': 'When to book flights to Iceland for good weather?',
            'content': 'If you are looking for the most suitable weather for tourism, we suggest you book your flights to Iceland in the period between May and September. If you decide to get tickets for flights to Iceland in that particular period, you will be able to go whale watching, see the Northern Lights, as well as enjoy some of the summer festivals on the island. Before you book your flights to Iceland you need to know that the temperatures rarely pass over 68 degrees Fahrenheit. For tourists that book flights to Iceland in the summer, especially June and July, make sure to pack an eye mask in order to sleep. These are the months of the midnight sun, which sounds quite beautiful but can be quite hard on the adjusting body, that just landed from the flights to Iceland. If you book flights to Iceland in September, you might experience some snowfalls, but the island is quite beautiful with a white cap.'
          },
          {
            'title': 'Should you book flights to Iceland in the winter?',
            'content': 'The winter period can be quite harsh on the island and if you decide to book flights to Iceland in that period (between October and April) you have to be prepared for the grueling conditions. If you book flights to Iceland in the winter you should expect long nights, low temperatures varying around 14 degrees Fahrenheit (and sometimes coming to an extreme at -22 degrees Fahrenheit, particularly in the northern part of the island), blazing storms, and road closures, but the aurora borealis is a true spectacle of nature that will quickly make you forget the harsh conditions.'
          },
          {
            'title': 'When is the best time to book flights to Iceland to enjoy the Northern Lights?',
            'content': 'This natural spectacle is a true show that deserves our attention, and every person should see it at least once in their life. If you want to book flights to Iceland and get the best weather to watch the Northern Lights, we suggest you choose February and March or September and October.'
          },
          {
            'title': 'Booked your flights to Iceland? What is there to do on the island',
            'content': 'We are sure that no matter how much time you are going to have between your flights to Iceland and home – you will still have a lot to see again! The breathtaking scenery, the wildlife, frozen volcanoes, fiery gazers – these are just part of the things that are awaiting for you at the end of your flights to Iceland!'
          },
          {
            'title': 'Booked your flights to Iceland in the Spring? Don’t miss the whales!',
            'content': 'Whale watching is one of the most memorable things you can do when landing from your flights to Iceland. Around the island live over 20 species of whales and dolphins, where the minke and the humpback whales are the 2 most common. When you land from your flights to Iceland, make your way to Reykjavik, Akureyri, or Husavik in order to get on a boat and go see the whales!'
          },
          {
            'title': 'Before departing on your flights to Iceland make sure to check the availability at the Blue Lagoon',
            'content': 'When you land from your flights to Iceland you will definitely want to see this magical place – Iceland’s most famous SPA – the Blue Lagoon. It is quite famous for its healing silica, as well as the mud, warm water, mesmerizing surroundings, and billow steam stacks. Make sure you check if the Blue Lagoon is open before departing on your flights to Iceland, as well as pricing. The spa can be quite expensive, but don’t worry – the island has other similar experiences, that might just fit the budget!'
          },
          {
            'title': 'Looking for flights to Iceland? Then you are in the right place!',
            'content': 'Visit the magical island and explore the beautiful scenery with flights to Iceland from flight365.com. On our website you will be able to find cheap flights to Iceland that will suit your every need. Do not hesitate and shop with confidence – choose flights to Iceland from flight365.com!'
          }
        ],
        'brazil': [
          {
            'title': 'Flights to Brazil – experience the magic of the Carnival',
            'content': 'Brazil is well-known around the world for many reasons – some know that this is actually the longest country in the world; others have heard or have seen pictures of the Carnival; the fans of soccer know some iconic players like Pelé and Neymar. If you decide to book flights to Brazil, you will definitely have a lot to do – from a relaxing vacation on the beach to observing the wildlife in its natural habitat.'
          },
          {
            'title': 'When is the best time to book flights to Brazil?',
            'content': 'You may expect that the best time to book flights to Brazil might be in the Spring or Autumn, but this time that is not the case.'
          },
          {
            'title': 'Make sure you know the seasons before booking flights to Brazil',
            'content': 'Before we talk about the best time to travel to the country, we have to make it clear for all tourists booking flights to Brazil for the first time, that due to the fact that Brazil is South of the Equator, seasons are inverted – just like in Australia or Africa. If you want to book flights to Brazil in the summer, then you must be looking for available flights to Brazil from November to March (or the winter in the Northern hemisphere). If you book flights to Brazil during that period, you will enjoy the warm temperatures and low chances of rain. If we have to be perfectly honest, the country provides excellent conditions for travelers throughout the entire year so you will never miss it if you book flights to Brazil.'
          },
          {
            'title': 'When should you book flights to Brazil to see the Carnival?',
            'content': 'The annual Carnival is a staple in the culture of the country – and a great excuse to book your flights to Brazil right now! It is held in Rio De Janeiro in February or early March, and it is a great representation of all major dance schools in the country. The lavishing Carnival is a really exciting celebration of joy and dance, and you should definitely book flights to Brazil to see it asap.'
          },
          {
            'title': 'When is it best to book flights to Brazil to avoid the crowds?',
            'content': 'Even though the weather throughout the year is quite nice, the winter in the northern parts can be quite humid and rainy. We advise you to book flights to Brazil in September or October. You will definitely avoid major crowds of tourists, who booked flights to Brazil to spend the Christmas and New Year’s Holidays in the country. You will avoid the chilly weather in the Northern parts of the country and the damp season in the Pantanal. The Spring is a great time to book flights to Brazil because they are quite affordable, and accommodations are not that expensive as well.'
          },
          {
            'title': 'Where to book your flights to Brazil?',
            'content': 'The largest country in South America has more than 700 airports, but choosing the best one can be quite difficult. Here are the most used international airports in the country where you can land with ease from your flights to Brazil:'
          },
          {
            'title': 'Flights to Brazil and São Paulo',
            'content': 'The international airport in São Paulo is quite new and modern and it is the biggest airport in the country with a very large number of flights to Brazil landing there. According to official information from the airport, annually more than 26 million passengers choose São Paulo as their final destination for their flights to Brazil. If you decide to book your flights to Brazil to São Paulo, you must visit the Ibirapuera park – a spacious, green escape from the concrete jungle. Tourists that book flights to Brazil to São Paulo shouldn’t forget to see the Theatro Municipal de São Paulo, which is modeled after the Palais Garnier in Paris.'
          },
          {
            'title': 'Flights to Brazil to Rio De Janeiro',
            'content': 'Even though the São Paulo international airport is bigger, the Rio De Janeiro Galeao airport is the busiest in the country, with most tourists landing here from their flights to Brazil. Annually more than 17 million people choose flights to Brazil to one of the two terminals of Rio De Janeiro’s airport. As we already mentioned, if you book flights to Brazil to Rio De Janeiro, you will be able to attend the Carnival – Brazil’s biggest annual event. Make sure to prepare a bigger budget with flights to Brazil being quite expensive in that period, as well as accommodation and restaurants. After you land from your flights to Brazil in Rio De Janeiro, make sure to see the white-sanded beaches, explore the nearby tropical islands, dive deep in the vivid nightlife, filled with a lot of dance, music, and drumming. Before you come home from your flights to Brazil make sure you ride the cable car up Corcovado – the view of the city and the nearby landscape is incredible. Also, after your flights to Brazil, make sure to visit the symbol of Christianity – the statue of Christ the Redeemer, located near Rio De Janeiro.'
          },
          {
            'title': 'Flights to Brazil to Brasilia International Airport',
            'content': 'Located in the central part of the country, Brasilia international airport is in the federal district of Lago Sul, and it is a primary international airport for flights to Brazil as well as a domestic hub. If you decide to book flights to Brazil to Lago Sul, make sure you visit the Pontao do Lago Sul – a beautiful lakeside park with fine dining and breathtaking bridge views.'
          },
          {
            'title': 'Searching for flights to Brazil? Make sure you check flight365.com!',
            'content': 'If you are planning your next vacation and need affordable flights to Brazil, make sure to check our website. We provide travelers with a wide variety of options for flights to Brazil and many other destinations around the world. If you are looking for a trusted partner for your flights to Brazil, then look no more! Choose your next flights to Brazil with flights365.com!'
          }
        ],
        'italy': [
          {
            'title': 'Flights to Italy – visit the land of love, art, culture, and gelato!',
            'content': 'Italy is a very popular European destination for many tourists. As soon as you land from your flights to Italy you will be able to immerse yourself in the rich culture, generous hospitality, and amazing nature and landmarks of the country. The aroma of rich, freshly brewed coffee, and delicious pasta and pizza, is flowing through the air to make your days after landing from the flights to Italy even more amazing.'
          },
          {
            'title': 'When is the best time to book flights to Italy?',
            'content': 'Choosing the perfect time to book flights to Italy depends greatly on the things that you would like to do in the country.'
          },
          {
            'title': 'When is the best time to book flights to Italy to avoid the crowd?',
            'content': 'Tourist cities, like Rome, Venice, and Milan can be quite crowded in the summer and flights to Italy can be quite expensive. If you want to explore the country without bumping into other tourists, we suggest you book flights to Italy in the Spring (from March through May) and the Autumn (the months of September, October, and November). If you book flights to Italy during this period, you will enjoy the warm weather without the crowds of people.'
          },
          {
            'title': 'Book flights to Italy and enjoy a great winter vacation',
            'content': 'The winter has a lot to offer to people who choose to book flights to Italy in the months between December and February. In the northern parts of the country are situated some of the top-rated ski resorts in Europe. After landing from your flights to Italy, head to Cortina d’Ampezzo – a ski resort located in the Dolomites that hosted the Winter Olympic Games back in 1956. The resort is suitable for beginners, as well as professional skiers. Before coming home from the flights to Italy make sure to visit the Val Gardena resort as well. Located in proximity to Cortina d’Ampezzo, Val Gardena offers more intimate ski terrains, but also hosts the longest ski slope in South Tyrol, that is more than 6 miles long – you must definitely include it in the list of things to visit after landing from your flights to Italy. If you have booked cheap flights to Italy in the winter, but are not that big of a fan of skiing, you can always go sightseeing in Rome or Venice or enjoy a relaxing time in the southern parts of the country where the climate is mild and quite warm even in January.'
          },
          {
            'title': 'When to book flights to Italy to enjoy some of the most famous festivals?',
            'content': 'The Carnevale in Venice, the Battle of Oranges, the traditional Regatta, or the Infiorata Festival – these are just some of the annual celebrations that you can enjoy after landing from your flights to Italy. If you want to visit the traditional Carnevale, dating back to the 12 th century and the Pagan festival, then you should book flights to Italy in February and March. The Carnival is held in Venice so make sure to book flights to Italy to Venice or to Milan and then travel by bus or car. If you decide to book flights to Italy for the Carnival in February, make sure to visit Ivrea for the annual Battle of Oranges. The participants are divided into teams and hurl oranges at each other on the streets of the city. The beautiful floral festival is held in the Spring so make sure to book flights to Italy in May and June and visit the city of Noto, Bolsena, Brugnato, Spello or Orvieto.'
          },
          {
            'title': 'Booked flights to Italy? What to do during your vacation?',
            'content': 'The country is quite big and once you book your first flights to Italy, you will definitely want to see more of the culture, the people, and enjoy the delicious Mediterranean cuisine.'
          },
          {
            'title': 'Booked flights to Italy to Venice? Make sure to ride a Gondola!',
            'content': 'The narrow paths of the city of canals are best explored with a Gondola. Once you land from your flights to Italy make sure you enjoy the authentic experience – choose your preferred route and time of the day and just explore the magic of Venice. Before going home from your flights to Italy make sure to see the St. Mark’s Basilica and St. Mark’s Square. The Basilica is a sublime piece of architecture that was built back in 1092 and can be admired from tourists, who have booked flights to Italy, from the outside and on the inside.'
          },
          {
            'title': 'Booked flights to Italy to Rome? Step inside Ancient Rome',
            'content': 'Rome – the capital of the country – is a big tourist attraction and booking flights to Italy to Rome is quite easy. Once you land from your flights to Italy and make your way to the city, make sure you take your time and visit the Colosseum – standing almost intact from the times of the Roman Empire. After your flights to Italy make sure to see the Forum and Palatine Hill that will give you quite a panoramic view over the city.'
          },
          {
            'title': 'Booked your flights to Italy to Pisa? The Leaning tower awaits!',
            'content': 'The small town of Pisa is quite legendary and well-known around the world. Tourists who have booked flights to Italy often visit Pisa to see the Leaning tower or just pass by on their way to Florence. When you land from your flights to Italy and make your way to Pisa make sure to see the beautiful Cathedral of Saint Maria Assunta. The astonishing architecture of the building is often overshadowed by the Leaning Tower, but its beauty is a reason to take the next flights to Italy and visit Pisa. Right next to it is located the infamous Leaning Tower of Pisa – a structure known worldwide and a reason for many people to purchase tickets for flights to Italy.'
          },
          {
            'title': 'Looking for flights to Italy? Make sure to check flights365.com!',
            'content': 'If you are looking for affordable flights to Italy then look no further! On our website you will be able to find some of the best deals on flights to Italy. Plan your next European vacation and book flights to Italy from flight365.com with confidence!'
          }
        ],
        'china': [
          {
            'title': 'Flights to China – explore the lands of the red dragon',
            'content': 'Long after the writings of the adventurer Marco Polo, more than 7 centuries ago when he first stumbled with the Chinese people, today China remains the embodiment of the mysteriousness and exoticness of Asia. Even though flights to China are quite accessible for tourists, the world’s largest country keeps some of its secrets, which will be revealed to you once you step foot on the land after the long flights to China. Before you take on the adventure, make sure to read some of the most important things you need to know before booking flights to China!'
          },
          {
            'title': 'Flights to China – when is the best time to travel?',
            'content': 'Usually, every country has a specific time of the year when it is best to travel, but due to its enormous size, China can offer tourists things to see and do throughout the entire year.'
          },
          {
            'title': 'When to book flights to China for nice weather?',
            'content': 'If you want the perfect conditions for exploring and walking around the country, make sure to book flights to China in the spring. The temperatures are quite nice and in most of the country it is quite dry. If you want to visit the western part of the country make sure to book flights to China in the period between April and October. Tourists that book flights to China in that time window will be able to see the mesmerizing blossoming flowers and trees and experience the traditional local bazaars.'
          },
          {
            'title': 'Should you book flights to China in July?',
            'content': 'When booking flights to China be aware that July is known as the hottest and most humid month throughout most of the country. If you have booked flights to China to Shanghai, Beijing or Xi’an expect temperatures between 75- and 90-degrees Fahrenheit. Also, tourists with flights to China in July can expect heavy rains.  If you still decide to book flights to China in July, make sure to pack a lot of breathable and quick-drying clothes.'
          },
          {
            'title': 'Booked your flights to China – what are the best places to visit?',
            'content': 'As we already mentioned, the enormous land of the country offers a variety of activities – from sightseeing to mountain climbing. If you have booked your flights to China but you are still not sure what to do – here are some ideas!'
          },
          {
            'title': 'Bought tickets for flights to China to Beijing? Explore the authentic Chinese culture',
            'content': 'The capital of the country – Beijing – is a great destination for your flights to China. Beijing has been the capital of PRC (the People’s Republic of China) for the past over 70 years and since its proclamation back in 1949 the city has changed a lot. Booking flights to China to Beijing will allow you to explore the history of the city that dates back almost 3 millennia. The amazing mix of modern architecture and ancient structures will leave a mark in your mind long after you have come back home from your flights to China. If you have decided to book flights to China and to Beijing in particular, make sure you visit the grand complex of the Forbidden City – China’s largest and most important building. The complex’s building process started during the Yuan Dynasty and was built for almost 200 years. When you land from your flights to China make sure you take your time to explore the 818,000 square feet Forbidden palace and all its ceremonial and administrative buildings. Before coming home from your flights to China make sure to visit the Summer Palace in Beijing. It is set on more than 700 acres of beautiful parkland, and it is one of the most visited attractions by tourists who book flights to China. The palace itself was built in 1153, but later in the 14th century the lake was added in.'
          },
          {
            'title': 'Booked flights to China to Shanghai? Make sure to take your time to explore!',
            'content': 'Even though Beijing is the capital of the country, Shanghai is the biggest city and main tourist attraction from tourists who book flights to China. The city is located on China\'s central coast, and it is well-known around the world for many reasons. If you decide to book flights to China to Shanghai, make sure you take your time and really explore the city. Right after you land from your flights to China and leave your luggage in the hotel, make sure you visit the Shanghai Tower and the Observation Deck, located on the 118th and 119th floor of the building. The overall height of the Shanghai Tower is 632 meters or more than 2000 feet in  height (visible while you are on the plane on your flights to China), with the Observation deck being 546 meters or 1791 feet above the ground. The marvelous panoramic view over the city is something every tourist who booked flights to China must see! When you book flights to China to Shanghai you will have the opportunity to stroll down the beautiful riverside, also known as the Bund or Wàitan. It is located along the Huangpu Jiang River and the tranquility of this place will make you forget that you just landed from your flights to China – the world’s most populated country with more than 1.4 billion residents.'
          },
          {
            'title': 'Booked flights to China – make sure to see some of the natural landmarks',
            'content': 'If you want to book flights to China to see some of the marvelous natural and mand-made landmarks in the country, like the Great Wall of China, the Terracotta Army, the Zhangjiajie National Forest Park, and many more, make sure you purchase tickets for flights to China to a nearby destination and prepare a route with specific stops along the way for your travels.'
          },
          {
            'title': 'Looking for flights to China? Browse flight365.com!',
            'content': 'Flights to China can be quite pricey and difficult to book but with flight365.com the process is easier than ever. Browse our website to find the flights to China, suitable for your needs. Flights to China to Beijing, Shanghai, Hong Kong, and many other destinations. Book your flights to China today with flight365.com!'
          }
        ],
        'australia': [
          {
            'title': 'Flights to Australia – visit the "Land Down Under"',
            'content': 'The largest country in Oceania and the sixth biggest country in the world – Australia is a magical land of vast plains, exotic animals, hospitality, and culture. If you decide to book flights to Australia, you will definitely have a lot to do – from sightseeing, exploring the nearby islands, to simply relaxing on the sandy beaches with a cocktail in your hand, surrounded by friendly kangaroos. Before you book your flights to Australia, here are some of our tips for your trip to the land down under.'
          },
          {
            'title': 'Flights to Australia – when is the best time to visit?',
            'content': 'Before you even consider booking flights to Australia you have to make sure that you know and understand the climate in the country, as well as its seasons. Due to its geographical position south from the Equator, Australia has an inverted season from the ones that we are used to in the Northern parts of the Earth.'
          },
          {
            'title': 'Before booking flights to Australia, make sure you check the season',
            'content': 'For instance, if you want to book flights to Australia in the summer, then you need to look for tickets in the months from December through February. For many, spending Christmas and the New Year’s Holidays on the beach in a bikini is quite unusual, but it is pretty natural for the natives as well as the people who book flights to Australia regularly. The following season of Autumn aligns with the Spring in the Northern hemisphere – from March to May – a great time to book flights to Australia. Usually, the temperatures in the Autumn are between the low 60s and mid 95s so you will definitely have great conditions for exploring – which makes it a perfect time to purchase your flights to Australia. The Winter period is from June to August with temperatures varying from the low 50s in the northern parts to almost 90 degrees Fahrenheit in the south – so plan your flights to Australia accordingly. The Spring is from September to November and the daily temperatures start to rise from the south to the north and planning a vacation and flights to Australia in this period would be a great decision.'
          },
          {
            'title': 'When is the best time to book flights to Australia to avoid crowds?',
            'content': 'Spending the Christmas holidays on the beach is becoming quite popular and flights to Australia in their summer season are quite pricey. Adding the hotel accommodation to the price of your flights to Australia equals a quite pricey vacation. And that is not the only downside to booking flights to Australia in the summer – the crowds of tourists in the Australian summer are quite overwhelming and loud. If you want a relaxing time in the country, we suggest booking flights to Australia in the low season – the winter, between June and August. There is quite a lot to do if you choose winter flights to Australia – enjoy the sunshine, drink wine, admire the vineyards, and many more!'
          },
          {
            'title': 'Booked your flights to Australia – what should you plan to do?',
            'content': 'You have already picked the dates for your flights to Australia and now is time to choose what to include in your plan for the vacation. Here are some of our top picks what to do after you land from your flights to Australia:'
          },
          {
            'title': 'Booked your flights to Australia to Perth? Visit the Cape Le Grand National Park',
            'content': 'Visiting the Cape Le Grand National Park is a must in order to get the true Australian experience. You simply cannot go back home from your flights to Australia without sunbathing and taking a picture with a kangaroo. The white sandy beaches, the turquoise water create this idyllic place to relax after the long flights to Australia.'
          },
          {
            'title': 'Booked your flights to Australia to Brisbane? Visit the Whitsunday Islands',
            'content': 'Located in the north-east part of the country, the Whitsunday Islands are a great place for sailing due to the perfect wind conditions, as well as the calm sea. After you land from your flights to Australia and arrive at Airlie Beach you will be able to explore the archipelago, consisting of 74 islands, 69 of which are still uninhabited.'
          },
          {
            'title': 'Booked your flights to Australia to Melbourne? Go sightseeing',
            'content': 'After getting off from your flights to Australia in Melbourne you will definitely have a lot to see and do! There are a lot of one-day trips around the city to enjoy the vineyards and have a glass of true Australian wine. After landing from your flights to Australia make sure to explore Melbourne’s laneways that hide great small bars, cafés and restaurants with typical cuisine and authentic experience. Before going home from your flights to Australia make sure to visit the Royal Botanic Gardens and see the National Gallery of Victoria.'
          },
          {
            'title': 'Booked your flights to Australia to Sydney? Here are the best things to see',
            'content': 'Sydney is one of the most popular Australian cities and main destination for flights to Australia. The iconic Sydney Opera House, the amazing Bondi Beach, the Royal Botanical Garden, as well as the Taronga Zoo – these are only part of the things to do after you arrive from your flights to Australia. For the people who enjoy a more thrilling and exciting experience, before coming home from your flights to Australia make sure to not only see the famous Harbor Bridge, but to climb it. As you go further up right in front of you will be revealed a stunning view of the city – a memory that will stay in your mind long after you come back from your flights to Australia!'
          },
          {
            'title': 'Book your flights to Australia today with flight365.com!',
            'content': 'Book your flights to Australia today and explore the magical land down under. Enjoy the wildlife, see the Great Barrier Reef, go sightseeing, or just relax on the beach – just book your flights to Australia and choose what suits you best. On our website you will find a variety of options for flights to Australia – search and find your best one! You need flights to Australia – choose them from flight365.com!'
          }
        ],
        'greece': [
          {
            'title': 'Flights to Greece – indulge yourself in the rich culture of the Greeks',
            'content': 'Situated in the southern parts of Europe, with access to the Mediterranean, Aegean, and Ionian Sea, Greece is a very popular destination for international tourists. Combination of rich history, dating back to the 8th century BC, hospitality of the natives, and the stunning scenery – if you still haven’t visited the country, you need to book your flights to Greece immediately! Before you purchase your tickets for flights to Greece, here is some important information that will definitely come in handy!'
          },
          {
            'title': 'Flights to Greece – where to land?',
            'content': 'Even though the country is quite big, there are a couple of airports that you can choose from when booking flights to Greece – Athens and Thessaloniki. If you choose your flights to Greece to Thessaloniki International Airport, situated in the northern part of the country, you will have access to the main part of the country, as well as many tourist destinations on the Aegean Sea. If you choose to land from your flights to Greece in Athens – the capital of the country – you can easily reach the Peloponnese and the islands of the Aegean Sea in the south. If you want to visit the island of Crete you can choose flights to Greece to Chania Souda International Airport, located in the western parts of the island, or flights to Greece to Heraklion Airport, situated roughly at the center of the island. For tourists who want to visit the Cyclades cluster of islands, you can choose flights to Greece to Athens and take a connecting flight from Athens to Santorini. If you want to experience the true beauty of the Cyclades, we suggest you book your flights to Greece to Athens and take the ferry from Piraeus.'
          },
          {
            'title': 'When is the best time to book flights to Greece?',
            'content': 'Choosing the perfect time to book flights to Greece depends mainly on your personal preferences.'
          },
          {
            'title': 'The best time to book flights to Greece for perfect weather',
            'content': 'If you want a sunny holiday on the beach, then we suggest you book your flights to Greece in the Summer – from July to the end of August. If you book your flights to Greece in the summer you can enjoy temperatures varying from 70 to 90 degrees Fahrenheit, long days, warm water, and perfect weather almost every day. This is also the busiest season in the country and if you decide to book your flights to Greece in that period you should be prepared for the crowds of tourists. Some of the most popular destinations in the summer are Santorini, Mykonos, and the Rhodes islands. But that does not mean that if you book your flights to Greece in the summer you will definitely not be able to have a relaxing vacation – the country can offer you miles and miles of sandy beaches, crystal clear water, and great service. You will definitely find a peaceful place to relax after your flights to Greece.'
          },
          {
            'title': 'The best time to book flights to Greece to avoid the crowds',
            'content': 'As we already mentioned, summer is the best time to book flights to Greece, but if you want to avoid the crowds and really relax on the beach or in the mountains – then Spring and Autumn are the perfect time to book flights to Greece. Spring (from April to June) is quite warm with temperatures varying from 50 to 90 degrees Fahrenheit from north to south. If you want to take a swim in the sea, then we suggest you book your flights to Greece in the Autumn (from September to the end of October). The weather is great with temperatures from 60 to 85 degrees and the water is still warm from the hot summer days – the perfect time to book flights to Greece!'
          },
          {
            'title': 'When is the cheapest to book flights to Greece?',
            'content': 'The cheapest time to book flights to Greece is during the winter. If you want to book flights to Greece to spend the Christmas and New Year’s Holidays in the country, we suggest you pick your flights to Greece to Athens and choose one of the bigger islands – like Crete.'
          },
          {
            'title': 'Booked your flights to Greece? What are the best things to do!',
            'content': 'The rich culture of the Greek civilization is quite fascinating and there are a lot of things you should see before going home from your flights to Greece!'
          },
          {
            'title': 'Booked your flights to Greece to Athens? Take your time to explore the capital',
            'content': 'The capital of Greece – Athens – holds a lot of attractions and sights that you must see when you land from your flights to Greece. Dominating over the view of the city is the Ancient Acropolis (you will probably see it when landing with your flights to Greece)! Constructed with marble, this site is quite famous for its historic value as well as the statue of the Greek goddess Athena – the goddess of wisdom, as well as the adored patroness of the capital city. After landing from your flights to Greece and visiting the Acropolis, do not forget to see the Acropolis Museum as well. The museum exposition includes some incredible statues as well as a terrace that reveals a breathtaking view on the Acropolis. Before going home from your flights to Athens, do not miss the Hephaestus Temple, located in the Thissio neighborhood. After landing from your flights to Greece and exploring the ancient city of Athens make sure you head south and see the amazing tourist destinations on the sea – the islands of Crete, Zakinthos, Kefalonia, and many more!'
          },
          {
            'title': 'Looking for flights to Greece! Check them out at flight365.com!',
            'content': 'If you are looking to book your flights to Greece for your next vacation, make sure you check our current offers on flight365.com! For tourists on a tight budget, we suggest you book your flights to Greece at least 3 weeks prior to your departure. No matter the time or the place – find cheap flights to Greece on our website!'
          }
        ]
      }
    }),

    created: function () {
      document.title = "Flights to "+this.capitalize(this.destination)+" | Flights365";
      window.fbq('track', 'ViewContent', {content_name: this.destination});
    },

    mounted: function () {
      this.$store.state.expandSearch = true
      this.quickSearchTo(this.searchTerms[this.destination])
    },

    watch: {
      'destination': function () {
        document.title = "Flights to "+this.capitalize(this.destination)+" | Flights365";
        this.quickSearchTo(this.searchTerms[this.destination])
      }
    },

    components: {
      SearchWidget,
    },

    computed: {
      destination () {
        // flights-to-bulgaria
        if (this.$route.params.destination.split('flights-to-').length > 1)
          return this.$route.params.destination.split('flights-to-')[1]
        else
          return this.$route.params.destination
      }
    },

    methods: {
      isMobile() {
        return this.$vuetify.breakpoint.mobile
      },

      capitalize(text) {
        return (text[0].toUpperCase()+text.slice(1))
      },

      quickSearchTo(destination) {
        this.$store.state.toSearch = ''
        this.$store.state.toLocations = []
        this.$store.state.to = ''
        document.querySelector('.bg').click() // HACK
        setTimeout(() => {
          document.activeElement.blur()
          document.querySelector('#locationInputTo').focus()
          window.scrollTo(0, 0)
          this.$store.state.toSearch = destination
          document.querySelector('#locationInputTo').click()
        }, 100)
      }
    }
  }
</script>
<style>
.bg {
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  padding: 20px 1rem;
}
@media only screen and (min-width: 1265px) {
  .bg {
    background-position-y: center;
    padding: 140px 1rem;
  }
}
.hawaii {
background-image: url('~@/assets/hawaii.jpg');
}
.bulgaria {
background-image: url('~@/assets/bulgaria.jpg');
}
.croatia {
background-image: url('~@/assets/croatia.jpg');
}
.florida {
background-image: url('~@/assets/florida.jpg');
}
.poland {
background-image: url('~@/assets/poland.jpg');
}
.israel {
background-image: url('~@/assets/israel.jpg');
}
.philippines {
background-image: url('~@/assets/philippines.jpg');
}
.miami {
background-image: url('~@/assets/miami.jpg');
}
.india {
background-image: url('~@/assets/india.jpg');
}
.japan {
background-image: url('~@/assets/japan.jpg');
}
.iceland {
background-image: url('~@/assets/iceland.jpg');
}
.brazil {
background-image: url('~@/assets/brazil.jpg');
}
.italy {
background-image: url('~@/assets/italy.jpg');
}
.china {
background-image: url('~@/assets/china.jpg');
}
.australia {
background-image: url('~@/assets/australia.jpg');
}
.greece {
background-image: url('~@/assets/greece.jpg');
}
</style>
