import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    expandSearch: true,
    flights: [],
    altFlights: [],
    searching: false,
    hasSearched: false,
    currentPage: 1,
    flightType: 'round',
    from: '',
    to: '',
    fromLocations: [
        
    ],
    toLocations: [

    ],
    fromSearch: '',
    toSearch: '',
    departureDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    returnDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    adults: 1,
    children: 0,
    infants: 0,
    flightClass: 'e',
    bookingFormFlight: null,
    additionalDestinations: [
      {
        "DepartureTime": (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        "DepartureLocationCode": "",
        "ArrivalLocationCode": "",
        "CabinClass": "E",
        "searchInputFrom": "",
        "searchInputTo": "",
        "fromLocations": [],
        "toLocations": []
      }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
