var usStates = [
  { text: '', value: 'NA' },
  { text: 'AL', value: 'AL' },
  { text: 'AK', value: 'AK' },
  { text: 'AS', value: 'AS' },
  { text: 'AZ', value: 'AZ' },
  { text: 'AR', value: 'AR' },
  { text: 'CA', value: 'CA' },
  { text: 'CO', value: 'CO' },
  { text: 'CT', value: 'CT' },
  { text: 'DE', value: 'DE' },
  { text: 'DC', value: 'DC' },
  { text: 'FM', value: 'FM' },
  { text: 'FL', value: 'FL' },
  { text: 'GA', value: 'GA' },
  { text: 'GU', value: 'GU' },
  { text: 'HI', value: 'HI' },
  { text: 'ID', value: 'ID' },
  { text: 'IL', value: 'IL' },
  { text: 'IN', value: 'IN' },
  { text: 'IA', value: 'IA' },
  { text: 'KS', value: 'KS' },
  { text: 'KY', value: 'KY' },
  { text: 'LA', value: 'LA' },
  { text: 'ME', value: 'ME' },
  { text: 'MH', value: 'MH' },
  { text: 'MD', value: 'MD' },
  { text: 'MA', value: 'MA' },
  { text: 'MI', value: 'MI' },
  { text: 'MN', value: 'MN' },
  { text: 'MS', value: 'MS' },
  { text: 'MO', value: 'MO' },
  { text: 'MT', value: 'MT' },
  { text: 'NE', value: 'NE' },
  { text: 'NV', value: 'NV' },
  { text: 'NH', value: 'NH' },
  { text: 'NJ', value: 'NJ' },
  { text: 'NM', value: 'NM' },
  { text: 'NY', value: 'NY' },
  { text: 'NC', value: 'NC' },
  { text: 'ND', value: 'ND' },
  { text: 'MP', value: 'MP' },
  { text: 'OH', value: 'OH' },
  { text: 'OK', value: 'OK' },
  { text: 'OR', value: 'OR' },
  { text: 'PW', value: 'PW' },
  { text: 'PA', value: 'PA' },
  { text: 'PR', value: 'PR' },
  { text: 'RI', value: 'RI' },
  { text: 'SC', value: 'SC' },
  { text: 'SD', value: 'SD' },
  { text: 'TN', value: 'TN' },
  { text: 'TX', value: 'TX' },
  { text: 'UT', value: 'UT' },
  { text: 'VT', value: 'VT' },
  { text: 'VI', value: 'VI' },
  { text: 'VA', value: 'VA' },
  { text: 'WA', value: 'WA' },
  { text: 'WV', value: 'WV' },
  { text: 'WI', value: 'WI' },
  { text: 'WY', value: 'WY' }
]
// var usStates = [
//     {
//         "text": "No state",
//         "value": "NA"
//     },
//     {
//         "text": "Alabama",
//         "value": "AL"
//     },
//     {
//         "text": "Alaska",
//         "value": "AK"
//     },
//     {
//         "text": "American Samoa",
//         "value": "AS"
//     },
//     {
//         "text": "Arizona",
//         "value": "AZ"
//     },
//     {
//         "text": "Arkansas",
//         "value": "AR"
//     },
//     {
//         "text": "California",
//         "value": "CA"
//     },
//     {
//         "text": "Colorado",
//         "value": "CO"
//     },
//     {
//         "text": "Connecticut",
//         "value": "CT"
//     },
//     {
//         "text": "Delaware",
//         "value": "DE"
//     },
//     {
//         "text": "District Of Columbia",
//         "value": "DC"
//     },
//     {
//         "text": "Federated States Of Micronesia",
//         "value": "FM"
//     },
//     {
//         "text": "Florida",
//         "value": "FL"
//     },
//     {
//         "text": "Georgia",
//         "value": "GA"
//     },
//     {
//         "text": "Guam",
//         "value": "GU"
//     },
//     {
//         "text": "Hawaii",
//         "value": "HI"
//     },
//     {
//         "text": "Idaho",
//         "value": "ID"
//     },
//     {
//         "text": "Illinois",
//         "value": "IL"
//     },
//     {
//         "text": "Indiana",
//         "value": "IN"
//     },
//     {
//         "text": "Iowa",
//         "value": "IA"
//     },
//     {
//         "text": "Kansas",
//         "value": "KS"
//     },
//     {
//         "text": "Kentucky",
//         "value": "KY"
//     },
//     {
//         "text": "Louisiana",
//         "value": "LA"
//     },
//     {
//         "text": "Maine",
//         "value": "ME"
//     },
//     {
//         "text": "Marshall Islands",
//         "value": "MH"
//     },
//     {
//         "text": "Maryland",
//         "value": "MD"
//     },
//     {
//         "text": "Massachusetts",
//         "value": "MA"
//     },
//     {
//         "text": "Michigan",
//         "value": "MI"
//     },
//     {
//         "text": "Minnesota",
//         "value": "MN"
//     },
//     {
//         "text": "Mississippi",
//         "value": "MS"
//     },
//     {
//         "text": "Missouri",
//         "value": "MO"
//     },
//     {
//         "text": "Montana",
//         "value": "MT"
//     },
//     {
//         "text": "Nebraska",
//         "value": "NE"
//     },
//     {
//         "text": "Nevada",
//         "value": "NV"
//     },
//     {
//         "text": "New Hampshire",
//         "value": "NH"
//     },
//     {
//         "text": "New Jersey",
//         "value": "NJ"
//     },
//     {
//         "text": "New Mexico",
//         "value": "NM"
//     },
//     {
//         "text": "New York",
//         "value": "NY"
//     },
//     {
//         "text": "North Carolina",
//         "value": "NC"
//     },
//     {
//         "text": "North Dakota",
//         "value": "ND"
//     },
//     {
//         "text": "Northern Mariana Islands",
//         "value": "MP"
//     },
//     {
//         "text": "Ohio",
//         "value": "OH"
//     },
//     {
//         "text": "Oklahoma",
//         "value": "OK"
//     },
//     {
//         "text": "Oregon",
//         "value": "OR"
//     },
//     {
//         "text": "Palau",
//         "value": "PW"
//     },
//     {
//         "text": "Pennsylvania",
//         "value": "PA"
//     },
//     {
//         "text": "Puerto Rico",
//         "value": "PR"
//     },
//     {
//         "text": "Rhode Island",
//         "value": "RI"
//     },
//     {
//         "text": "South Carolina",
//         "value": "SC"
//     },
//     {
//         "text": "South Dakota",
//         "value": "SD"
//     },
//     {
//         "text": "Tennessee",
//         "value": "TN"
//     },
//     {
//         "text": "Texas",
//         "value": "TX"
//     },
//     {
//         "text": "Utah",
//         "value": "UT"
//     },
//     {
//         "text": "Vermont",
//         "value": "VT"
//     },
//     {
//         "text": "Virgin Islands",
//         "value": "VI"
//     },
//     {
//         "text": "Virginia",
//         "value": "VA"
//     },
//     {
//         "text": "Washington",
//         "value": "WA"
//     },
//     {
//         "text": "West Virginia",
//         "value": "WV"
//     },
//     {
//         "text": "Wisconsin",
//         "value": "WI"
//     },
//     {
//         "text": "Wyoming",
//         "value": "WY"
//     }
// ]

var caStates = [
    {
        "text": "No state",
        "value": "NA"
    },
    {
        "value": "AB",
        "text": "Alberta"
    },
    {
        "value": "BC",
        "text": "British Columbia"
    },
    {
        "value": "MB",
        "text": "Manitoba",
    },
    {
        "value": "NB",
        "text": "New Brunswick",
    },
    {
        "value": "NL",
        "text": "Newfoundland and Labrador",
    },
    {
        "value": "NS",
        "text": "Nova Scotia",
    },
    {
        "value": "ON",
        "text": "Ontario",
    },
    {
        "value": "PE",
        "text": "Prince Edward Island",
    },
    {
        "value": "QC",
        "text": "Quebec",
    },
    {
        "value": "SK",
        "text": "Saskatchewan",
    },
    {
        "value": "NT",
        "text": "Northwest Territories",
    },
    {
        "value": "NU",
        "text": "Nunavut",
    },
    {
        "value": "YT",
        "text": "Yukon"
    },
]

var countries = [
    {
        "text": "United States",
        "value": "US"
    },
    {
        "text": "Canada",
        "value": "CA"
    },
    {
        "text": "Afghanistan",
        "value": "AF"
    },
    {
        "text": "Albania",
        "value": "AL"
    },
    {
        "text": "Algeria",
        "value": "DZ"
    },
    {
        "text": "American Samoa",
        "value": "AS"
    },
    {
        "text": "Andorra",
        "value": "AD"
    },
    {
        "text": "Angola",
        "value": "AO"
    },
    {
        "text": "Anguilla",
        "value": "AI"
    },
    {
        "text": "Antarctica",
        "value": "AQ"
    },
    {
        "text": "Antigua",
        "value": "AG"
    },
    {
        "text": "Argentina",
        "value": "AR"
    },
    {
        "text": "Armenia",
        "value": "AM"
    },
    {
        "text": "Aruba",
        "value": "AW"
    },
    {
        "text": "Australia",
        "value": "AU"
    },
    {
        "text": "Austria",
        "value": "AT"
    },
    {
        "text": "Azerbaijan",
        "value": "AZ"
    },
    {
        "text": "Bahamas",
        "value": "BS"
    },
    {
        "text": "Bahrain",
        "value": "BH"
    },
    {
        "text": "Bangladesh",
        "value": "BD"
    },
    {
        "text": "Barbados",
        "value": "BB"
    },
    {
        "text": "Belarus",
        "value": "BY"
    },
    {
        "text": "Belgium",
        "value": "BE"
    },
    {
        "text": "Belize",
        "value": "BZ"
    },
    {
        "text": "Benin",
        "value": "BJ"
    },
    {
        "text": "Bermuda",
        "value": "BM"
    },
    {
        "text": "Bhutan",
        "value": "BT"
    },
    {
        "text": "Bolivia",
        "value": "BO"
    },
    {
        "text": "Bosnia Herzegovina",
        "value": "BA"
    },
    {
        "text": "Botswana",
        "value": "BW"
    },
    {
        "text": "Bouvet Island",
        "value": "BV"
    },
    {
        "text": "Brazil",
        "value": "BR"
    },
    {
        "text": "British Indian Ocean",
        "value": "IO"
    },
    {
        "text": "British Virgin Islands",
        "value": "VG"
    },
    {
        "text": "Brunei",
        "value": "BN"
    },
    {
        "text": "Bulgaria",
        "value": "BG"
    },
    {
        "text": "Burkina Faso",
        "value": "BF"
    },
    {
        "text": "Burundi",
        "value": "BI"
    },
    {
        "text": "Cambodia",
        "value": "KH"
    },
    {
        "text": "Cameroon",
        "value": "CM"
    },
    {
        "text": "Canton And Enderbury Is",
        "value": "CT"
    },
    {
        "text": "Cape Verde",
        "value": "CV"
    },
    {
        "text": "Cayman Islands",
        "value": "KY"
    },
    {
        "text": "Central Africa",
        "value": "CF"
    },
    {
        "text": "Chad",
        "value": "TD"
    },
    {
        "text": "Chile",
        "value": "CL"
    },
    {
        "text": "China",
        "value": "CN"
    },
    {
        "text": "Christmas Island",
        "value": "CX"
    },
    {
        "text": "Cocos Islands",
        "value": "CC"
    },
    {
        "text": "Colombia",
        "value": "CO"
    },
    {
        "text": "Comoros",
        "value": "KM"
    },
    {
        "text": "Congo Dem Republic",
        "value": "CD"
    },
    {
        "text": "Congo Republic",
        "value": "CG"
    },
    {
        "text": "Cook Islands",
        "value": "CK"
    },
    {
        "text": "Costa Rica",
        "value": "CR"
    },
    {
        "text": "Croatia",
        "value": "HR"
    },
    {
        "text": "Cuba",
        "value": "CU"
    },
    {
        "text": "Cyprus",
        "value": "CY"
    },
    {
        "text": "Czech Republic",
        "value": "CZ"
    },
    {
        "text": "Denmark",
        "value": "DK"
    },
    {
        "text": "Djibouti",
        "value": "DJ"
    },
    {
        "text": "Dominica",
        "value": "DM"
    },
    {
        "text": "Dominican Republic",
        "value": "DO"
    },
    {
        "text": "Dronning Maud Land",
        "value": "NQ"
    },
    {
        "text": "E Ural Russia",
        "value": "XU"
    },
    {
        "text": "East Germany",
        "value": "DD"
    },
    {
        "text": "East Timor-Indonesia",
        "value": "TP"
    },
    {
        "text": "Ecuador",
        "value": "EC"
    },
    {
        "text": "Egypt",
        "value": "EG"
    },
    {
        "text": "El Salvador",
        "value": "SV"
    },
    {
        "text": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "text": "Eritrea",
        "value": "ER"
    },
    {
        "text": "Estonia",
        "value": "EE"
    },
    {
        "text": "Ethiopia",
        "value": "ET"
    },
    {
        "text": "European Union",
        "value": "BC"
    },
    {
        "text": "Falkland Islands",
        "value": "FK"
    },
    {
        "text": "Faroe Islands",
        "value": "FO"
    },
    {
        "text": "Fiji",
        "value": "FJ"
    },
    {
        "text": "Finland",
        "value": "FI"
    },
    {
        "text": "France",
        "value": "FR"
    },
    {
        "text": "French Guiana",
        "value": "GF"
    },
    {
        "text": "French Polynesia",
        "value": "PF"
    },
    {
        "text": "Gabon",
        "value": "GA"
    },
    {
        "text": "Gambia",
        "value": "GM"
    },
    {
        "text": "Georgia",
        "value": "GE"
    },
    {
        "text": "Germany",
        "value": "DE"
    },
    {
        "text": "Ghana",
        "value": "GH"
    },
    {
        "text": "Gibraltar",
        "value": "GI"
    },
    {
        "text": "Greece",
        "value": "GR"
    },
    {
        "text": "Greenland",
        "value": "GL"
    },
    {
        "text": "Greenwich England",
        "value": "ZU"
    },
    {
        "text": "Grenada",
        "value": "GD"
    },
    {
        "text": "Guadeloupe",
        "value": "GP"
    },
    {
        "text": "Guam",
        "value": "GU"
    },
    {
        "text": "Guatemala",
        "value": "GT"
    },
    {
        "text": "Guinea",
        "value": "GN"
    },
    {
        "text": "Guinea Bissau",
        "value": "GW"
    },
    {
        "text": "Guyana",
        "value": "GY"
    },
    {
        "text": "Haiti",
        "value": "HT"
    },
    {
        "text": "Heard And Mcdonald Islands",
        "value": "HM"
    },
    {
        "text": "Honduras",
        "value": "HN"
    },
    {
        "text": "Hong Kong",
        "value": "HK"
    },
    {
        "text": "Hungary",
        "value": "HU"
    },
    {
        "text": "Iceland",
        "value": "IS"
    },
    {
        "text": "India",
        "value": "IN"
    },
    {
        "text": "Indonesia",
        "value": "ID"
    },
    {
        "text": "Iran",
        "value": "IR"
    },
    {
        "text": "Iraq",
        "value": "IQ"
    },
    {
        "text": "Ireland",
        "value": "IE"
    },
    {
        "text": "Israel",
        "value": "IL"
    },
    {
        "text": "Israel Shekel",
        "value": "CS"
    },
    {
        "text": "Italy",
        "value": "IT"
    },
    {
        "text": "Ivory Coast",
        "value": "CI"
    },
    {
        "text": "Jamaica",
        "value": "JM"
    },
    {
        "text": "Japan",
        "value": "JP"
    },
    {
        "text": "Johnson Island",
        "value": "JT"
    },
    {
        "text": "Jordan",
        "value": "JO"
    },
    {
        "text": "Kazakhstan",
        "value": "KZ"
    },
    {
        "text": "Kenya",
        "value": "KE"
    },
    {
        "text": "Kiribati",
        "value": "KI"
    },
    {
        "text": "Kuwait",
        "value": "KW"
    },
    {
        "text": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "text": "Lao Peoples Dem Rep",
        "value": "LA"
    },
    {
        "text": "Latvia",
        "value": "LV"
    },
    {
        "text": "Lebanon",
        "value": "LB"
    },
    {
        "text": "Lesotho",
        "value": "LS"
    },
    {
        "text": "Leward Islands",
        "value": "LW"
    },
    {
        "text": "Liberia",
        "value": "LR"
    },
    {
        "text": "Libya",
        "value": "LY"
    },
    {
        "text": "Liechtenstein",
        "value": "LI"
    },
    {
        "text": "Lithuania",
        "value": "LT"
    },
    {
        "text": "Luxembourg",
        "value": "LU"
    },
    {
        "text": "Macau",
        "value": "MO"
    },
    {
        "text": "Macedonia",
        "value": "MK"
    },
    {
        "text": "Madagascar",
        "value": "MG"
    },
    {
        "text": "Malawi",
        "value": "MW"
    },
    {
        "text": "Malaysia",
        "value": "MY"
    },
    {
        "text": "Maldives",
        "value": "MV"
    },
    {
        "text": "Mali",
        "value": "ML"
    },
    {
        "text": "Malta",
        "value": "MT"
    },
    {
        "text": "Marshall Islands",
        "value": "MH"
    },
    {
        "text": "Martinique",
        "value": "MQ"
    },
    {
        "text": "Mauritania",
        "value": "MR"
    },
    {
        "text": "Mauritius",
        "value": "MU"
    },
    {
        "text": "Mayotte",
        "value": "YT"
    },
    {
        "text": "Mexico",
        "value": "MX"
    },
    {
        "text": "Micronesia",
        "value": "FM"
    },
    {
        "text": "Midway",
        "value": "MI"
    },
    {
        "text": "Moldova Republic",
        "value": "MD"
    },
    {
        "text": "Monaco",
        "value": "MC"
    },
    {
        "text": "Mongolia",
        "value": "MN"
    },
    {
        "text": "Montenegro",
        "value": "ME"
    },
    {
        "text": "Montserrat",
        "value": "MS"
    },
    {
        "text": "Morocco",
        "value": "MA"
    },
    {
        "text": "Mozambique",
        "value": "MZ"
    },
    {
        "text": "Myanmar",
        "value": "MM"
    },
    {
        "text": "Namibia",
        "value": "NA"
    },
    {
        "text": "Nauru",
        "value": "NR"
    },
    {
        "text": "Nepal",
        "value": "NP"
    },
    {
        "text": "Netherlands",
        "value": "NL"
    },
    {
        "text": "Netherlands Antilles",
        "value": "AN"
    },
    {
        "text": "Nevis",
        "value": "KN"
    },
    {
        "text": "New Caledonia",
        "value": "NC"
    },
    {
        "text": "New Zealand",
        "value": "NZ"
    },
    {
        "text": "Nicaragua",
        "value": "NI"
    },
    {
        "text": "Niger",
        "value": "NE"
    },
    {
        "text": "Nigeria",
        "value": "NG"
    },
    {
        "text": "Niue",
        "value": "NU"
    },
    {
        "text": "Norfolk Island",
        "value": "NF"
    },
    {
        "text": "North Korea",
        "value": "KP"
    },
    {
        "text": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "text": "Norway",
        "value": "NO"
    },
    {
        "text": "Occupied Palestinian Terr",
        "value": "PS"
    },
    {
        "text": "Oman",
        "value": "OM"
    },
    {
        "text": "Pakistan",
        "value": "PK"
    },
    {
        "text": "Palau",
        "value": "PW"
    },
    {
        "text": "Panama",
        "value": "PA"
    },
    {
        "text": "Panama Canal Zone",
        "value": "PZ"
    },
    {
        "text": "Papua New Guinea",
        "value": "PG"
    },
    {
        "text": "Paraguay",
        "value": "PY"
    },
    {
        "text": "Peru",
        "value": "PE"
    },
    {
        "text": "Philippines",
        "value": "PH"
    },
    {
        "text": "Pitcairn Islands",
        "value": "PN"
    },
    {
        "text": "Poland",
        "value": "PL"
    },
    {
        "text": "Portugal",
        "value": "PT"
    },
    {
        "text": "Puerto Rico",
        "value": "PR"
    },
    {
        "text": "Qatar",
        "value": "QA"
    },
    {
        "text": "Reunion",
        "value": "RE"
    },
    {
        "text": "Romania",
        "value": "RO"
    },
    {
        "text": "Russia",
        "value": "RU"
    },
    {
        "text": "Rwanda",
        "value": "RW"
    },
    {
        "text": "Saint Helena",
        "value": "SH"
    },
    {
        "text": "Saint Lucia",
        "value": "LC"
    },
    {
        "text": "Saint Pierre And Miquelon",
        "value": "PM"
    },
    {
        "text": "Saint Vincent And The Grenadines",
        "value": "VC"
    },
    {
        "text": "San Marino",
        "value": "SM"
    },
    {
        "text": "Sao Tome And Principe",
        "value": "ST"
    },
    {
        "text": "Saudi Arabia",
        "value": "SA"
    },
    {
        "text": "Senegal",
        "value": "SN"
    },
    {
        "text": "Serbia",
        "value": "RS"
    },
    {
        "text": "Seychelles",
        "value": "SC"
    },
    {
        "text": "Sierra Leone",
        "value": "SL"
    },
    {
        "text": "Singapore",
        "value": "SG"
    },
    {
        "text": "Slovakia",
        "value": "SK"
    },
    {
        "text": "Slovenia",
        "value": "SI"
    },
    {
        "text": "Solomon Islands",
        "value": "SB"
    },
    {
        "text": "Somalia",
        "value": "SO"
    },
    {
        "text": "South Africa",
        "value": "ZA"
    },
    {
        "text": "South Georgia & South Sandwich Islands",
        "value": "GS"
    },
    {
        "text": "South Korea",
        "value": "KR"
    },
    {
        "text": "Spain",
        "value": "ES"
    },
    {
        "text": "Sri Lanka",
        "value": "LK"
    },
    {
        "text": "Sudan",
        "value": "SD"
    },
    {
        "text": "Suriname",
        "value": "SR"
    },
    {
        "text": "Svalbard And Jan Mayen",
        "value": "SJ"
    },
    {
        "text": "Swaziland",
        "value": "SZ"
    },
    {
        "text": "Sweden",
        "value": "SE"
    },
    {
        "text": "Switzerland",
        "value": "CH"
    },
    {
        "text": "Syria",
        "value": "SY"
    },
    {
        "text": "Taiwan",
        "value": "TW"
    },
    {
        "text": "Tajikistan",
        "value": "TJ"
    },
    {
        "text": "Tanzania",
        "value": "TZ"
    },
    {
        "text": "Thailand",
        "value": "TH"
    },
    {
        "text": "Timor-Leste",
        "value": "TL"
    },
    {
        "text": "Togo",
        "value": "TG"
    },
    {
        "text": "Tokelau",
        "value": "TK"
    },
    {
        "text": "Tonga",
        "value": "TO"
    },
    {
        "text": "Trinidad",
        "value": "TT"
    },
    {
        "text": "Tunisia",
        "value": "TN"
    },
    {
        "text": "Turkey",
        "value": "TR"
    },
    {
        "text": "Turkmenistan",
        "value": "TM"
    },
    {
        "text": "Turks And Caicos Islands",
        "value": "TC"
    },
    {
        "text": "Tuvalu",
        "value": "TV"
    },
    {
        "text": "Uganda",
        "value": "UG"
    },
    {
        "text": "Ukraine",
        "value": "UA"
    },
    {
        "text": "United Arab Emirates",
        "value": "AE"
    },
    {
        "text": "United Kingdom",
        "value": "GB"
    },
    {
        "text": "Uruguay",
        "value": "UY"
    },
    {
        "text": "Us Minor Outlying Islands",
        "value": "UM"
    },
    {
        "text": "Us Pacific Is",
        "value": "PC"
    },
    {
        "text": "Uz Hidden",
        "value": "XE"
    },
    {
        "text": "Uzbekistan",
        "value": "UZ"
    },
    {
        "text": "Vanuatu",
        "value": "VU"
    },
    {
        "text": "Vatican City",
        "value": "PO"
    },
    {
        "text": "Vatican City State",
        "value": "VA"
    },
    {
        "text": "Venezuela",
        "value": "VE"
    },
    {
        "text": "Vietnam",
        "value": "VN"
    },
    {
        "text": "Virgin Islands",
        "value": "VI"
    },
    {
        "text": "Wake Island",
        "value": "WK"
    },
    {
        "text": "Wallis Futuna Islands",
        "value": "WF"
    },
    {
        "text": "Western Sahara",
        "value": "EH"
    },
    {
        "text": "Western Samoa",
        "value": "WS"
    },
    {
        "text": "Windward Is",
        "value": "WW"
    },
    {
        "text": "Yemen Republic",
        "value": "YE"
    },
    {
        "text": "Yugoslavia",
        "value": "YU"
    },
    {
        "text": "Zaire",
        "value": "ZR"
    },
    {
        "text": "Zambia",
        "value": "ZM"
    },
    {
        "text": "Zimbabwe",
        "value": "ZW"
    }
]

export default {usStates, caStates, countries}